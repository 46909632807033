import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthInterceptor } from "../../auth/auth-interceptor";
import { ErrorInterceptor } from "../../error/error-interceptor";
import { CreateQuestionComponent } from './create-question.component';
import { DragDropModule/*, CDK_DRAG_CONFIG, DragDropConfig*/ } from '@angular/cdk/drag-drop';
import { BookReaderModule } from '../../bookreader/bookreader.module';
import { PdfReaderModule } from '../../pdfreader/pdfreader.module';
import { MetamorphoseModule } from '../../metamorphose/metamorphose.module';
import { RecordTimerModule } from '../../record-timer/record-timer.module';
import { RecordRTCModule } from '../../record-rtc/record-rtc.module';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    CreateQuestionComponent
  ],
  entryComponents: [
    CreateQuestionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    MatDialogModule,
    DragDropModule,
    PdfReaderModule,
    BookReaderModule,
    MetamorphoseModule,
    RecordTimerModule,
    RecordRTCModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Insomnia
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [
  ],
  exports: [CreateQuestionComponent]
})
export class CreateQuestionModule {}
