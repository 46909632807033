import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { AuthService } from '../../../auth/auth.service';

export interface Mentor {}
// interface Coordinator {
//   _id: string;
//   username: string;
//   firstname?: string;
//   lastname?: string;
//   role: string;
// }

@Component({
  selector: 'app-mentor-edit',
  templateUrl: './mentor-edit.component.html',
  styleUrls: ['./mentor-edit.component.scss'],
})
export class MentorEditComponent implements OnInit {
  id;
  userType;
  isAdmin: boolean = false;
  mentor: Mentor[] = [];
  rForm: FormGroup;
  subscription: Subscription;
  username: string;
  // coordinators : Coordinator[] = [];

  // public coordinator: FormControl = new FormControl();
  // public coordinatorFilter: FormControl = new FormControl();
  // public filteredCoordinators: ReplaySubject<Coordinator[]> = new ReplaySubject<Coordinator[]>(1);
  // private coordinatorsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    private router: Router,
    private route:ActivatedRoute,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.userType = this.authService.getType();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    this.userService.getUserbyId(this.id).subscribe(res => {
      this.mentor = res;
      console.log(res)
    });

    this.initForm();
  }

  initForm(){
    this.rForm = new FormGroup({
      firstname: new FormControl(null, {
        validators: [Validators.required]
      }),
      lastname: new FormControl(null, {
        validators: [Validators.required]
      }),
      gender: new FormControl(null, {
        validators: [Validators.required]
      }),
      email: new FormControl(null, {
        validators: []
      }),
      phoneNumber: new FormControl(null, {
        validators: []
      }),
      mobileNumber: new FormControl(null, {
        validators: []
      }),
      birthday: new FormControl(null, {
        validators: []
      }),
      comment: new FormControl(null, {
        validators: []
      }),
      activeMembership: new FormControl(null, {
        validators: []
      }),
      scientificSurvey: new FormControl(null, {
        validators: []
      }),
      // coordinator: new FormControl(null, {
      //   validators: [Validators.required]
      // }),
      preferredTypeOfSchool: new FormControl(null, {
        validators: []
      }),
      studentsCapacity: new FormControl(null, {
        validators: []
      }),
      availabilityMo  : new FormControl(null, {
        validators: []
      }),
      availabilityTu  : new FormControl(null, {
        validators: []
      }),
      availabilityWe  : new FormControl(null, {
        validators: []
      }),
      availabilityTh  : new FormControl(null, {
        validators: []
      }),
      availabilityFr  : new FormControl(null, {
        validators: []
      }),
      availabilitySa  : new FormControl(null, {
        validators: []
      }),
      availabilitySu  : new FormControl(null, {
        validators: []
      })
    })

    //fill input field with data from user object
    this.subscription = this.userService.getUserbyId(this.id).subscribe(mentor => {
      this.username = mentor['username'];
      this.rForm.get('firstname').setValue(mentor['firstname']);
      this.rForm.get('lastname').setValue(mentor['lastname']);
      this.rForm.get('gender').setValue(mentor['gender']);
      this.rForm.get('email').setValue(mentor['email']);
      this.rForm.get('phoneNumber').setValue(mentor['phoneNumber']);
      this.rForm.get('mobileNumber').setValue(mentor['mobileNumber']);
      this.rForm.get('birthday').setValue(mentor['birthday']);
      this.rForm.get('comment').setValue(mentor['comment']);
      let activeMembership = true;
      if (mentor['activeMembership'] == undefined) {
        activeMembership = false;
      } else {
        activeMembership = mentor['activeMembership'];
      }
      this.rForm.get('activeMembership').setValue(activeMembership);
      let scientificSurvey = false;
      if (mentor['scientificSurvey'] == undefined) {
        scientificSurvey = false;
      } else {
        scientificSurvey = mentor['scientificSurvey'];
      }
      this.rForm.get('scientificSurvey').setValue(scientificSurvey);
      this.rForm.get('preferredTypeOfSchool').setValue(mentor['preferredTypeOfSchool']);
      this.rForm.get('studentsCapacity').setValue(mentor['studentsCapacity']);
      // if (mentor['coordinator'] != undefined) {
      //   this.rForm.get('coordinator').setValue(mentor['coordinator']['_id']);
      // }
      if (mentor['availability']) {
        let availability = JSON.parse(mentor['availability']);
        this.rForm.get('availabilityMo').setValue(availability.monday);
        this.rForm.get('availabilityTu').setValue(availability.tuesday);
        this.rForm.get('availabilityWe').setValue(availability.wednesday);
        this.rForm.get('availabilityTh').setValue(availability.thursday);
        this.rForm.get('availabilityFr').setValue(availability.friday);
        this.rForm.get('availabilitySa').setValue(availability.saturday);
        this.rForm.get('availabilitySu').setValue(availability.sunday);
      }
    });

    // Get coordinators from api
    // this.userService.getCoordinatorsList();
    // this.coordinatorsSub = this.userService.getCoordinatorsUpdateListener().pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe((coordinators: Coordinator[]) => {
    //   console.log(coordinators)
    //     this.coordinators = coordinators;
    //     // set select for coordinator
    //     this.coordinator.patchValue(this.coordinators);
    //     // refresh coordinators search filter
    //     this.filteredCoordinators.next(this.coordinators.slice());
    //   });

    // this.coordinatorFilter.valueChanges
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe(() => {
    //     this.filterCoordinators();
    //   });
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      this.rForm.get("firstname").markAsTouched();
      this.rForm.get("lastname").markAsTouched();
      this.rForm.get("gender").markAsTouched();
      // this.rForm.get("coordinator").markAsTouched();
      return;
    }
    let availability = {
      monday: this.rForm.value.availabilityMo,
      tuesday: this.rForm.value.availabilityTu,
      wednesday: this.rForm.value.availabilityWe,
      thursday: this.rForm.value.availabilityTh,
      friday: this.rForm.value.availabilityFr,
      saturday: this.rForm.value.availabilitySa,
      sunday: this.rForm.value.availabilitySu,
    };

    
    this.userService.updateUser({
      _id : this.id,
      firstname : this.rForm.value.firstname,
      lastname : this.rForm.value.lastname,
      gender : this.rForm.value.gender,
      email : this.rForm.value.email,
      phoneNumber : this.rForm.value.phoneNumber,
      mobileNumber : this.rForm.value.mobileNumber,
      availability : JSON.stringify(availability),
      birthday : this.rForm.value.birthday,
      comment: this.rForm.value.comment,
      activeMembership : this.rForm.value.activeMembership,
      scientificSurvey: this.rForm.value.scientificSurvey,
      preferredTypeOfSchool : this.rForm.value.preferredTypeOfSchool,
      // coordinator: this.rForm.value.coordinator,
      studentsCapacity: this.rForm.value.studentsCapacity
    }).subscribe(res => {
      this.router.navigate(['/mentor-list']);
    })
  }

  // filterCoordinators() {
  //   if (!this.coordinators) {
  //     return;
  //   }
  //   // get the search keyword
  //   let search = this.coordinatorFilter.value;
  //   if (!search) {
  //     this.filteredCoordinators.next(this.coordinators.slice());
  //     return;
  //   } else {
  //     search = search.toLowerCase();
  //   }
  //   // filter the coordinators by (firstname/ lastname)
  //   this.filteredCoordinators.next(
  //     this.coordinators.filter(coordinator => coordinator.firstname.toLowerCase().indexOf(search) > -1 || coordinator.lastname.toLowerCase().indexOf(search) > -1)
  //   );
  //}
}
