<div class="container-fluid pt-3" [ngClass]="{'mentor_bg': isMentor, 'child_bg': !isMentor }">
  <div class="row">
    <div class="col-md-12 col-lg-4 col-sm-12 px-3">

      <div class="row">
        <div class="col-md-6 col-lg-12 col-sm-6 px-3">
          <div class="video-container">
            <div class="video-drag">
              <!-- <div class="video-drag" cdkDragBoundary=".container-fluid" cdkDrag> -->
              <app-video-call [partnerId]="roomId" [showBoth]="true"></app-video-call>
              <app-record-rtc [readingMode]="'Tandem'" [studentId]="roomId" [mentorId]="mentorId" [userType]="isMentor"
                [showRecordNavbar]="readingModeReadyForRecord" [readingModeActive]="readingMode" (isRecording)="getIsRecording($event)"></app-record-rtc>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-12 col-sm-6 px-3">
          <app-record-timer [readingMode]="'Tandem'" [userType]="isMentor" [partnerId]="roomId"></app-record-timer>
          <app-metamorphose [userType]="isMentor" [partnerId]="roomId"></app-metamorphose>
          <div class="button-group button-group--aside" *ngIf="isMentor">
            <button mat-button color="accent" (click)="hangupCall()" class="size-small">
              <mat-icon class="material-icons">call_end</mat-icon> Auflegen
            </button>
            <button mat-button color="accent" (click)="openBooksDialog()" class="size-small" *ngIf="!gamingMode">
              <mat-icon class="material-icons">menu_book</mat-icon> Buch auswählen
            </button>
            <div *ngIf="gamingMode">
              <button mat-button color="accent" class="size-small" (click)="switchToReadingMode()">
                <mat-icon class="material-icons">menu_book</mat-icon> Lesen
              </button>
            </div>
            <div *ngIf="readingMode || gamingMode">
              <button mat-button color="accent" class="size-small" (click)="openGamesDialog()">
                <mat-icon class="material-icons">sports_esports</mat-icon> Spielen
              </button>
            </div>
            <button mat-flat-button color="accent" *ngIf="scientificSurvey" (click)="startScientificSurvey(surveyUrl)" class="size-small">
              <mat-icon class="material-icons">school</mat-icon> Befragung
            </button>
          </div>
          <div class="button-group" *ngIf="!isMentor">
            <button mat-button color="accent" (click)="openBooksDialog()" class="size-small">
              <mat-icon class="material-icons">menu_book</mat-icon> Bücher anschauen
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-sm-12">
      <ng-container *ngIf="isMentor && !readingMode && !gamingMode">
        <div class="button-group">
          <button mat-button color="accent" class="size-big" (click)="switchToReadingMode()">
            <mat-icon class="material-icons">menu_book</mat-icon> Lesen
          </button>
          <button mat-button color="accent" class="size-big" (click)="openGamesDialog()">
            <mat-icon class="material-icons">sports_esports</mat-icon> Spielen
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="!isMentor && !readingMode && !gamingMode">
        <div class="student_container">
          <h1><span *ngIf="!firstname">{{ username }}</span><span *ngIf="firstname">{{ firstname }}</span>, wollen wir lesen?</h1>
        </div>
      </ng-container>

      <app-bookreader [readingMode]="'Tandem'" [bookUrl]="bookUrl" *ngIf="readingMode && readingModeIsEpub">
      </app-bookreader>
      <app-pdfreader [readingMode]="'Tandem'" [bookUrl]="bookUrl" [bookActivePage]="bookActivePage" [toolButtons]="false"
        (activePage)="getActivePage($event)" *ngIf="readingMode && readingModeIsPDF"></app-pdfreader>
      <app-game-connect-four [studentId]="roomId" [userType]="isMentor" [mentorId]="mentorId"
        *ngIf="gamingMode && activeGame == 'connect-four'"></app-game-connect-four>
      <app-game-uno [studentId]="roomId" [mentorId]="mentorId" [userType]="isMentor" *ngIf="gamingMode && activeGame == 'uno'"></app-game-uno>
    </div>
  </div>
</div>