import { Component, OnInit, Inject, Optional } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  id: string;
}

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent implements OnInit {
  url: string = '';
  urlSafe: SafeResourceUrl;

  constructor(
    private router: Router,
    private route:ActivatedRoute,
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<IframeComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData
  ) {
    if (injectedData) {
      // Get new url
      if (typeof(injectedData['url']) !== "undefined") {
        this.url = injectedData['url'];
      }
      if (this.url != '') {
        // Try to add parameter to url
        let urlParameter = '';
        if (typeof(injectedData['params']) !== "undefined") {
          if (injectedData['params']) {
            if (typeof(injectedData['mentorId']) !== "undefined") {
              urlParameter = '?mentorId=' + injectedData['mentorId'];
            }
            if (typeof(injectedData['studentId']) !== "undefined") {
              urlParameter += (urlParameter == '') ? '?studentId=' + injectedData['studentId'] : '&studentId=' + injectedData['studentId'];
            }
          }
        }
        this.url += urlParameter;
      }
    }
    console.log(this.url)
  }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}