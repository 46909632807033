<div class="game">
    <div class="your_color"></div>
    <div class="opponent_color"></div>
    <div class="board">
        <img src="../../assets/board.svg" class="overlay">
        <div class="cols">
            <div class="col" id="col_1" data-in-col="0"></div>
            <div class="col" id="col_2" data-in-col="0"></div>
            <div class="col" id="col_3" data-in-col="0"></div>
            <div class="col" id="col_4" data-in-col="0"></div>
            <div class="col" id="col_5" data-in-col="0"></div>
            <div class="col" id="col_6" data-in-col="0"></div>
            <div class="col" id="col_7" data-in-col="0"></div>
        </div>
    </div>
    <div class="status">Du bist am Zug!</div>
</div>

<div class="button-group restart-game">
  <button mat-button color="accent" (click)="resetGame()">
    <mat-icon class="material-icons">replay</mat-icon>
  </button>
</div>