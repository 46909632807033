import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subscription, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators, FormGroupDirective } from "@angular/forms";
import { takeUntil } from 'rxjs/operators';
import { SchoolService } from '../../../providers/school.service';

export interface Student {}
interface School {
  _id: string;
  name: string;
  streetNr?: string;
  plz?: string;
  city?: string;
  typeOfSchool: string;
}
@Component({
  selector: 'app-student-edit',
  templateUrl: './student-edit.component.html',
  styleUrls: ['./student-edit.component.scss'],
})
export class StudentEditComponent implements OnInit {
  id;
  student: Student[] = [];
  rForm: FormGroup;
  subscription: Subscription;
  username: string;
  loanedDevice: boolean = false;

  schools : School[] = [];
  public school: FormControl = new FormControl();
  private schoolsSub: Subscription;
  public schoolFilter: FormControl = new FormControl();
  /** list of schools filtered by search keyword */
  public filteredSchools: ReplaySubject<School[]> = new ReplaySubject<School[]>(1);
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    private router: Router,
    private route:ActivatedRoute,
    private schoolService : SchoolService,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.userService.getUserbyId(this.id).subscribe(res => {
      this.student = res;
      console.log(res)
    });

    this.initForm();
  }

  initForm(){
    this.rForm = new FormGroup({
      firstname: new FormControl(null, {
        validators: [Validators.required]
      }),
      lastname: new FormControl(null, {
        validators: [Validators.required]
      }),
      gender: new FormControl(null, {
        validators: [Validators.required]
      }),
      email: new FormControl(null, {
        validators: []
      }),
      phoneNumber: new FormControl(null, {
        validators: []
      }),
      mobileNumber: new FormControl(null, {
        validators: []
      }),
      birthday: new FormControl(null, {
        validators: []
      }),
      activeMembership: new FormControl(null, {
        validators: []
      }),
      readingAbilityRate: new FormControl(null, {
        validators: []
      }),
      comment: new FormControl(null, {
        validators: []
      }),
      schoolClass: new FormControl(null, {
        validators: []
      }),
      loanedDeviceSerial: new FormControl(null, {
        validators: []
      }),
      school: new FormControl(null, {
        validators: [Validators.required]
      }),
      // readingTime: new FormControl(null, {
      //   validators: []
      // }),
      availabilityMo  : new FormControl(null, {
        validators: []
      }),
      availabilityTu  : new FormControl(null, {
        validators: []
      }),
      availabilityWe  : new FormControl(null, {
        validators: []
      }),
      availabilityTh  : new FormControl(null, {
        validators: []
      }),
      availabilityFr  : new FormControl(null, {
        validators: []
      }),
      availabilitySa  : new FormControl(null, {
        validators: []
      }),
      availabilitySu  : new FormControl(null, {
        validators: []
      })
    })

    //fill input field with data from user object
    this.subscription = this.userService.getUserbyId(this.id).subscribe(student => {
      this.username = student['username'];
      this.rForm.get('firstname').setValue(student['firstname']);
      this.rForm.get('lastname').setValue(student['lastname']);
      this.rForm.get('gender').setValue(student['gender']);
      this.rForm.get('email').setValue(student['email']);
      this.rForm.get('phoneNumber').setValue(student['phoneNumber']);
      this.rForm.get('mobileNumber').setValue(student['mobileNumber']);
      this.rForm.get('birthday').setValue(student['birthday']);
      let activeMembership = true;
      if (student['activeMembership'] == undefined) {
        activeMembership = false;
      } else {
        activeMembership = student['activeMembership'];
      }
      this.rForm.get('activeMembership').setValue(activeMembership);
      let readingAbilityRate = student['readingAbilityRate'];
      // Set default value
      if (!readingAbilityRate) {
        readingAbilityRate = 'keine Angaben';
      }
      this.rForm.get('readingAbilityRate').setValue(readingAbilityRate);
      this.rForm.get('comment').setValue(student['comment']);
      this.rForm.get('schoolClass').setValue(student['schoolClass']);
      this.rForm.get('school').setValue(student['school']['_id']);
      this.rForm.get('loanedDeviceSerial').setValue(student['loanedDeviceSerial']);
      // set toggle to true if value in serial field
      if (student['loanedDeviceSerial']) {
        this.loanedDevice = true;
      }
      if (student['availability']) {
        let availability = JSON.parse(student['availability']);
        this.rForm.get('availabilityMo').setValue(availability.monday);
        this.rForm.get('availabilityTu').setValue(availability.tuesday);
        this.rForm.get('availabilityWe').setValue(availability.wednesday);
        this.rForm.get('availabilityTh').setValue(availability.thursday);
        this.rForm.get('availabilityFr').setValue(availability.friday);
        this.rForm.get('availabilitySa').setValue(availability.saturday);
        this.rForm.get('availabilitySu').setValue(availability.sunday);
      }
      // this.rForm.get('readingTime').setValue(student['readingTime']);
    });

    // Get schools from api
    this.schoolService.getSchoolList();
    this.schoolsSub = this.schoolService.getSchoolUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((schools: School[]) => {
        this.schools = schools;
        // refresh coordinators search filter
        this.filteredSchools.next(this.schools.slice());
      });


    this.schoolFilter.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.filterSchools();
      });
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      this.rForm.get("firstname").markAsTouched();
      this.rForm.get("lastname").markAsTouched();
      this.rForm.get("gender").markAsTouched();
      this.rForm.get("school").markAsTouched();
      return;
    }
    let availability = {
      monday: this.rForm.value.availabilityMo,
      tuesday: this.rForm.value.availabilityTu,
      wednesday: this.rForm.value.availabilityWe,
      thursday: this.rForm.value.availabilityTh,
      friday: this.rForm.value.availabilityFr,
      saturday: this.rForm.value.availabilitySa,
      sunday: this.rForm.value.availabilitySu,
    };

    let loanedDeviceSerial = this.rForm.value.loanedDeviceSerial;
    if (!this.loanedDevice) {
      loanedDeviceSerial = '';
    }

    this.userService.updateUser({
      _id : this.id,
      firstname : this.rForm.value.firstname,
      lastname : this.rForm.value.lastname,
      gender : this.rForm.value.gender,
      email : this.rForm.value.email,
      phoneNumber : this.rForm.value.phoneNumber,
      mobileNumber : this.rForm.value.mobileNumber,
      availability : JSON.stringify(availability),
      birthday : this.rForm.value.birthday,
      activeMembership : this.rForm.value.activeMembership,
      readingAbilityRate: this.rForm.value.readingAbilityRate,
      comment: this.rForm.value.comment,
      schoolClass: this.rForm.value.schoolClass,
      loanedDeviceSerial: loanedDeviceSerial,
      school: this.rForm.value.school,
      // readingTime : this.rForm.value.readingTime,
    }).subscribe(res => {
      this.router.navigate(['/student-list']);
    });
  }

  filterSchools() {
    if (!this.schools) {
      return;
    }
    // get the search keyword
    let search = this.schoolFilter.value;
    if (!search) {
      this.filteredSchools.next(this.schools.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the schools by name
    this.filteredSchools.next(
      this.schools.filter(school => school.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
