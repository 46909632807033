import { Component, OnInit } from '@angular/core';
import { SchoolService } from "../../providers/school.service";
import { FormBuilder, FormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-create-school',
  templateUrl: './create-school.component.html',
  styleUrls: ['./create-school.component.scss'],
})
export class CreateSchoolComponent implements OnInit {
  rForm: FormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public schoolService: SchoolService,
    private router: Router,
    private _formBuilder: FormBuilder,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.rForm = this._formBuilder.group({
      name  : ['', Validators.required],
      plz     : [''],
      city     : [''],
      streetNr     : [''],
      typeOfSchool  : ['', Validators.required]
    });
  }

  /**
   * submit data
   */
  submitForm(formDirective: FormGroupDirective) {
    console.log('hit')
    if (this.rForm.invalid) {
      this.rForm.get("name").markAsTouched();
      this.rForm.get("typeOfSchool").markAsTouched();
      return;
    }
    console.log(this.rForm.value)

    this.schoolService.createSchool(
      this.rForm.value.name,
      this.rForm.value.streetNr,
      this.rForm.value.plz,
      this.rForm.value.city,
      this.rForm.value.typeOfSchool
    ).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res:any) => {
      console.log(res)
      var message = "Schule" + " " + this.rForm.value.name + " " + "angelegt";
      this.snackBar.open(message, "",{
        duration: 3000,
        horizontalPosition: 'right'
      });
      formDirective.resetForm();
      this.rForm.reset();
      this.router.navigate(["/school-list"]);
    });
  }
}
