import { Component, AfterViewInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { WebSocketService } from '../providers/websocket.service';
import { map, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pdfreader',
  templateUrl: './pdfreader.component.html',
  styleUrls: ['./pdfreader.component.scss'],
})
export class PdfreaderComponent implements AfterViewInit {
  // pdfFile: any;
  totalPages = 0;
  pdfOptions = {
    width: "820px",
    height: "585px" //665px
  };
  isLoading: boolean = true;
  navAtStart: boolean = true;
  navAtEnd: boolean = false;
  currentZoomFactor: number;
  minZoom = "0.5";
  maxZoom = "2";
  public zoom: any = 'page-width';
  handTool: boolean = true;
  userInputChanged: Subject<string> = new Subject<string>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  //@ViewChild('pdfContentRef', {static: true}) pdfContentRef: ElementRef;

  @Input('bookUrl') bookUrl: any;
  @Input('readingMode') readingMode: string;
  @Input('bookActivePage') currentPage: number;
  @Input('toolButtons') toolButtons: boolean;
  @Input('previewMode') previewMode: boolean;
  @Output('activePage') informParent = new EventEmitter<number>();

  constructor(
    private webSocketService: WebSocketService
  ) {
    var _this = this;
    this.userInputChanged.pipe(
      map(event => event),
      debounceTime(500),
      distinctUntilChanged()
    ).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe({
        next: function(userinput) {
            var val = Number(userinput);
            if ((!isNaN(val)) && (val > 0) && (val < _this.totalPages+1)) {
              _this.currentPage = val;
              _this.sendSocketPdfReading();
            }
        }
      });
  }

  ngAfterViewInit() {
    this.isLoading = true;
    // this.pdfFile = "../assets/books/Kinderzeitung-2020-506-S.pdf";
    this.webSocketService.listen('pdf-reading').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.updateCurrentPage(data));
  }

  /**
   *  load the previous page
   */
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.sendSocketPdfReading();
    }
  }

  /**
   *  load the next page
   */
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage = this.currentPage + 1;
      this.sendSocketPdfReading();
    }
  }

  /**
   *  pdf zoom in
   */
  zoomIn() {
    var factor = Number((this.currentZoomFactor + 0.1).toFixed(1)) *100;
    //console.log(factor)
    if (factor <= Number(this.maxZoom)*100) {
      this.zoom = factor;
    }
  }

  /**
   *  pdf zoom out
   */
  zoomOut() {
    var factor = Number((this.currentZoomFactor - 0.1).toFixed(1)) *100;
    //console.log(factor)
    if (factor >= Number(this.minZoom)*100) {
      this.zoom = factor;
    }
  }

  /**
   *  switch handtool to selection tool
   */
  toggleHandTool() {
    this.handTool = !this.handTool;
  }

  /**
   *  pdf zoom reset
   */
  zoomReset() {
    this.zoom = 'page-width';
  }

  /**
   *  on value change fire update logic
   */
  onCurrentPageChange(val) {
    this.userInputChanged.next(val);
  }
  

  /**
   *  set value of current zoom
   */
  updateZoomFactor(data) {
    //console.log(data)
    this.currentZoomFactor = data;
  }

  /**
   *  onPageRendered
   */
  onPageRendered(data) {
    //console.log('rendered')
    console.log(data)
  }

  /**
   *  onPageChange
   */
  onPageChange(data) {
    //console.log('changed')
    console.log(data)
  }

  /**
   *  get the totalpage
   */
  onPdfLoaded(data) {
    //console.log('loadeddd')
    this.isLoading = false;
    if (this.previewMode) {
      this.totalPages = data.pagesCount > 10 ? 10 : data.pagesCount;
    } else {
      this.totalPages = data.pagesCount;
    }
    // Update navigation buttons
    this.updateNavigation();
    // Notify other user that reader is ready
    this.webSocketService.emitTo('book-reader-ready', this.webSocketService.getCurrentRoom(), {
      ready : true
    }); 
  }

  /**
   *  send pdf data to socket
   */
  sendSocketPdfReading(): void {
    this.updateNavigation();
    // Scroll back to top of container
    //this.scrollTabContentToTop();
    if (this.readingMode == 'Tandem') {
        this.webSocketService.emitTo('pdf-reading', this.webSocketService.getCurrentRoom(), {
          page: this.currentPage
        });
    }
    // Send data to parent component
    this.informParent.emit(this.currentPage);
  }

  /**
   *  update the current page
   */
  updateCurrentPage(data:any) {
    console.log(data)
    if(!!!data) return;
    if (this.currentPage != data.page) {
      this.currentPage = data.page;
      // Send data to parent component
      this.informParent.emit(this.currentPage);
      this.updateNavigation();
    }
  }

  /**
   *  update the navigation of the pdf (buttons)
   */
  updateNavigation() {
    if (this.currentPage == 1) {
      this.navAtStart = true;
      this.navAtEnd = false;
    } else if (this.currentPage == this.totalPages) {
        this.navAtStart = false;
        this.navAtEnd = true;
    } else {
      this.navAtStart = false;
      this.navAtEnd = false;
    }
  }

  // scrollTabContentToTop() : void {
	// 	this.pdfContentRef.nativeElement.scrollTo(100, 2000 );
	// }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
      this.userInputChanged.unsubscribe();
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

}