import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { QuizService } from '../../providers/quiz.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EbooksEpubService } from "../../providers/epub.service";
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../helpers/confirm-dialog/confirm-dialog.component';

export interface Quiz {
  _id?: string;
}

@Component({
  selector: 'app-list-question',
  templateUrl: './list-question.component.html',
  styleUrls: ['./list-question.component.scss'],
})
export class ListQuestionComponent implements OnInit {
  displayedColumns: string[] = ['question', 'page', 'buttons'];
  dataSource:any;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isAdmin = false;
  id;
  book;
  bookname;
  dialogConfig: any;
  quiz: Quiz[] = [];
  private quizSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();


  constructor(
    private quizService : QuizService,
    private route:ActivatedRoute,
    private router: Router,
    public ebookEpubService: EbooksEpubService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.quizService.getQuestionAboutTheBook(this.id);

    this.ebookEpubService.getBookById(this.id).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(data => {
      this.bookname = data["name"];
    });

    this.quizSub = this.quizService.getQuestionUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((quiz: Quiz[]) => {
        this.quiz = quiz;
        this.dataSource = new MatTableDataSource<Quiz>(this.quiz);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteQuestion(id) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    //this.dialogConfig.width = '400px';
    this.dialogConfig.data = {
      title: 'Achtung',
      note: 'Soll die Frage wirklich gelöscht werden?',
      accept: true,
      acceptIcon: '',
      acceptText: 'Ja',
      decline: true,
      declineIcon: '',
      declineText: 'Nein'
    };
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(result => {
      if (result == 'ok') {
       this.quizService.deleteQuestion(id)
      }
    });
  }


}
