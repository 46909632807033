import { Component, OnInit } from '@angular/core';
import { EbooksEpubService } from "../../providers/epub.service";
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";

export interface Book {}

@Component({
  selector: 'app-book-edit',
  templateUrl: './book-edit.component.html',
  styleUrls: ['./book-edit.component.scss'],
})
export class BookEditComponent implements OnInit {
  id;
  book: Book[] = [];
  rForm: FormGroup;
  subscription: Subscription;


  constructor(
    public ebookEpubService: EbooksEpubService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }
  

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];

    this.ebookEpubService.getBookById(this.id).subscribe(res => {
      this.book = res;
      console.log(res)
    });

    this.initForm();
  }

  
  initForm(){
    this.rForm = new FormGroup({
      name: new FormControl(null, {
        validators: []
      }),
      recommendation: new FormControl(null, {
        validators: []
      })
    })

    //fill input field with data from user object
    this.subscription = this.ebookEpubService.getBookById(this.id).subscribe(book => {
      this.rForm.get('name').setValue(book['name']);
      this.rForm.get('recommendation').setValue(book['recommendation']);
    })
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      this.rForm.get("name").markAsTouched();
      return;
    }
    this.ebookEpubService.updateBook({
      _id : this.id,
      name : this.rForm.value.name,
      recommendation:  this.rForm.value.recommendation,
    }).subscribe(res => {
      this.router.navigate(['/books']);
    })
  }

}
