import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../../providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolService } from '../../../providers/school.service';

export interface DialogData {
  id: any;
}
interface School {
  _id: string;
  name: string;
  streetNr?: string;
  plz?: string;
  city?: string;
  typeOfSchool: string;
}

@Component({
  selector: 'app-mentor-upgrade',
  templateUrl: './mentor-upgrade.component.html',
  styleUrls: ['./mentor-upgrade.component.scss'],
})
export class MentorUpgradeComponent implements OnInit {
  id;
  userType;
  user;
  rForm: FormGroup;

  schools : School[] = [];
  public schoolFilter: FormControl = new FormControl();

  public filteredSchools: ReplaySubject<School[]> = new ReplaySubject<School[]>(1);
  private schoolsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    private authService : AuthService,
    private router: Router,
    private schoolService : SchoolService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MentorUpgradeComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: DialogData
  ) {
    if (injectedData) {
      if (typeof(injectedData['id'])!=="undefined") {
        this.id = injectedData['id'];
      }
    }
  }

  ngOnInit() {
    this.userService.getUserbyId(this.id).subscribe(res => {
      this.user = res;
    });
    this.initForm();
  }

  initForm() {
    this.rForm = new FormGroup({
      supervisingSchools: new FormControl(null, Validators.required)
    });

    // Get schools from api
    this.schoolService.getSchoolList();
    this.schoolsSub = this.schoolService.getSchoolUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((schools: School[]) => {
      console.log(schools)
        this.schools = schools;
        this.filteredSchools.next(this.schools.slice());
      });


    this.schoolFilter.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.filterSchools();
      });
  }

  cancel() {
    this.dialogRef.close('decline');
  }

	submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    this.userService.updateUser({
      _id : this.id,
      coordinator : null,
      role: 'coordinator',
      tokenSalt: '',
      supervisingSchools: this.rForm.value.supervisingSchools
    }).subscribe(res => {
        this.dialogRef.close();
        this.router.navigate(['/coordinator-list']);
        this.snackBar.open(this.user.firstname + " " + this.user.lastname + " wurde zum Koordinator heraufgestuft.", "",{
          duration: 3000,
          horizontalPosition: 'right'
        });
    })
  }

  filterSchools() {
    if (!this.schools) {
      return;
    }
    // get the search keyword
    let search = this.schoolFilter.value;
    if (!search) {
      this.filteredSchools.next(this.schools.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the schools by name
    this.filteredSchools.next(
      this.schools.filter(school => school.name.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
