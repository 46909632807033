import { Component } from '@angular/core';

@Component({
  selector: 'app-game-list-multiplayer',
  templateUrl: './game-list-multiplayer.component.html',
  styleUrls: ['./game-list-multiplayer.component.scss'],
})
export class GameListMultiplayerComponent  {

  constructor() { }

}
