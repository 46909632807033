import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { FormGroup, NgForm } from '@angular/forms';
import { UserService } from '../../providers/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  updatePasswordForm: FormGroup;
  id;
  username;
  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private authService : AuthService,
    private userService : UserService
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.userService.getUserbyId(this.id).subscribe(res => {
      this.username = res["username"];
    });
  }

  /**
   *  update Password
   */
  updatePassword(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.authService.updatePassword(this.username, form.value.password);
  }
}
