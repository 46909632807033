import { Component, OnInit } from '@angular/core';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  rForm: FormGroup;
  clientLogo = environment.clientLogo;

  constructor(
    private authService : AuthService
  ) {}

  ngOnInit() {
    this.rForm = new FormGroup({
      username: new FormControl()
    })
  }

  ResetPassword(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    this.authService.requestPassword(form.value.username);
  }
}
