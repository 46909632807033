import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-school-menu',
  templateUrl: './school-menu.component.html',
  styleUrls: ['./school-menu.component.scss'],
})
export class SchoolMenuComponent implements OnInit {
  isAdmin = false;
  isCoordinator = false;
  userType;

  constructor(
    private authService : AuthService,
  ) { }

  ngOnInit() {
    this.userType = this.authService.getType();

    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    if (this.userType == 'coordinator') {
      this.isCoordinator = true;
    }
  }

}
