<ion-app [ngClass]="{'tablet-frame': showTabletFrame, 'is-app is-android': IS_APP }">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <div class="left-wrap">
        <!-- <button mat-icon-button aria-label="History back">
          <mat-icon>arrow_back</mat-icon>
        </button> -->
        <back-button color="primary" *ngIf="!hideBackbutton"></back-button>
      </div>
      <!-- <span class="toolbar-spacer"></span> -->
      <div class="center-wrap">
        <span class="logo-wrap" (click)="logoRouting()"><img src="assets/logos/{{clientLogoNavbar}}" /></span>
        <div class="logo-title">
          <span class="app-title">{{ CLIENT_TITLE }}</span>
          <span class="app-version">{{ APP_VERSION }}</span>
        </div>
      </div>
      <span class="toolbar-spacer"></span>
      <div class="right-wrap">
        <button *ngIf="userIsAuthenticated && (isCoordinator || isMentor) && (router.url == '/dashboard') && scientificSurvey" mat-flat-button color="accent" (click)="openIframeAsOverlay(surveyUrl)" aria-label="Wissenschaftliche Befragung" class="icon-with-text">
          <mat-icon>school</mat-icon> Wissenschaftliche Befragung
        </button>

        <button *ngIf="userIsAuthenticated && isCoordinator" mat-button aria-label="Verwaltungsbereich" [routerLink]="['/admin-dashboard']" class="icon-with-text">
          <mat-icon>manage_accounts</mat-icon> Verwaltungsbereich
        </button>

        <button *ngIf="userIsAuthenticated && (isCoordinator || isMentor)" mat-button aria-label="Fehlerhinweis zur App" (click)="goToBugReportUrl()" class="icon-with-text">
          <mat-icon>rate_review</mat-icon> Fehlerhinweis zur App
        </button>

        <button *ngIf="userIsAuthenticated" mat-button aria-label="Fehlerhinweis zur App" (click)="openIframeAsOverlay(speedtestUrl)" class="icon-with-text">
          <mat-icon>speed</mat-icon>
        </button>
        
        <button *ngIf="userIsAuthenticated" mat-button aria-label="Logout" (click)="logoutQuestion()" class="icon-with-text">
          <mat-icon>exit_to_app</mat-icon> Abmelden
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <ion-content no-bounce has-bouncing="false" forceOverscroll="false">
    <div class="ion-content-wrap">
      <router-outlet></router-outlet>
    </div>
  </ion-content>
  <div class="network_status_check" *ngIf="!networkStatusOnline?.connected && !IS_APP">
    <p>Es besteht keine Verbindung zum Internet.</p>
  </div>
  <audio id="ringtone" webkit-playsinline="true" playsinline="true" autoPlay>
    <source src="/assets/sounds/simple_beep.mp3" type="audio/mp3" />
  </audio>
</ion-app>
<app-splash-screen [showSplashScreen]="splashScreenBrowser"></app-splash-screen>