<div class="loading-screen" *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>
<div class="game" id="uno-bg" [hidden]="isLoading">
    <div class="game_board">
        <div class="players">
            <ul></ul>
        </div>
        <div class="board"></div>
    </div>

    <div class="deck-wrapper">
        <div class="col" style="text-align: center;">

        </div>
        <div class="deck">
            <div class="frame" id="frame">

            </div>
        </div>  
    </div>
    <div id="startGameDiv"></div>

    <div id="uno-color-choice" class="choose-color modal">
        <div class="color-choice-content">
          <h3>Wähle eine Farbe</h3>
          <div class="color-choice-wrap" id="choosColorBtn">
            <div class="red" data-color="red"></div>
            <div class="green" data-color="green"></div>
            <div class="blue" data-color="blue"></div>
            <div class="yellow" data-color="yellow"></div>
          </div>
          <!-- <select>
              <option value="red">red</option>
              <option value="green">green</option>
              <option value="blue">blue</option>
              <option value="yellow">yellow</option>
          </select> -->
        </div>
        <!-- <div class="modal-footer">
          <a class="modal-close waves-effect waves-green btn-flat" id="choosColorBtn">Ok</a>
        </div> -->
    </div>
</div>

<div class="button-group restart-game">
  <button mat-button color="accent" (click)="resetGame()">
    <mat-icon class="material-icons">replay</mat-icon>
  </button>
</div>