<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-10 col-sm-12">
        <h1 class="pt-3">Registrierung</h1>
        <div class="register_wrap">
          <form [formGroup]="rForm" #formDirective="ngForm">
            <div class="row">
              <div class="col-md-4">
                  <mat-form-field>
                    <input matInput formControlName="username"  type="text" placeholder="Benutzername" required>
                  </mat-form-field>
      
                  <mat-form-field>
                    <input type="password" formControlName="password"  matInput placeholder="Kennwort"  required>
                  </mat-form-field>
      
                  <mat-form-field [hidden]="hideRole">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="role" type="role" placeholder="Role" (selectionChange)="getSelectedRole($event)">
                      <mat-option value="student">Student</mat-option>
                      <mat-option value="mentor">Mentor</mat-option>
                      <mat-option value="coordinator" *ngIf="isAdmin">Koordinator</mat-option>
                      <mat-option value="admin" *ngIf="isAdmin">Admin</mat-option>
                    </mat-select>
                  </mat-form-field>
      
                  <!--<mat-form-field  *ngIf="isMentor"> 
                    <mat-label>Koordinator</mat-label>
                    <mat-select formControlName="coordinator" placeholder="Koordinator" required>
                      <mat-option>
                        <ngx-mat-select-search [formControl]="coordinatorFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let coordinator of filteredCoordinators  | async" [value]="coordinator._id">
                        {{ coordinator?.firstname }}  {{ coordinator?.lastname }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>-->
      
                  <mat-form-field *ngIf="isStudent">
                    <mat-label>Schule</mat-label>
                    <mat-select formControlName="school" placeholder="Schule" required>
                      <mat-option>
                        <ngx-mat-select-search [formControl]="schoolFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let school of filteredSchools | async" [value]="school">
                        {{school.name}} | {{school.plz}} {{school.city}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field *ngIf="isCoordinator">
                    <mat-label>Betreute Schulen</mat-label>
                    <mat-select formControlName="supervisingSchools" placeholder="Schulen" multiple required>
                      <mat-option>
                        <ngx-mat-select-search [formControl]="schoolFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let school of filteredSchools | async" [value]="school">
                        {{school.name}} | {{school.plz}} {{school.city}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div *ngIf="isCoordinator || isMentor">
                    <mat-form-field>
                      <mat-label>Präferenz Schulforn für Leseaktivität</mat-label>
                      <mat-select formControlName="preferredTypeOfSchool" type="role">
                        <mat-option value="Grundschule">Grundschule</mat-option>
                        <mat-option value="Weiterführend">Weiterführend</mat-option>
                        <mat-option value="Andere">Andere</mat-option>
                        <mat-option value="Egal">Egal</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Kapazität an Lesekindern</mat-label>
                      <mat-select formControlName="studentsCapacity">
                        <mat-option value="1">1</mat-option>
                        <mat-option value="2">2</mat-option>
                        <mat-option value="3">3</mat-option>
                        <mat-option value="4">4</mat-option>
                        <mat-option value="5">5</mat-option>
                        <mat-option value="6">6</mat-option>
                        <mat-option value="7">7</mat-option>
                        <mat-option value="8">8</mat-option>
                        <mat-option value="9">9</mat-option>
                        <mat-option value="10">10</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="roleInit === 'student'">
                    <br />
                    <mat-slide-toggle [checked]="loanedDevice" (change)="loanedDevice = !loanedDevice" color="primary">Leihgerät</mat-slide-toggle>
                    <br />
                    <br />
                    <mat-form-field *ngIf="loanedDevice">
                      <input matInput formControlName="loanedDeviceSerial" type="text" placeholder="Seriennummer Leihgerät">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Besuchte Schulklasse</mat-label>
                      <mat-select formControlName="schoolClass">
                        <mat-option value="1">1</mat-option>
                        <mat-option value="2">2</mat-option>
                        <mat-option value="3">3</mat-option>
                        <mat-option value="4">4</mat-option>
                        <mat-option value="5">5</mat-option>
                        <mat-option value="6">6</mat-option>
                        <mat-option value="7">7</mat-option>
                        <mat-option value="8">8</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Einschätzung der Lesefähigkeit der Eltern</mat-label>
                      <mat-select formControlName="readingAbilityRate">
                        <mat-option value="keine Angaben">keine Angaben</mat-option>
                        <mat-option value="Sehr gut">Sehr gut</mat-option>
                        <mat-option value="Gut">Gut</mat-option>
                        <mat-option value="Normal">Normal</mat-option>
                        <mat-option value="Schwach">Schwach</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <mat-form-field>
                    <textarea matInput formControlName="comment" placeholder="Kommentar"></textarea>
                  </mat-form-field>
              </div>

              <div class="col-md-4">
                  <p><strong>Persönliche Daten</strong></p>
                  <mat-form-field>
                    <input matInput formControlName="firstname"  type="text" placeholder="Vorname" required>
                  </mat-form-field>
      
                  <mat-form-field>
                    <input matInput formControlName="lastname"  type="text" placeholder="Nachname" required>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Geschlecht</mat-label>
                    <mat-select formControlName="gender" required>
                      <mat-option value="männlich">männlich</mat-option>
                      <mat-option value="weiblich">weiblich</mat-option>
                      <mat-option value="divers">divers</mat-option>
                    </mat-select>
                  </mat-form-field>
      
                  <mat-form-field>
                    <input matInput formControlName="email"  type="text" placeholder="E-Mail"> 
                  </mat-form-field>
      
                  <mat-form-field>
                    <input matInput formControlName="phoneNumber"  type="tel" placeholder="Telefon Festnetz">
                  </mat-form-field>
      
                  <mat-form-field>
                    <input matInput formControlName="mobileNumber"  type="tel" placeholder="Telefon Mobil">
                  </mat-form-field>

                  <mat-form-field (click)="birthdaypicker.open()">
                    <mat-label>Geburtstag</mat-label>
                    <input matInput formControlName="birthday" type="text" [matDatepicker]="birthdaypicker">
                    <mat-datepicker-toggle matSuffix [for]="birthdaypicker"></mat-datepicker-toggle>
                    <mat-datepicker #birthdaypicker></mat-datepicker>
                  </mat-form-field>
              </div>

              <div class="col-md-4">
                  <div *ngIf="roleInit !== 'admin'">
                    <p><strong>Zeitliche Verfügbarkeit für Lesesitzungen</strong></p>
                    <mat-form-field>
                      <input matInput formControlName="availabilityMo"  type="text" placeholder="Montag">
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput formControlName="availabilityTu"  type="text" placeholder="Dienstag">
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput formControlName="availabilityWe"  type="text" placeholder="Mittwoch">
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput formControlName="availabilityTh"  type="text" placeholder="Donnerstag">
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput formControlName="availabilityFr"  type="text" placeholder="Freitag">
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput formControlName="availabilitySa"  type="text" placeholder="Samstag">
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput formControlName="availabilitySu"  type="text" placeholder="Sonntag">
                    </mat-form-field>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big" aria-label="Registration">
                  Registrieren
                </button>
              </div>
              <div class="col-md-4"></div>
            </div>
          </form>
        </div>
    </div>
  </div>
</div>