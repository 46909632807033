<div class="admin-settings container-fluid">
  <div class="admin-settings_wrap row">
    <div class="col-md-12">
          <h1 class="pt-3 align-center">Einstellungen</h1>
          <form [formGroup]="rForm" #formDirective="ngForm">
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                  <mat-form-field>
                    <textarea matInput rows="4" formControlName="technicalSupport" placeholder="Technischer Ansprechpartner"></textarea>
                  </mat-form-field>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                  <mat-slide-toggle formControlName="showContactPerson" color="primary">Ansprechpartner anzeigen</mat-slide-toggle>
                  <br>
                  <br>
                  <div *ngIf="rForm.value.showContactPerson">
                    <hr>
                    <br>
                  </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row" *ngIf="rForm.value.showContactPerson">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                  <mat-slide-toggle formControlName="showContactMentor" color="primary">Mentor Anzeigen</mat-slide-toggle>
                  <br>
                  <br>
                  <div *ngIf="rForm.value.showContactMentor">
                    <mat-form-field>
                      <textarea matInput rows="4" formControlName="mentorContactIntro" placeholder="Mentor Ansprechpartner Einleitung"></textarea>
                    </mat-form-field>
                  </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row" *ngIf="rForm.value.showContactPerson">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                  <mat-slide-toggle formControlName="showContactCoordinator" color="primary">Koordinator Anzeigen</mat-slide-toggle>
                  <br>
                  <br>
                  <div *ngIf="rForm.value.showContactCoordinator">
                    <mat-form-field>
                      <textarea matInput rows="4" formControlName="coordinatorContactIntro" placeholder="Koordinator Ansprechpartner Einleitung"></textarea>
                    </mat-form-field>
                  </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="button-group">
                  <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big" aria-label="Edit">
                      Speichern
                  </button>
                </div>
              </div>
              <div class="col-md-3">
                <div class="button-group">
                  <button mat-button color="accent" type="button" class="size-big" [routerLink]="['/admin-dashboard']" aria-label="Cancel">
                    Abbrechen
                  </button>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
          </form>
    </div>
  </div>
</div>