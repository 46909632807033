import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      note: string,
      accept: boolean,
      acceptIcon: string,
      acceptText: string,
      decline: boolean,
      declineIcon: string,
      declineText: string,
    },
    private matDialogRef: MatDialogRef<ConfirmDialogComponent>
  ) { }

  public cancel() {
    this.matDialogRef.close('decline');
  }

  public apply() {
    this.matDialogRef.close('ok');
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.matDialogRef.close('decline');
  }

}
