<div class="session container-fluid pt-3">
  <div class="session_wrap row">
    <div class="col-md-12">

      <mat-table [dataSource]="dataSourceStudent" class="mat-elevation-z1" class="student-table">

        <ng-container matColumnDef="preview-button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon  (click)="openStudentProfil(element._id)">preview</mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstname">
            <mat-header-cell *matHeaderCellDef>Vorname</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.firstname}} 
            </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="lastname">
            <mat-header-cell *matHeaderCellDef >Nachname</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.lastname}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="school">
            <mat-header-cell *matHeaderCellDef>Schule</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{  element.school?.name}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="monday">
          <mat-header-cell *matHeaderCellDef>Montag</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ extractNameFromJson(element.availability, 'monday') }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tuesday">
          <mat-header-cell *matHeaderCellDef>Dienstag</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ extractNameFromJson(element.availability, 'tuesday') }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="wednesday">
          <mat-header-cell *matHeaderCellDef>Mittwoch</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ extractNameFromJson(element.availability, 'wednesday') }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="thursday">
          <mat-header-cell *matHeaderCellDef>Donnerstag</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ extractNameFromJson(element.availability, 'thursday') }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="friday">
          <mat-header-cell *matHeaderCellDef>Freitag</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ extractNameFromJson(element.availability, 'friday') }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="saturday">
          <mat-header-cell *matHeaderCellDef>Samstag</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ extractNameFromJson(element.availability, 'saturday') }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sunday">
          <mat-header-cell *matHeaderCellDef>Sonntag</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ extractNameFromJson(element.availability, 'sunday') }}
          </mat-cell>
        </ng-container>
      
          <mat-header-row *matHeaderRowDef="displayedStudentColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedStudentColumns;"></mat-row>
      </mat-table>
      <!-- <mat-paginator #paginatorStudent [pageSize]="1"></mat-paginator> -->

      
      <div class="table-navigation">
        <div class="filter-area">
          <div class="filter-wrap">
            <mat-form-field>
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Vorname, Nachname" #input>
            </mat-form-field>

            <mat-checkbox (change)="studentsSelectionFilter($event)">Mentoren ohne Lesekind</mat-checkbox>

            <mat-checkbox (change)="studentsCapacitySelectionFilter($event)">Mentoren mit Kapazität</mat-checkbox>

            <mat-checkbox (change)="availabilityMatchSelectionFilter($event)">Wochentage abgleichen</mat-checkbox>
          </div>
        </div>
      </div>

      <mat-table [dataSource]="dataSource" class="mat-elevation-z1" matSort matSortActive="created" matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Anlagedatum</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon (click)="openMentorProfil(element._id)">preview</mat-icon>
            </mat-cell>
          </ng-container>
        
          <ng-container matColumnDef="firstname">
              <mat-header-cell *matHeaderCellDef  mat-sort-header>Vorname</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{element.firstname}} 
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastname">
              <mat-header-cell *matHeaderCellDef  mat-sort-header>Nachname</mat-header-cell>
              <mat-cell *matCellDef="let element">
                    {{ element.lastname}} 
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="coordinator">
              <mat-header-cell *matHeaderCellDef  mat-sort-header><!--Koordinator--></mat-header-cell>
              <mat-cell *matCellDef="let element">
                    <!-- {{ element.coordinator}} -->
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="monday">
            <mat-header-cell *matHeaderCellDef>Montag</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ extractNameFromJson(element.availability, 'monday') }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tuesday">
            <mat-header-cell *matHeaderCellDef>Dienstag</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ extractNameFromJson(element.availability, 'tuesday') }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="wednesday">
            <mat-header-cell *matHeaderCellDef>Mittwoch</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ extractNameFromJson(element.availability, 'wednesday') }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="thursday">
            <mat-header-cell *matHeaderCellDef>Donnerstag</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ extractNameFromJson(element.availability, 'thursday') }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="friday">
            <mat-header-cell *matHeaderCellDef>Freitag</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ extractNameFromJson(element.availability, 'friday') }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="saturday">
            <mat-header-cell *matHeaderCellDef>Samstag</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ extractNameFromJson(element.availability, 'saturday') }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sunday">
            <mat-header-cell *matHeaderCellDef>Sonntag</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ extractNameFromJson(element.availability, 'sunday') }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="match-button">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon (click)="openMatch(element)">call_merge</mat-icon>
            </mat-cell>
          </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginator [pageSize]="7"></mat-paginator>
    </div>
  </div>
</div>

