import * as React from 'react';
import { Card }  from  './card.component';

interface BoardProps {
    animated?;
    currentCard? : {
        value?;
        color?;
        isspecial?;
    };
}

export class Board extends React.Component<BoardProps> {
    state;
    constructor(props:BoardProps){
        super(props);
        this.state = { 
            card: props.currentCard
        };       
        this.changeCard.bind(this);
    }

    changeCard(card){
        
        this.setState({card:card});
    }

    componentDidMount(){
        //console.log('Board.component.tsx::componentDidMount');
    }

    render(){
        return (
            <div className={`board-card ${this.props.animated?this.props.animated:""}`}  >
                 <Card  value={this.state.card.value} color={this.state.card.color} isspecial={this.state.card.isspecial} />
            </div>
        )
    }
}