import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeformat',
  pure: false
})
export class TimeformatPipe implements PipeTransform {
  constructor() {}

  transform(value: any, arg: string) {
      if (typeof(value) === 'number') {
        if (arg) {
          const format = val => `0${Math.floor(val)}`.slice(-2);
          const seconds = value;
          const hours = seconds / 3600;
          const minutes = (value % 3600) / 60;
          
          if (arg === 'sec') {
            // value = value/60;
            // var sec = parseInt(value);
            return seconds;
          }
          if (arg === 'min') {
            return [minutes, seconds % 60].map(format).join(':');
          } else if (arg === 'hours') {
            return [hours, minutes, seconds % 60].map(format).join(':')
          } else {
            return value;
          }
        } else {
          return value;
        }
      }
  }

  public formatTime(nbSeconds, hasHours) {
      var time = [],
          s = 1;
      var calc = nbSeconds;

      if (hasHours) {
          s = 3600;
          calc = calc / s;
          time.push(Math.floor(calc));//hour
      }

      calc = ((calc - (time[time.length-1] || 0)) * s) / 60;
      time.push(Math.floor(calc));//minute

      calc = (calc - (time[time.length-1])) * 60;
      time.push(Math.round(calc));//second


      // function format(n) {//it makes "0X"/"00"/"XX"
      //     return (("" + n) / 10).toFixed(1).replace(".", "");
      // }

      //if (!hasHours) time.shift();//you can set only "min: sec"

      return time.join(":");
  }
}
