import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Subject,BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



export interface User {
  _id: string;
  username: string;
  role: string;
  email: string;
  password: string;
  avatar: string;
  readingTime: string;
  studentBooks?: any;
  students?: string[];
  school?: string;
  passwordResetRequest?: boolean;
}

export interface Students {
    username: string;
    _id: string;
  	role: string;
}

export interface Mentors {
  username: string;
  _id: string;
  role: string;
}

export interface Coordinators {
  _id: string;
  username: string;
  firstname?: string;
  lastname?: string;
  role: string;
}


export interface Admins {
  _id: string;
  username: string;
  firstname?: string;
  lastname?: string;
  role?: string;
}

@Injectable({
  providedIn: 'root'
})

export class UserService {
    private restServerUrl;
    user: User[] = [];
    students: Students[] = [];
    mentors: Mentors[] = [];
    admins: Admins[] = [];
    userUpdated = new Subject<User[]>();
    adminsUpdated = new Subject<Admins[]>();
    mentorStudents: Students[] = [];
    mentorsUpdated = new Subject<Mentors[]>();
    coordinators: Coordinators[] = [];
    coordinatorsUpdated = new Subject<Coordinators[]>();
    mentorsAndCoordinators: Mentors[] = [];
    mentorsAndCoordinatorsUpdated = new Subject<Mentors[]>();
    mentorStudentsUpdated = new Subject<Students[]>();
    studentsUpdated = new Subject<Students[]>();
    onMentorsChanged: BehaviorSubject<any>;
    onStudentsChanged: BehaviorSubject<any>;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private http: HttpClient
    ) {
        this.onStudentsChanged = new BehaviorSubject([]);
        let protocol = environment.ssl ? 'https://' : 'http://';
	    	this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
     }

  getStudentsUpdateListener() {
		return this.studentsUpdated.asObservable();
  }

  getMentorStudentsUpdateListener() {
		return this.mentorStudentsUpdated.asObservable();
  }

  getMentorsUpdateListener() {
		return this.mentorsUpdated.asObservable();
  }

  getAdminsUpdateListener() {
		return this.adminsUpdated.asObservable();
  }

  getCoordinatorsUpdateListener() {
		return this.coordinatorsUpdated.asObservable();
  }

  getMentorsAndCoordinatorsUpdateListener() {
		return this.mentorsAndCoordinatorsUpdated.asObservable();
  }
  
  /**
   * edit user data
   */
  updateUser(data)  {
    console.log(data)
    return this.http.put(
      this.restServerUrl + '/api/user/editUser/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(res => {
          return <User>res;
        }));
  }

  getUserbyId(id) {
    return this.http.get<User[]>(this.restServerUrl + '/api/user/profile/' + id)
      .pipe(map(data => data));
  }

  getCoordinatorBySchool(id) {
    return this.http.get<User[]>(this.restServerUrl + '/api/user/coordinatorBySchool/' + id)
      .pipe(map(data => data));
  }

  /**
   * get list of all students
   */
	getStudentsList() {
		this.http.get<Students[]>(this.restServerUrl + '/api/user/students')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.students = data;
                this.studentsUpdated.next([...this.students]);
                this.onStudentsChanged.next(this.students);
			});
  }

  /**
   * get list of all mentors
   */
	getMentorList() {
		this.http.get<Mentors[]>(this.restServerUrl + '/api/user/mentorsList')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.mentors = data;
                this.mentorsUpdated.next([...this.mentors]);
                // this.onMentorsChanged.next(this.mentors);
			});
  }

  /**
   * get list of all admins
   */
	getAdminList() {
		this.http.get<Admins[]>(this.restServerUrl + '/api/user/adminsList')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.admins = data;
                this.adminsUpdated.next([...this.admins]);
                // this.onMentorsChanged.next(this.mentors);
			});
  }

  /**
   * get list of all coordinators
   */
	getCoordinatorsList() {
		this.http.get<Coordinators[]>(this.restServerUrl + '/api/user/coordinatorsList')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.coordinators = data;
                this.coordinatorsUpdated.next([...this.coordinators]);
			});
  }

  /**
   * get list of all mentors and coordinators
   */
   getMentorsAndCoordinatorsList() {
		this.http.get<Coordinators[]>(this.restServerUrl + '/api/user/mentorsAndCoordinatorsList')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.mentorsAndCoordinators = data;
                this.mentorsAndCoordinatorsUpdated.next([...this.mentorsAndCoordinators]);
			});
  }

  getAllStudensOfMentor(id) {
		this.http.get<Students[]>(this.restServerUrl + '/api/user/studentsList/' + id)
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
        console.log(data)
                this.mentorStudents = data["students"];
                this.mentorStudentsUpdated.next([...this.mentorStudents]);
			});
  }

  /**
   * add students to mentor
   */
  addStudentsToMentor(mentorId, studentArray)  {
    const data = {
      mentorId: mentorId,
      studentArray: studentArray
    };

    return this.http.put(
      this.restServerUrl + '/api/user/addStudents', data)
        .pipe(map(res => {
          return <User>res;
        }));

    // const data = {
    //   students: studentArray
    // };
    // return this.http.put(
    //   this.restServerUrl + '/api/user/editUser/' + mentorId,
    //   JSON.stringify(data),
    //   { headers: new HttpHeaders().set('Content-Type', 'application/json') })
    //     .pipe(map(res => {
    //       return <User>res;
    //     }));
  }

  /**
   * add single student to mentor and change next meeting date
   */
   addSingleStudentToMentor(mentorId, studentId/*, nextMeeting*/)  {
    const data = {
      mentorId: mentorId,
      studentId: studentId,
      //nextMeeting: nextMeeting
    };

    return this.http.put(
      this.restServerUrl + '/api/user/addSingleStudent', data)
        .pipe(map(res => {
          return <User>res;
        }));
  }

  /**
   * remove students to mentor
   */
  removeStudentsFromMentor(mentorId, studentArray)  {
    const data = {
      mentorId: mentorId,
      studentArray: studentArray
    };

    return this.http.put(
      this.restServerUrl + '/api/user/removeStudents', data)
        .pipe(map(res => {
          return <User>res;
        }));
  }
  
  /**
   * get list of read books
   */
	getStudentBooks(id) {
		return this.http.get<Students[]>(this.restServerUrl + '/api/user/booksList/' + id)
			.pipe(map(data => data));
  }

  sendSmsToUser(messageTo, messageBody) {
    const formData = {
      messageTo: messageTo,
      messageBody: messageBody
    };

    return this.http.post<any>(this.restServerUrl + '/api/user/sendSmsToUser', formData,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(res => {
          return res;
        }));
  }

  /**
   * set delete user
  */
  deleteUserAndRemoveRelation(id: string) {
    return this.http.delete(this.restServerUrl + '/api/user/' + id)
            .pipe(map(res => {
              return res;
            }));
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}