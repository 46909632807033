<div class="session container-fluid pt-3">
  <div class="session_wrap row">
    <div class="col-md-12">

      <div class="table-navigation">
        <div class="filter-area">
          <div class="filter-wrap">
            <mat-form-field>
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Nachname, Vorname, Ort, Schule" #input>
            </mat-form-field>
  
            <mat-checkbox (change)="studentsSelectionFilter($event)">Koordinatoren ohne Lesekind</mat-checkbox>
          </div>
        </div>
        <div class="button-area">
          <a [routerLink]="['/coordinator-create']">
            <mat-icon >person_add</mat-icon> Koordinator anlegen
          </a>
        </div>
      </div>

      <mat-table [dataSource]="dataSource" class="mat-elevation-z1" matSort matSortActive="username" matSortDisableClear matSortDirection="asc">
        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Benutzername</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.username}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstname">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Vorname</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.firstname}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastname">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Nachname</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.lastname}} 
            </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="supervisingSchools">
          <mat-header-cell *matHeaderCellDef>Betreute Schulen</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container *ngFor="let element of row.supervisingSchools; index as i">
                <span *ngIf="element.name">
                  {{i+1}}. {{ element.name }}
                  <br />
                </span>
            </ng-container>
        </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" >
                <div>
                    <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More">
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>

                    <mat-menu #moreMenu="matMenu">
                        <button mat-menu-item aria-label="add-note" [routerLink]="['/coordinator-edit/' + element._id]">
                            <mat-icon>create</mat-icon>
                            <span>Bearbeiten</span>
                        </button>
                        <button mat-menu-item aria-label="add-note" [routerLink]="['/coordinator-matching/' + element._id]">
                            <mat-icon>link</mat-icon>
                            <span>Matching</span>
                        </button>
                        <button mat-menu-item aria-label="add-note" [routerLink]="['/coordinator-mapping/' + element._id]">
                            <mat-icon>assignment</mat-icon>
                            <span>Matchings einsehen & aufheben</span>
                        </button>
                        <button mat-menu-item aria-label="downgrade" *ngIf="isAdmin" (click)="openCoordinatorDowngrade(element._id)">
                            <mat-icon>trending_down</mat-icon>
                            <span>zu Mentor herabstufen</span>
                        </button>
                        <button mat-menu-item aria-label="downgrade" *ngIf="isAdmin && element.mobileNumber" (click)="sendSmsToUser(element)">
                          <mat-icon>sms</mat-icon>
                          <span>Info über neue Lesekinder</span>
                      </button>
                        <button mat-menu-item aria-label="password" [routerLink]="['/change-password/' + element._id]">
                            <mat-icon>lock</mat-icon>
                            <span>Kennwort ändern</span>
                        </button>
                        <button mat-menu-item aria-label="remove" *ngIf="isAdmin" (click)="deleteUser(element._id, element.firstname, element.lastname)">
                          <mat-icon>delete</mat-icon>
                          <span>Löschen</span>
                        </button>
                    </mat-menu>
                </div>
            </mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginator [pageSize]="10"></mat-paginator>
    </div>
  </div>
</div>
