import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Platform } from '@ionic/angular';
import { Router, Event, NavigationEnd } from '@angular/router';
import { WebSocketService } from './providers/websocket.service';
import { WebrtcService } from './providers/webrtc.service';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './helpers/confirm-dialog/confirm-dialog.component';
import { IframeComponent } from './helpers/iframe/iframe.component';
import { UserService } from "./providers/user.service";
import { environment } from '../environments/environment';
import { Plugins } from '@capacitor/core';
import { CapacitorService } from './providers/capacitor.service';

const { SplashScreen } = Plugins;
declare let cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  showTabletFrame: boolean = true;
  APP_VERSION = environment.appVersion;
  IS_APP = environment.isApp;
  CLIENT_ID = environment.clientId;
  CLIENT_TITLE = environment.clientTitle;
  clientLogoNavbar = environment.clientLogoNavbar;
  IS_TOUCH_DEVICE: boolean = false;
  splashScreenBrowser: boolean = false;
  deviceInfo;
  networkStatusOnline: any = {
    connected: true
  };
  surveyUrl: string = 'https://www.digi-sapiens.de/fragebogen-fuer-mentorinnen';
  speedtestUrl: string = 'https://fast.com/de/';
  roomId;
  userId;
  userType;
  dialogConfig: any;
  isCoordinator: boolean = false;
  isMentor: boolean = false;
  scientificSurvey: boolean = false;
  hideBackbutton: boolean = false;
  // ringTone: any;
  ringTone: any;
  private _unsubscribeAll: Subject<any>;
  private authListenerSubs: Subscription;
  private socketListenerSubs: Subscription;
  userIsAuthenticated: boolean = false;

  @ViewChild('ringtone') audioRef: ElementRef;
  @HostListener('window:message', ['$event'])
        private onWindowMessage(event) {
            // Close modal if CF7 submittion succeeded
            if (~event.origin.indexOf('https://www.digi-sapiens.de')) {
                if(event.data == 'wpcf7submit-success') {
                  setTimeout(() => {
                    this.dialog.closeAll();
                  }, 2000);
                }
            } else { 
                // The data hasn't been sent from https://www.digi-sapiens.de
                return; 
            }
        }

  constructor(
    private platform: Platform,
    public router: Router,
    private webSocketService: WebSocketService,
    public webRTC: WebrtcService,
    private authService : AuthService,
    public authGuard:AuthGuard,
    public dialog: MatDialog,
    private capacitorService: CapacitorService,
    public userService: UserService,
    private titleService: Title
  ) {
    this.initializeApp();
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Check for touch device
    this.IS_TOUCH_DEVICE = this.authGuard.isTouchDevice();
    if (this.IS_TOUCH_DEVICE) {
        document.documentElement.classList.add('touch_detected');
    }
    // Capacitor device info
    this.capacitorService.readDeviceInfo().then(
        (data) => {
          this.deviceInfo = data;
          const devicePlatform = this.deviceInfo.platform.toLowerCase();
          // Set device data for other components
          if(this.IS_APP) {
            document.documentElement.classList.add('is_app');
          }
          // Add platform class to html
          document.documentElement.classList.add('platform_'+ devicePlatform);
          // Capacitor network status
          if (devicePlatform !== 'web') {
              // Check network status
              this.capacitorService.getNetworkStatus();
              // Call network status event listener
              this.capacitorService.startListenToNetwork();
              // Watch network status changes
              this.capacitorService.watchNetWorkStatus().pipe(
                  takeUntil(this._unsubscribeAll)
                ).subscribe(event => {
                  this.networkStatusOnline = event;
                  console.log('Network', this.networkStatusOnline);
              });
              if (devicePlatform === 'android') {
                // Add back button logic
                this.capacitorService.androidBackButtonLogic();
              }
          } else {
              // Show web splashscreen for 5 seconds only in production
              if (!this.IS_APP && environment.production) {
                // Splash for Mentor hessen only
                if (this.CLIENT_ID == 'app.mentor.lese') {
                  this.splashScreenBrowser = true;
                  setTimeout(() => {
                    this.splashScreenBrowser = false;
                  }, 5000);
                }
              }
          }
          // Listen for app status change
          this.capacitorService.appStateChange();
      },
      (err) => {
        console.log(err);
      }
    );
    // Statusbar from cordova as long as capacitor does not support ios
    //this.capacitorService.setStatusBarBackgroundColor('#f5f5f5');
    //document.documentElement.classList.add('touch_detected');

    this.router.events.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes('/room')) { 
            this.hideBackbutton = true;
          } else {
            this.hideBackbutton = false;
          }
        }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
  }

  ngOnInit(): void {
    // Update title
    // let title = this.titleService.getTitle();
    // this.titleService.setTitle(title + ' v' + environment.appVersion);

    if (this.IS_APP) {
      this.showTabletFrame = false;
    }
    // Socket listener for mentor switching student
    this.socketListenerSubs = this.authService.getSocketListener()
      .subscribe(room => {
        if (room) {
          this.joinSocketRoom();
        }
    });

    // Load app settings
		this.authService.loadAppSettings();

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authService.autoAuthUser();
    this.authListenerSubs = this.authService.getAuthStatusListener()
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.userIsAuthenticated = isAuthenticated;
          console.log(this.userIsAuthenticated)
          this.userId = this.authService.getCurrentID();
          this.userType = this.authService.getType();
          this.scientificSurvey = this.authService.getScientificSurveyStatus();
          this.createSocket();
          if (this.userType == 'student') {
            console.log('listening for call')
            this.webSocketService.listen('call-request').subscribe((data) => this.callRequestDialog(data));
          }
          // this.webSocketService.emit('login', { userId: this.userId });
          // Listen for cancelled call on both ends
          this.webSocketService.listen('call-cancelled').subscribe((data) => this.callRequestGotCancelled());
          // Listen for multiple logins
          this.webSocketService.listen('validate-token').subscribe((data) => this.validateUserToken());

          // Reset passwordResetRequest if successfully logged in after request
          if (this.authService.getPasswordResetRequestStatus()) {
              this.userService.updateUser({
                _id : this.userId,
                passwordResetRequest : false,
              }).pipe(
                takeUntil(this._unsubscribeAll)
              ).subscribe(response => {
                this.authService.setPasswordResetRequestStatus(response['passwordResetRequest']);
              });
          }
        } else {
          // Disconnect from socket if not logged in
          if (this.webSocketService.checkSocketStatus()) {
            this.webSocketService.disconnectFromSocket();
          }
        }
    });
    this.authService.setAuthenticatedStatus();
    // Create ring tone
    //this.ringTone = new Audio();
    //this.ringTone.src = "/assets/sounds/simple_beep.mp3";
    //this.ringTone.load();

    this.ringTone = document.getElementById('ringtone');
    this.ringTone.muted = true;
    // Safari fix
    // window.addEventListener('touchstart', () => {
    //   this.ringTone.play();
    //   this.ringTone.pause();
    //   this.ringTone.muted = true;
    // });
  }

  /**
   * Open modal by call
   */  
  callRequestDialog(deviceInfo) {
    if (this.router.url != "/room/" + this.roomId) {
      // Only prompt incoming call if student has a mentor
      if (this.authService.checkIfStudentHasMentor()) {
        // Set device info from call request
        this.webRTC.setPartnerDeviceInfo(deviceInfo);
        // Generate dialog
        this.dialogConfig = new MatDialogConfig();
        this.dialogConfig.disableClose = true;
        this.dialogConfig.autoFocus = false;
        //this.dialogConfig.width = '400px';
        this.dialogConfig.data = {
          title: 'Eingehender Anruf',
          note: 'Willst du den Anruf entgegenehmen?',
          accept: true,
          acceptIcon: 'phone',
          acceptText: 'Ja',
          decline: true,
          declineIcon: 'phone_disabled',
          declineText: 'Nein',
        };
        this.ringTone.play();
        this.ringTone.muted = false;
        this.dialog.closeAll();
        let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          // Navigate to room if call got accepted
          if (result == 'ok') {
            this.router.navigate(["/room/" + this.roomId]);
            // Inform mentor that call got accepted and send device info of receiver
            this.webSocketService.emitTo('call-accepted', this.webSocketService.getCurrentRoom(), this.capacitorService.getDeviceInfo());
          } else if (result == 'decline') {
            // Inform mentor of cancelling call request
            this.webSocketService.emitTo('call-cancelled', this.webSocketService.getCurrentRoom(), '');
          } else {}
          // Stop ringtone and reset for new call
          this.ringTone.pause();
          this.ringTone.currentTime = 0;
        });
      } else {
        console.log('Mentor tried to call student but mentor is not set')
        setTimeout(() => {
          this.webSocketService.emitTo('call-cancelled', this.webSocketService.getCurrentRoom(), '');
        }, 1000);
      }
    }
  }


  /**
   * Close modal on cancelled call
   */ 
  callRequestGotCancelled() {
    this.dialog.closeAll();
  }

  /**
   * create socket with userId
   */  
  createSocket() {
    this.webSocketService.initSocket(this.userId);
    this.joinSocketRoom();
  }

  /**
   * join socket room with the ID of Student
   */  
  joinSocketRoom() {
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      // Set first student as active if not already set
      if (!this.authService.getActiveStudent()) {
        this.authService.setFirstStudentOfMentorAsActive();
      }
      var activeStudent = this.authService.getActiveStudent();
      if (activeStudent != undefined) {
        console.log(activeStudent)
        this.roomId = activeStudent._id;
      }
    } else {
      this.roomId  = this.authService.getCurrentID();
    }

    if (this.userType == 'coordinator') {
      this.isCoordinator = true;
      this.isMentor = false;
    } else if (this.userType == 'mentor') {
      this.isCoordinator = false;
      this.isMentor = true;
    } else {
      this.isCoordinator = false;
      this.isMentor = false;
    }

    console.log(`this.roomId: r-${this.roomId}`)
    this.webSocketService.joinRoom(this.roomId);
  }

  goToLink(url: string){
      window.open(url, "_blank");
  }

  goToBugReportUrl() {
    let url = 'https://www.digi-sapiens.de/app-fehler-melden';
    if (this.CLIENT_ID == 'app.litcam.lese') {
      url = 'https://www.digi-sapiens.de/app-fehler-melden-ftk';
    }
    window.open(url, "_blank");
  }

  /**
   * logout and disconnect from Socket 
   */  
  logout() {
    this.userIsAuthenticated = false;
    this.webSocketService.disconnectFromSocket();
    this.authService.logout();
  }

  /**
   * ask user to logout 
   */  
  logoutQuestion() {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.data = {
      title: 'Achtung',
      note: 'Benutzer wirklich abmelden?',
      accept: true,
      acceptIcon: '',
      acceptText: 'Ja',
      decline: true,
      declineIcon: '',
      declineText: 'Nein'
    };
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(result => {
      if (result == 'ok') {
       this.logout();
      }
    });
  }

  /**
   * open overlay for scietific survey or speedtest
   */  
   openIframeAsOverlay(url) {
    let params = false;
    if (url == this.surveyUrl) { params = true; }
    const dialogRef = this.dialog.open(IframeComponent, {
      width: '100%',
      height: '80%',
      autoFocus: false,
      panelClass: 'iframe-overlay-pane',
      data: {
        url: url,
        mentorId: this.userId,
        params: params
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

  validateUserToken() {
    this.authService.validateToken();
  }
  
  logoRouting() {
    if (this.userIsAuthenticated) {
      if (this.userType != 'admin') {
        this.router.navigate(["/dashboard"]);
      } else {
        this.router.navigate(["/admin-dashboard"]);
      }
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
      this.authListenerSubs.unsubscribe();
  }
}