import { Component, OnInit } from '@angular/core';
import { BookListComponent } from '../books/book-list/book-list.component';
import { MatDialog } from '@angular/material/dialog';
import { EbooksEpubService } from "../providers/epub.service";
import { AuthService } from '../auth/auth.service';
import { SessionService } from '../providers/session.service';
import { UserService } from "../providers/user.service";
//import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { QuizComponent } from '../quiz/quiz.component';

@Component({
  selector: 'app-single-reading-silent',
  templateUrl: './single-reading-silent.component.html',
  styleUrls: ['./single-reading-silent.component.scss'],
})
export class SingleReadingSilentComponent implements OnInit {
  IS_APP = environment.isApp;
  book;
  bookId;
  bookName;
  bookUrl = '';
  bookActivePage = 1;
  bookStartPage = 1;
  readingModeIsPDF: boolean = false;
  readingModeIsEpub: boolean = false;
  quizAvailable: boolean = false;
  userId;
  studentBooks: any = [];
  quizPages: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public dialog: MatDialog,
    public ebookEpubService: EbooksEpubService,
    private authService : AuthService,
    private sessionService : SessionService,
    public userService: UserService,
    private insomnia: Insomnia
  ) { }

  ngOnInit() {
    this.bookUrl = '';
    this.userId = this.authService.getCurrentID();
    this.userService.getStudentBooks(this.userId).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(data => {
          if (data.length > 0) {
            this.studentBooks = data;
            // Open last read book
            this.ebookEpubService.getBookById(this.studentBooks[0].book).pipe(
              takeUntil(this._unsubscribeAll)
            ).subscribe(data => {
              if (data['quizPages']) {
                this.quizPages = data['quizPages'];
                delete data['quizPages'];
              }
              console.log(this.quizPages)
              this.book = data;
              this.bookId = data["_id"];
              this.bookName = data["name"];
              this.startReadingMode();
            });
          }
    });
    // Prevent screen from sleeping
    //if (this.IS_APP) {
      this.insomnia.keepAwake();
    //}
  }

  /**
   * open book list modal
   */
  openDialog(): void {
    const dialogRef = this.dialog.open(BookListComponent, {
      width: '100%',
      autoFocus: false
      // data: {id: this._id, url: this.url}
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(book => {
      console.log(book)
      this.bookId = book._id
      this.bookName = book.name
      // Set current active page before switching to new book
      this.setActivePageOnBook(book);
      this.quizAvailable = false;
    });
  }
  
  startReadingMode() {
    // Check for active page to continue reading
    this.bookActivePage = this.getActivePageFromStoredBook(this.book._id);
    this.bookStartPage = this.bookActivePage;
    this.bookUrl = this.ebookEpubService.getBook(this.book.filename);
    this.setReaderByType();
    // setTimeout(() => {
    //   this.checkForQuiz();
    // }, 15000);
}

  startQuizDialog(): void {
    let startPage = this.bookStartPage;
    let activePage = this.bookActivePage;

    if (startPage > activePage) {
      startPage = this.bookActivePage;
      activePage = this.bookStartPage;
    }

    const dialogRef = this.dialog.open(QuizComponent, {
      width: '100%',
      autoFocus: false,
      data: {
        bookId: this.bookId,
        bookName: this.bookName,
        startPage: startPage,
        activePage: activePage
      }
    });
    // dialogRef.disableClose = true;
    // dialogRef.afterClosed().pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe(book => {
    //   // Set current active page before switching to new book
      
    // });
  }

  setReaderByType() {
    if (this.book.format == 'pdf') {
      this.readingModeIsPDF = true;
      this.readingModeIsEpub = false; 
    } else {
      this.readingModeIsPDF = false;
      this.readingModeIsEpub = true; 
    }
  }

  setActivePageOnBook(book?) {
      if (this.book != undefined) {
        // Find current book
        var foundBook = this.studentBooks[this.studentBooks.findIndex((item) => {
          return item.book === this.book._id;
        })];
        // Change active page of current book
        if (foundBook) {
          foundBook.activePage = this.bookActivePage;
          foundBook.lastRead = new Date().toISOString();
        } else {
          this.studentBooks.push({
            book: this.book._id,
            activePage: this.bookActivePage,
            lastRead: new Date().toISOString()
          });
        }
      }
      // Set new book
      if (book) {
        this.book = book;
          this.startReadingMode();
      }
  }

  getActivePageFromStoredBook(id) {
      // Find current book
      var foundBook = this.studentBooks[this.studentBooks.findIndex((item) => {
        return item.book === id;
      })];
      if (foundBook) {
        return foundBook.activePage;
      } else {
        return 1;
      }
  }

  updateReadBooksOnStudent() {
    if (this.book) {
      this.setActivePageOnBook();
      // Update student books in db
      this.userService.updateUser({
        _id : this.userId,
        studentBooks : this.studentBooks
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        console.log(res)
      });
    }
  }

  getActivePage(page) {
    this.bookActivePage = page;
    this.checkForQuiz();
  }

  checkForQuiz() {
    if (this.quizPages.includes(this.bookActivePage)) {
      this.quizAvailable = true;
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.updateReadBooksOnStudent();
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      //console.log('reading unsubscribe -------------')
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }, 500);
    // Allow sleep mode again
    //if (this.IS_APP) {
      this.insomnia.allowSleepAgain();
    //}
  }
  

}
