import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from "../../../auth/auth.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoordinatorDowngradeComponent } from '../coordinator-downgrade/coordinator-downgrade.component';
import { ConfirmDialogComponent } from '../../../helpers/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export interface coordinators {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-coordinator-list',
  templateUrl: './coordinator-list.component.html',
  styleUrls: ['./coordinator-list.component.scss'],
})
export class CoordinatorListComponent implements OnInit {
  displayedColumns: string[] = ['username', 'firstname', 'lastname', /*'supervisingSchools',*/ 'buttons'];
  dataSource:any;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userType;
  dialogConfig: any;
  isAdmin: boolean = false;
  filterValues = {
    search: '',
    students: false
  };

  coordinators: coordinators[] = [];
  private coordinatorsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.userType = this.authService.getType();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    this.userService.getCoordinatorsList();
    this.coordinatorsSub = this.userService.getCoordinatorsUpdateListener().pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe((coordinators: coordinators[]) => {
        this.coordinators = coordinators;
        console.log(this.coordinators)
        this.dataSource = new MatTableDataSource<coordinators>(this.coordinators);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
        // Filter in nested array as well
        this.dataSource.filterPredicate = (data: any, filter) => {
          // Check if students array is empty
          let withoutStudentsFound = false;
          if (filter.students) {
            if (data['students'].length < 1) {
              withoutStudentsFound = true;
            }
          }
          // Find position for search word
          let positionFound = JSON.stringify(data).toLowerCase().indexOf(filter.search) != -1;
          if (filter.students) {
              return withoutStudentsFound && positionFound;
          } else {
              return positionFound;
          }
        };
      });
  }

  studentsSelectionFilter(el) {
    this.filterValues['students'] = el.checked;
    this.dataSource.filter = this.filterValues;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValues['search'] = filterValue.trim().toLowerCase()
    this.dataSource.filter = this.filterValues;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  sendSmsToUser(el) {
    if (el.mobileNumber) {
      let messageBody = 'Hallo Koordinator*in, in der MENTOR Lese-App sind an Deiner Schule neue Kinder dazugekommen. Bitte nehme ein Matching vor. Dein MENTOR Digital Team';
      this.userService.sendSmsToUser(el.mobileNumber, messageBody).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((res:any) => {
        this.snackBar.open(el.firstname + " " + el.lastname + " wurde per SMS informiert", "",{
          duration: 3000,
          horizontalPosition: 'right'
        });
      });
    }
  }

  deleteUser(id, firstname, lastname) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    //this.dialogConfig.width = '400px';
    this.dialogConfig.data = {
      title: 'Achtung',
      note: ' Wollen Sie' +"  " + firstname + " " + lastname + " " +'wirklich löschen?',
      accept: true,
      acceptIcon: '',
      acceptText: 'Ja',
      decline: true,
      declineIcon: '',
      declineText: 'Nein'
    };
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(result => {
      if (result == 'ok') {
        this.userService.deleteUserAndRemoveRelation(id).subscribe(res => {
          console.log(res)
          // Update local list
          this.coordinators.splice(this.coordinators.findIndex((item) => {
              return item._id === id;
          }), 1);
          // Refresh datasource
          this.dataSource._updateChangeSubscription();
          }, (error) => {
              console.error("Error deleting user: ", error);
          });
      }
    });
  }

  openCoordinatorDowngrade(id): void {
    const dialogRef = this.dialog.open(CoordinatorDowngradeComponent, {
      width: '100%',
      autoFocus: false,
      data: { id: id }
    });
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

}
