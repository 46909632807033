import * as React from 'react';

declare module "react" {
    interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
      value?;
      isspecial?;
      index?;
    }
}

interface CardProps {
    color?;
    value?;
    isspecial?;
    index?;
}

export class Card extends React.Component<CardProps> {
    color;
    value;
    isspecial;
    filename;
    index;
    constructor(props:CardProps){
        super(props);
        this.color = props.color;
        this.value = props.value;
        this.isspecial = props.isspecial;
        this.index = props.index;
        if(this.isspecial && this.color != "black"){
            if(this.value == 2) this.filename = `${9+3}_${this.color}.png`;
            else if(this.value == 3) this.filename = `${9+2}_${this.color}.png`;
            else this.filename = `${9+this.value}_${this.color}.png`;
            
        }else if(this.isspecial){
            this.filename = `${this.value}_${this.color}.png`;
        }else{
            this.filename = `${this.value}_${this.color}.png`;
        }
    }

    componentDidMount(){
        //console.log('Card.component.tsx::componentDidMount');
    }


    render(){
        return (
                <img color={this.color} value={this.value} isspecial={this.isspecial?1:0} index={this.index} className="card-deck" src={'assets/games/uno/deck-images/'+this.filename}/>
        )
    }
}
