import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MaterialModule } from '../../material.module';
import { BackButtonComponent } from './back-button.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MaterialModule
  ],
  declarations: [BackButtonComponent],
  exports: [BackButtonComponent]
})
export class BackButtonModule {}
