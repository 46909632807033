<div class="video-elements-wrap" *ngIf="activeVideo" [ngClass]="{'both-ready': showOpponent }">
    <video #myvideo id="my-video" webkit-playsinline="true" playsinline="true" muted="muted" volume="0"
      [ngClass]="{'top-video': topVideoFrame === 'my-video', 'main-video': topVideoFrame != 'my-video'}">
    </video>
    <video #partnervideo id="partner-video" webkit-playsinline="true" playsinline="true" muted="muted" volume="0" autoPlay
      (play)="handleVideoPlay(partnervideo)"
      (pause)="remoteVideoPaused(partnervideo)"
      (loadeddata)="remoteVideoLoaded(partnervideo)"
      [hidden]="!showOpponent"
      [ngClass]="{'top-video': topVideoFrame === 'partner-video', 'main-video': topVideoFrame != 'partner-video'}">
    </video>
    <div class="video-element-play" *ngIf="remoteStreamPaused && remoteStreamActive" (click)="playVideo(partnervideo)">
      <div class="video-element-notice">
        Das Video wurde vom Endgerät pausiert, bitte klicken Sie hier.
      </div>
    </div>
</div>
<!-- <button mat-button color="accent" (click)="activatePartnerAudio()">
  Activate P
</button>
<button mat-button color="accent" (click)="toggleMutePartnerAudio()">
  Mute P
</button>
<button mat-button color="accent" (click)="toggleMuteMyAudio()">
  Mute Me
</button>
<button mat-button color="accent" (click)="getMuteVal()">
  Val?
</button>
{{mutVal}}

<button mat-button color="accent" (click)="partnerVolIncrease()">
  +
</button>
<button mat-button color="accent" (click)="partnerVolDecrease()">
  -
</button>
<button mat-button color="accent" (click)="getCurrentPartnerVol()">
  PVol?
</button>
{{partnerVolVal}} -->