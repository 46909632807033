import { Component, OnInit } from '@angular/core';
import { BookListComponent } from '../book-list/book-list.component';
import { MatDialog } from '@angular/material/dialog';
import { EbooksEpubService } from "../../providers/epub.service";
import { AuthService } from '../../auth/auth.service';
import { SessionService } from '../../providers/session.service';
import { UserService } from "../../providers/user.service";
//import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { FormBuilder,FormArray, FormGroup, FormGroupDirective, AbstractControl } from "@angular/forms";

@Component({
  selector: 'app-book-preview',
  templateUrl: './book-preview.component.html',
  styleUrls: ['./book-preview.component.scss'],
})
export class BookPreviewComponent implements OnInit {
  IS_APP = environment.isApp;
  book;
  bookUrl = '';
  bookActivePage = 1;
  bookStartPage = 1;
  readingModeIsPDF: boolean = false;
  readingModeIsEpub: boolean = false;
  userId;
  studentBooks: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  rForm: FormGroup;
  highlightText = [];
  showAddAnswers = true;

  constructor(
    public dialog: MatDialog,
    public ebookEpubService: EbooksEpubService,
    private authService : AuthService,
    private sessionService : SessionService,
    public userService: UserService,
    private insomnia: Insomnia,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.bookUrl = '';
    this.userId = this.authService.getCurrentID();
    this.userService.getStudentBooks(this.userId).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(data => {
          if (data.length > 0) {
            this.studentBooks = data;
          }
    });
    this.openDialog();

    // Prevent screen from sleeping
    //if (this.IS_APP) {
      this.insomnia.keepAwake();
    //}
  }

  /**
   * open book list modal
   */
  openDialog(): void {
    const dialogRef = this.dialog.open(BookListComponent, {
      width: '100%',
      autoFocus: false
      // data: {id: this._id, url: this.url}
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(book => {
      // Set current active page before switching to new book
      this.setActivePageOnBook(book);
    });
  }

  startReadingMode() {
      // Check for active page to continue reading
      this.bookActivePage = 1;
      this.bookUrl = this.ebookEpubService.getBook(this.book.filename);
      this.setReaderByType();
  }


  setReaderByType() {
    if (this.book.format == 'pdf') {
      this.readingModeIsPDF = true;
      this.readingModeIsEpub = false; 
    } else {
      this.readingModeIsPDF = false;
      this.readingModeIsEpub = true; 
    }
  }

  setActivePageOnBook(book?) {
      // Set new book
      if (book) {
        this.book = book;
          this.startReadingMode();
      }
  }

  getActivePage(page) {
    this.bookActivePage = page;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      //console.log('reading unsubscribe -------------')
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }, 500);
    // Allow sleep mode again
    //if (this.IS_APP) {
      this.insomnia.allowSleepAgain();
    //}
  }
  

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    let questionForm = []; 
    questionForm.push(this.rForm.value.question, this.rForm.value.answers)
    formDirective.resetForm();
    this.rForm.reset();
    console.log(questionForm)
  }
}