import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../providers/session.service';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";

export interface Session {
    student: string;
    mentor: string;
    filename: string;
    sessionReadTime: number;
    readingMode: string;
  }
  

@Component({
    selector: 'session-note',
    templateUrl: 'session-note.html',
  })
  export class SessionNoteDialogComponent {
    rForm: FormGroup;
    id;
    subscription: Subscription;
    noteTitle;
  
    constructor(
      private sessionService : SessionService,
      public dialogRef: MatDialogRef<SessionNoteDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: Session) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
    ngOnInit(): void {
        this.initForm()
    }
  
    initForm(){
      this.rForm = new FormGroup({
        note: new FormControl(null, {
          validators: []
        })
      })
  
      this.subscription = this.sessionService.getSessionbyId(this.data["id"]).subscribe(session => {
        this.rForm.get('note').setValue(session['note']);
        if (session['note'] == undefined) {
            this.noteTitle = "Notiz hinzufügen"
        } else {
            this.noteTitle = "Notiz bearbeiten"
        }
      })
    }
      // submit form with data and navigate to userlist
      submitForm(formDirective: FormGroupDirective) {
      this.sessionService.updateSession({
        _id : this.data["id"],
        note: this.rForm.value.note
      }).subscribe(res => {
        this.dialogRef.close();
      })
    }
  
  }