import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  isAdmin = false;
  isCoordinator = false;
  userType;
  userId;


  constructor(
    private authService : AuthService,
  ) { }

  ngOnInit() {
    this.userType = this.authService.getType();
    this.userId = this.authService.getCurrentID();

    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    if (this.userType == 'coordinator') {
      this.isCoordinator = true;
    }
  }
}
