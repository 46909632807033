<div class="dashboard container-fluid">
  <div class="dashboard_wrap row">
    <div class="col-md-12">
      <div class="button-group">
        <ng-container *ngIf="isAdmin || isCoordinator">
          <h1 class="pt-3">Verwaltungsbereich<span *ngIf="isCoordinator"> für KoordinatorInnen</span></h1>
          <button mat-button color="accent" [routerLink]="['/student-list']" class="size-big"><span *ngIf="isCoordinator">Meine </span><span>Lesekinder</span></button>
          <button mat-button color="accent" [routerLink]="['/school-list']" class="size-big"><span *ngIf="isCoordinator">Meine </span><span>Schulen</span></button>
          <button mat-button color="accent" [routerLink]="['/mentor-list']" class="size-big"><span *ngIf="isCoordinator">Meine </span><span>MentorInnen</span></button>
          <button mat-button color="accent" [routerLink]="['/coordinator-matching/'+userId]" class="size-big" *ngIf="isCoordinator">Mich selbst matchen</button>
          <button mat-button color="accent" [routerLink]="['/session']" class="size-big">Lesesitzungen</button>
          <button mat-button color="accent" [routerLink]="['/books']" class="size-big" *ngIf="isAdmin">Bücher</button>
          <button mat-button color="accent" [routerLink]="['/coordinator-list']" class="size-big" *ngIf="isAdmin">KoordinatorInnen</button>
          <button mat-button color="accent" [routerLink]="['/admin-list']" class="size-big" *ngIf="isAdmin">Administratoren</button>
          <button mat-button color="accent" [routerLink]="['/admin-settings']" class="size-big" *ngIf="isAdmin">Einstellungen</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>