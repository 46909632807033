export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  ssl: true,
  socketServerUrl: 'api-ss.mentor-hessen-app.de',
  socketServerPort: 443,
  peerServerUrl: 'api-ps.mentor-hessen-app.de',
  peerServerPort: 443,
  restServer: 'api-rs.insl-lese-app.de',
  restServerPort: 443,
  isApp: false,
  clientId: "app.insl.lese",
  clientTitle: "InSL Lese-App",
  clientLogo: "insl-logo.jpg",
  clientLogoNavbar: "insl-logo.jpg",
  iceServers: [{
    urls: [ "stun:fr-turn1.xirsys.com" ]
  }, {
      username: "B8RN9MnxJPjngJuNo_wIdOPgcE7SmKpW6F5R6eMmP5WyyRqhP-RLf-Zj3hoZORT5AAAAAGHWvdJkaWdpc2FwaWVucw==",
      credential: "87075380-6ed7-11ec-8779-0242ac120004",
      urls: [
          "turn:fr-turn1.xirsys.com:80?transport=udp",
          "turn:fr-turn1.xirsys.com:3478?transport=udp",
          "turn:fr-turn1.xirsys.com:80?transport=tcp",
          "turn:fr-turn1.xirsys.com:3478?transport=tcp",
          "turns:fr-turn1.xirsys.com:443?transport=tcp",
          "turns:fr-turn1.xirsys.com:5349?transport=tcp"
      ]
  }]
};