import { Location } from '@angular/common';
import { OnInit, Component, Input } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'back-button',
    template: `<button mat-icon-button (click)="goBack()" *ngIf="showButton"><mat-icon>arrow_back</mat-icon></button>`,
})
export class BackButtonComponent implements OnInit {
    showButton: boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(
      private location: Location,
      private router: Router,
      private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.router.events.pipe(
             takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if (this.location.path() == '/dashboard' ||
                    this.location.path() == '/admin-dashboard' ||
                    this.location.path() == '/login') {
                    this.showButton = false;
                } else {
                    this.showButton = true;
                }
            });
    }

    goBack() {
        //console.log(this.location.path())
        if (this.location.path() == '/dashboard' || this.location.path() == '/login') {
            // Do nothing
        } else {
            // Nav tree logic
            if (this.location.path() == '/games') {
                this.router.navigate(["/dashboard"]);  
            } else {
                this.location.back();
            }
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}