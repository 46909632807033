import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebSocketService } from '../providers/websocket.service';
import { WebrtcService } from '../providers/webrtc.service';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { BookListComponent } from '../books/book-list/book-list.component';
import { GameListMultiplayerComponent } from '../games/multiplayer/game-list/game-list-multiplayer.component';
import { EbooksEpubService } from "../providers/epub.service";
import { FileDownloadService } from "../providers/filedownload.service";
import { CapacitorService } from "../providers/capacitor.service";
import { SessionService } from '../providers/session.service';
import { UserService } from "../providers/user.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { IframeComponent } from '../helpers/iframe/iframe.component';

//import moment from 'moment';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
})
export class RoomComponent implements OnInit, OnDestroy {
  IS_APP = environment.isApp;
  roomId: string;
  mentorId: string;
  username: string;
  firstname: string;
  isMentor: boolean = false;
  scientificSurvey: boolean = false;
  readingMode: boolean = false;
  readingModeReadyCount: number = 0;
  readingModeReadyForRecord: boolean = false;
  readingModeIsPDF = false;
  readingModeIsEpub = false;
  gamingMode: boolean = false;
  isRecording: boolean = false;
  activeGame: string = '';
  surveyUrl: string = 'https://www.digi-sapiens.de/fragebogen-kind';
  bookUrl = '';
  _id: string;
  url: string;
  books;
  book;
  bookActivePage = 1;
  bookStartPage = 1;
  studentBooks: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public webRTC: WebrtcService,
    public dialog: MatDialog,
    private webSocketService: WebSocketService,
    private authService : AuthService,
    public ebookEpubService: EbooksEpubService,
    public fileDownloadService: FileDownloadService,
    public capacitorService: CapacitorService,
    private sessionService : SessionService,
    public userService: UserService,
    private insomnia: Insomnia
  ) { }

  ngOnInit() {
    //this.capacitorService.checkUserMediaStatus();
    this.bookUrl = '';
    this.username = this.authService.getUsername();
    this.firstname = this.authService.getFirstname();
    this.roomId = this.route.snapshot.params['id'];
    if (this.authService.getType() == 'mentor' || this.authService.getType() == 'coordinator') {
      // Set extra bg class
      this.isMentor = true;
      this.mentorId = this.authService.getCurrentID();
      this.scientificSurvey = this.authService.getScientificSurveyStatus();
      // Load last read book
      this.userService.getStudentBooks(this.roomId).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(data => {
          if (data.length > 0) {
            this.studentBooks = data;
            // Open last read book
            this.ebookEpubService.getBookById(this.studentBooks[0].book).pipe(
              takeUntil(this._unsubscribeAll)
            ).subscribe(data => {
              this.bookActivePage = this.studentBooks[0].activePage;
              this.book = data;
              this.bookUrl = this.ebookEpubService.getBook(this.book.filename);
            });
          }
      });
    } else {
      this.mentorId = this.authService.getMentorOfChild();
      this.webSocketService.listen('room-settings').pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((data) => this.setRoomSettingsFromMentor(data));
    }
    // Listen for handup
    this.webSocketService.listen('call-hangup').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.hangupCall());
    // Listen for book ready on both ends
    this.webSocketService.listen('book-reader-ready').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.changeReadingReadyState(data.ready));
    // Prevent screen from sleeping
    //if (this.IS_APP) {
      this.insomnia.keepAwake();
    //}
  }

  /**
   * open book list modal
   */
  openBooksDialog(): void {
    const dialogRef = this.dialog.open(BookListComponent, {
      width: '100%',
      autoFocus: false,
      data: { canChooseBook: this.isMentor }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(book => {
      if (this.isMentor && book != undefined) {
        // Set current active page before switching to new book
        this.setActivePageOnBook(book);
      }
    });
  }

  /**
   * open game list modal
   */
  openGamesDialog(): void {
    const dialogRef = this.dialog.open(GameListMultiplayerComponent, {
      width: '100%',
      autoFocus: false
      // data: {id: this._id, url: this.url}
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(game => {
      if (this.isMentor && game != undefined) {
        // Set active game to show
        this.activeGame = game;
        this.switchToGamingMode();
      }
    });
  }

  setReaderByType() {
    if (this.book.format == 'pdf') {
      this.readingModeIsPDF = true;
      this.readingModeIsEpub = false; 
    } else {
      this.readingModeIsPDF = false;
      this.readingModeIsEpub = true; 
    }
  }

  setActivePageOnBook(book?) {
    console.log(book)
    if (this.book != undefined) {
      // Find current book
      var foundBook = this.studentBooks[this.studentBooks.findIndex((item) => {
        return item.book === this.book._id;
      })];
      // Change active page of current book
      if (foundBook) {
        foundBook.activePage = this.bookActivePage;
        foundBook.lastRead = new Date().toISOString();
      } else {
        this.studentBooks.push({
          book: this.book._id,
          activePage: this.bookActivePage,
          lastRead: new Date().toISOString()
        });
      }
    }
    // Set new book
    if (book) {
      console.log('drin')
      this.book = book;
      this.switchToReadingMode();
    }
  }

  getActivePageFromStoredBook(id) {
      // Find current book
      var foundBook = this.studentBooks[this.studentBooks.findIndex((item) => {
        return item.book === id;
      })];
      if (foundBook) {
        return foundBook.activePage;
      } else {
        return 1;
      }
  }

  updateReadBooksOnStudent() {
    if (this.book) {
      this.setActivePageOnBook();
      // Update student books in db
      this.userService.updateUser({
        _id : this.roomId,
        studentBooks : this.studentBooks
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        console.log(res)
      });
    }
  }

  getActivePage(page) {
    this.bookActivePage = page;
    if (this.isRecording) {
      // Update page in current book
      this.sessionService.changeActivePageInReadBook(this.book._id, this.bookActivePage);
    }
  }

  getIsRecording(status) {
    this.isRecording = status;
    this.setReadBook();
  }

  /**
   * switchToReadingMode
   */
  switchToReadingMode() {
    if (this.book == undefined) {
      this.openBooksDialog();
    } else {
      // Check for active page to continue reading
      this.bookActivePage = this.getActivePageFromStoredBook(this.book._id);
      this.bookUrl = this.ebookEpubService.getBook(this.book.filename);
      this.setReaderByType();
      this.readingMode = true;
      this.gamingMode = false;
      this.setReadBook();
      // Inform stundent with new settings
      this.sendSocketRoomSettings();
    }
  }

  changeReadingReadyState(val) {
    this.readingModeReadyCount++;
    if (this.readingModeReadyCount == 2) {
      this.readingModeReadyForRecord = val;
      this.readingModeReadyCount = 0;
    }
  }

  setReadBook() {
    if (this.isRecording) {
      this.bookStartPage = this.bookActivePage;
      this.sessionService.setReadBook(this.book._id, this.bookStartPage, this.bookActivePage);
    }
  }

  /**
   * download book for faster reading
   */
  downloadBookAnOpen() {
    if (this.book != undefined) {
      // Inform stundent with new settings
      //this.sendSocketRoomSettings();

      // // Capacitor file storage read file testing
      // // Save file
      // this.capacitorService.fileWrite(this.book._id + '.' + this.book.format, base64Data);
      // // Load file
      // this.capacitorService.fileRead().then(
      //   (file) => {
      //     console.log(file)
      //     this.bookUrl = file.data;
      //     if (this.book.format == 'pdf') {
      //       this.readingModeIsPDF = true;
      //       this.readingModeIsEpub = false; 
      //     } else {
      //       this.readingModeIsPDF = false;
      //       this.readingModeIsEpub = true; 
      //     }
      //     // Inform stundent with new settings
      //     //this.sendSocketRoomSettings();
      //     this.switchToReadingMode();   
      //   },
      //   (err) => {
      //     console.log('File not found', err);
      //   }
      // );

      // // LocalStorage file saving testing
      // this.fileDownloadService
      //     .getStoredFile('book-'+book._id, this.ebookEpubService.getBook(book.filename))
      //     .subscribe((base64Data: string) => {
      //       this.bookUrl = base64Data;
      //       if (this.book.format == 'pdf') {
      //         this.readingModeIsPDF = true;
      //         this.readingModeIsEpub = false; 
      //       } else {
      //         this.readingModeIsPDF = false;
      //         this.readingModeIsEpub = true; 
      //       }
      //       // Inform stundent with new settings
      //       //this.sendSocketRoomSettings();
      //       this.switchToReadingMode();    
      //     });
    }
  }

   /**
   * switchToGamingMode
   */
  switchToGamingMode() {
    this.readingMode = false;
    this.gamingMode = true;
    this.setActivePageOnBook();
    // Inform stundent with new settings
    this.sendSocketRoomSettings();
  }

  /**
   * hangup the call
   */
  hangupCall() {
    //this.webRTC.hangup();
    if (this.router.url.includes('/room')) { 
      this.router.navigate(["/dashboard"]);   
    }
  }

  /**
   * sendSocketRoomSettings/ bookurl, mode, pdf or epub, 
   */
  sendSocketRoomSettings(): void {
    this.webSocketService.emitTo('room-settings', this.webSocketService.getCurrentRoom(), {
      bookUrl : this.bookUrl,
      bookActivePage: this.bookActivePage,
      readingMode : this.readingMode,
      readingModeIsPDF : this.readingModeIsPDF,
      readingModeIsEpub : this.readingModeIsEpub,
      gamingMode: this.gamingMode,
      activeGame: this.activeGame
    }); 
  }

  /**
   * setRoomSettingsFromMentor/ bookurl, mode, pdf or epub, 
   */
  setRoomSettingsFromMentor(data) {
    this.bookUrl = data.bookUrl;
    this.bookActivePage = data.bookActivePage
    this.readingMode = data.readingMode;
    this.readingModeIsPDF = data.readingModeIsPDF;
    this.readingModeIsEpub = data.readingModeIsEpub;
    this.gamingMode = data.gamingMode;
    this.activeGame = data.activeGame;
  }

  /**
   * open overlay for scietific survey
   */  
   startScientificSurvey(url) {
    const dialogRef = this.dialog.open(IframeComponent, {
      width: '100%',
      height: '80%',
      autoFocus: false,
      panelClass: ['iframe-overlay-pane', 'iframe-overlay-pane--video'],
      data: {
        url: url,
        mentorId: this.mentorId,
        studentId: this.roomId,
        params: true
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy() {
    // Save read book only on mentor side
    if (this.isMentor) {
      this.updateReadBooksOnStudent();
    }
    this.webRTC.hangup();
    this.webSocketService.emitTo('call-hangup', this.webSocketService.getCurrentRoom(), '');
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      //console.log('room unsubscribe -------------')
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }, 500);
    // Allow sleep mode again
    //if (this.IS_APP) {
      this.insomnia.allowSleepAgain();
    //}
  }
}