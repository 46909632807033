<div class="header">
    <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<mat-dialog-content *ngIf="data.note">
    <p [innerHTML]="data.note"></p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="apply()" color="primary" *ngIf="data.accept">
        <mat-icon *ngIf="data.acceptIcon">{{ data.acceptIcon }}</mat-icon> <ng-container *ngIf="data.acceptText">{{ data.acceptText }}</ng-container>
    </button>
    <button mat-button (click)="cancel()" color="accent" *ngIf="data.decline">
        <mat-icon *ngIf="data.declineIcon">{{ data.declineIcon }}</mat-icon> <ng-container *ngIf="data.declineText">{{ data.declineText }}</ng-container>
    </button>
</mat-dialog-actions>