import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatDatePipe } from './formatDate.pipe';
import { TimeformatPipe } from './timeformat.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FormatDatePipe,
        TimeformatPipe
    ],
    exports: [
        FormatDatePipe,
        TimeformatPipe
    ]
})
export class PipeModule { }