<div class="row epub">
  <div class="col-md-12">
    <div class="epub-wrap">
      <div class="book-navbar">
        <button id="book-prev" mat-icon-button aria-label="Vorherige Seite" color="primary">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button id="book-next" mat-icon-button aria-label="Nächste Seite" color="primary">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
      <div id="epub-viewer" class="spreads"></div>
    </div>
  </div>
</div>

<ion-row nowrap>
  <ion-button (click)="gototest()" *ngIf="debug"> Go to page </ion-button>
  <ion-button (click)="increaseCurrentCfi()" *ngIf="debug"> Increase Cfi </ion-button>
</ion-row>