export enum FileType {
    JPG = 'jpg',
    JPEG = 'jpg',
    PNG = 'png'
}

export class FileTypeImageChecker {

    private constructor() {
    }

    static isAcceptType(type: string) {
        for (const acceptType of Object.values(FileType)) {
            if (type.toLowerCase() === acceptType.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    static getAllowedFiletypes() {
        let types = '';
        for (const acceptType of Object.values(FileType)) {
            types = types + '.' + acceptType.toLowerCase() + ', ';
        }
        return types;
    }
}
