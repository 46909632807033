import { Component, OnInit,Inject } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface DialogData {
  id: string;
}

export interface Student {}
@Component({
  selector: 'app-student-view',
  templateUrl: './student-view.component.html',
  styleUrls: ['./student-view.component.scss'],
})
export class StudentViewComponent implements OnInit {
  id;
  student: Student[] = [];
  username: string;
  firstname: string;
  lastname;
  email;
  phoneNumber;
  mobileNumber;
  availability;
  birthday;
  schoolName;
  schoolClass;
  readingAbilityRate;
  gender;
  comment;
  isAvailability: boolean= false;
  activeMembershipText: string = "";
  coordinator;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  constructor(
    private userService : UserService,
    private router: Router,
    private route:ActivatedRoute,
    public dialogRef: MatDialogRef<StudentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.id = this.data.id;

    this.userService.getUserbyId(this.id).subscribe(res => {
      console.log(res)
      this.student = res;
      this.username = res["username"];
      this.firstname = res["firstname"];
      this.lastname = res["lastname"];
      this.email = res["email"];
      this.phoneNumber = res["phoneNumber"];
      this.mobileNumber = res["mobileNumber"];
      this.availability = res["availability"];
      this.birthday = res["birthday"];
      this.schoolName = res["school"].name;
      this.schoolClass = res["schoolClass"];
      this.readingAbilityRate = res["readingAbilityRate"];
      this.gender = res["gender"];
      this.comment = res["comment"];


      if (res["activeMembership"] === true) {
        this.activeMembershipText = "Ja"
      } else{
        this.activeMembershipText = "Nein"
      }

      if (this.router.url.includes('/mentor-matching') && this.router.url.includes('/student-matching') ) { 
        this.isAvailability = true;
      } else {
        this.isAvailability = false;
      }

      this.userService.getCoordinatorBySchool(res['school']['_id']).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(data => {
          this.coordinator = data;
      });

    });
  }

}
