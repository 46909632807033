<div class="container-fluid pt-3">
  <div class="row justify-content-center">
      <div class="col-md-6 col-sm-12">
          <h1>Fragen hochladen</h1>
          <form [formGroup]="rForm" #formDirective="ngForm">
            <mat-form-field>
                <ngx-mat-file-input #fileInputModel formControlName="files" (change)="onFileChange($event)"  placeholder="Csv auswählen" ></ngx-mat-file-input>
            </mat-form-field>
          
          
            <button mat-raised-button color="primary" type="button" (click)="submitForm(formDirective)">Speichern</button>
          </form>
      </div>
  </div>
</div>