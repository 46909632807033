import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Plugins, FilesystemDirectory, FilesystemEncoding, StatusBarStyle, AppState, PermissionType } from '@capacitor/core';
//import { AuthGuard } from '@helpers/auth.guard';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import compareVersions from 'compare-versions';

const { Filesystem, Permissions } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class CapacitorService {
  
  private behaviorNetworkStatus = new BehaviorSubject<any>({
    connected: false,
    connectionType: 'none'
  });

  isStatusBarLight = false;
  deviceInfo: any;
  handler = null;
  permissionCamera;
  permissionMicrophone;
  IS_APP = environment.isApp;

  constructor(
    private __zone: NgZone,
    private router: Router,
    private location: Location,
    private authService : AuthService
  ) {}

  async fileWrite(fileName, fileData) {
    try {
      const result = await Filesystem.writeFile({
        path: 'savingtestingtest/' + fileName,
        data: fileData,
        directory: FilesystemDirectory.Cache,
        encoding: FilesystemEncoding.UTF8
      })
      console.log('Wrote file', result);
    } catch(e) {
      console.error('Unable to write file', e);
    }
  }
  
  async fileRead() {
    let contents = await Filesystem.readFile({
      path: 'savingtestingtest/5faa5b46c523333150f13b69.epub',
      directory: FilesystemDirectory.Cache,
      encoding: FilesystemEncoding.UTF8
    });
    console.log(contents);
    return contents;
  }

  /*
    Start Camera
  */
  async checkUserMediaStatus(){
    // check prequisites
    let loc = window.location;
    console.log(loc);

    // Check Permissions
    this.permissionCamera = await Permissions.query({ name: PermissionType.Camera });
    this.permissionMicrophone = await Permissions.query({ name: PermissionType.Microphone });

    if(this.permissionCamera.state != 'granted' && this.permissionMicrophone.state != 'granted'){
      console.error("Web-RTC-Cam: You might need to handle permissions on your device with Capacitor Plugins which prompt and grant these rights.");
    }
  }
  
  async fileAppend() {
    await Filesystem.appendFile({
      path: 'savingtestingtest/hanspeter.txt',
      data: "MORE TESTS",
      directory: FilesystemDirectory.Cache,
      encoding: FilesystemEncoding.UTF8
    });
  }
  
  async fileDelete() {
    await Filesystem.deleteFile({
      path: 'savingtestingtest/hanspeter.txt',
      directory: FilesystemDirectory.Cache
    });
  }
  
  async mkdir() {
    try {
      let ret = await Filesystem.mkdir({
        path: 'savingtestingtest',
        directory: FilesystemDirectory.Cache,
        recursive: false // like mkdir -p
      });
    } catch(e) {
      console.error('Unable to make directory', e);
    }
  }
  
  async rmdir() {
    try {
      let ret = await Filesystem.rmdir({
        path: 'savingtestingtest',
        directory: FilesystemDirectory.Cache,
        recursive: false,
      });
    } catch(e) {
      console.error('Unable to remove directory', e);
    }
  }
  
  async readdir() {
    try {
      let ret = await Filesystem.readdir({
        path: 'savingtestingtest',
        directory: FilesystemDirectory.Cache
      });
      console.log(ret)
    } catch(e) {
      console.error('Unable to read dir', e);
    }
  }

  async getUri() {
    try {
      let ret = await Filesystem.getUri({
        path: 'savingtestingtest/5faa5b46c523333150f13b69.epub',
        directory: FilesystemDirectory.Cache
      });
      return await ret;
    } catch(e) {
      console.error('Unable to get uri', e);
    }
  }
  
  async stat() {
    try {
      let ret = await Filesystem.stat({
        path: 'savingtestingtest/hanspeter.txt',
        directory: FilesystemDirectory.Cache
      });
    } catch(e) {
      console.error('Unable to stat file', e);
    }
  }
  
  // async readFilePath() {
  //   // Here's an example of reading a file with a full file path. Use this to
  //   // read binary data (base64 encoded) from plugins that return File URIs, such as
  //   // the Camera.
  //   try {
  //     let data = await Filesystem.readFile({
  //       path: 'file:///var/mobile/Containers/Data/Application/22A433FD-D82D-4989-8BE6-9FC49DEA20BB/Documents/hanspeter.txt'
  //     })
  //   }
  // }
  
  async rename() {
    try {
      // This example moves the file within the same 'directory'
      let ret = await Filesystem.rename({
        from: 'hanspeter.txt',
        to: 'text2.txt',
        directory: FilesystemDirectory.Cache
      });
    } catch(e) {
      console.error('Unable to rename file', e);
    }
  }
  
  async copy() {
    try {
      // This example copies a file within the documents directory
      let ret = await Filesystem.copy({
        from: 'hanspeter.txt',
        to: 'text2.txt',
        directory: FilesystemDirectory.Cache
      });
    } catch(e) {
      console.error('Unable to copy file', e);
    }
  }

  // Statusbar
  hideStatusBar() {
      Plugins.StatusBar.hide();
  }

  showStatusBar() {
      Plugins.StatusBar.show();
  }

  setStatusBarBackgroundColor(code) {
    Plugins.StatusBar.setBackgroundColor({ color: code })
  }

  changeStatusBar() {
    this.isStatusBarLight = !this.isStatusBarLight;
    Plugins.StatusBar.setStyle({
      style: this.isStatusBarLight ? StatusBarStyle.Dark : StatusBarStyle.Light
    });
  }

  // Devie info
  async readDeviceInfo() {
      return await Plugins.Device.getInfo().then(
        (data) => {
          // Add browser info for web
          if (data['platform'] == 'web') {
            data['browser'] = this.getBrowserName();
          }
          this.setDeviceInfo(data);
          return data;
        });
  }

  setDeviceInfo(data) {
    this.deviceInfo = data;
    // Remove unused data
    if (this.deviceInfo.appId != undefined) { delete this.deviceInfo.appId };
    if (this.deviceInfo.appName != undefined) { delete this.deviceInfo.appName };
    if (this.deviceInfo.diskFree != undefined) { delete this.deviceInfo.diskFree };
    if (this.deviceInfo.diskTotal != undefined) { delete this.deviceInfo.diskTotal };
    if (this.deviceInfo.isVirtual != undefined) { delete this.deviceInfo.isVirtual };
    if (this.deviceInfo.memUsed != undefined) { delete this.deviceInfo.memUsed };
    if (this.deviceInfo.uuid != undefined) { delete this.deviceInfo.uuid };
    console.log(this.deviceInfo)
  }

  getDeviceInfo() {
    return this.deviceInfo;
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getDevicePlatform() {
      return this.deviceInfo.platform.toLowerCase();
  }

  getDeviceOSVersion() {
    return this.deviceInfo.osVersion;
  }

  compareOSVersion(oldVer, newVer, operator) {
    return compareVersions.compare(oldVer, newVer, operator);
  }

  // Network
  startListenToNetwork() {
    this.handler = Plugins.Network.addListener('networkStatusChange', (status) => {
      //console.log("Network status changed", status);
      this.__zone.run(() => {
        if(status) {
            this.behaviorNetworkStatus.next(status);
        }
      });
    });
  }

  endListenToNetwork() {
    this.handler && this.handler.remove();
  }

  getNetworkStatus() {
    Plugins.Network.getStatus().then((status) => {
      console.log('Status', status);
      this.__zone.run(() => {
        if(status) {
            this.behaviorNetworkStatus.next(status);
        }
      });
    });
  }

  watchNetWorkStatus() {
    return this.behaviorNetworkStatus;
  }


  /* App state listener */
  appStateChange() {
    Plugins.App.addListener('appStateChange', (state: AppState) => {
      // state.isActive contains the active state
      console.log('App state changed. Is active?', state.isActive);
      // Check if token is still valid
      this.authService.validateToken();
      if (this.IS_APP && !state.isActive) {
        // Go to dashboard on App minimization -> Hangup call / single reading -> upload data
        let currentLocation = this.location.path();
        if (currentLocation.includes('/single-reading') || currentLocation.includes('/room')) {
          this.router.navigate(["/dashboard"]);  
        }
      }
    });
  }

  /* Android hardware back button */
  androidBackButtonLogic() {
    Plugins.App.addListener('backButton', () => {
      // Ask to exit app if in dashboard or login screen
      if (this.location.path() == '/dashboard' || this.location.path() == '/login') {
          this.androidConfirmCloseApp();
      } else {
        // Nav tree logic
        if (this.location.path() == '/games') {
            this.router.navigate(["/dashboard"]);  
        } else {
            this.location.back();
        }
      }
    });
  }

  async androidConfirmCloseApp() {
      let confirmRet = await Plugins.Modals.confirm({
        title: 'Bestätigen',
        message: 'Möchtest du die App wirklich schließen?',
        okButtonTitle: 'Ja',
        cancelButtonTitle: 'Nein'
      });
      if(confirmRet.value) {
        Plugins.App.exitApp();
      }
  }
}
