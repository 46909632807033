<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-10 col-sm-12">
        <h1 class="pt-3">Koordinator bearbeiten</h1>
        <div class="register_wrap">
          <form [formGroup]="rForm" #formDirective="ngForm">
            <div class="row">
              <div class="col-md-4">
                  <mat-form-field>
                    <input matInput disabled [value]="username"  type="text">
                  </mat-form-field>

                  <mat-slide-toggle formControlName="activeMembership" color="primary">Aktive Mitgliedschaft</mat-slide-toggle>
                  <br>
                  <br>
                  <mat-slide-toggle formControlName="scientificSurvey" color="primary">Wissenschaftliche Befragung</mat-slide-toggle>
                  <br>
                  <br>

                  <mat-form-field>
                    <mat-label>Betreute Schulen</mat-label>
                    <mat-select [compareWith]="compareById" formControlName="supervisingSchools" placeholder="Schulen" multiple required>
                      <mat-option>
                        <ngx-mat-select-search [formControl]="schoolFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let school of filteredSchools | async" [value]="school">
                        {{school.name}} | {{school.plz}} {{school.city}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <br>
                  <br>
                  <mat-form-field>
                    <mat-label>Präferenz Schulforn für Leseaktivität</mat-label>
                    <mat-select formControlName="preferredTypeOfSchool" type="role">
                      <mat-option value="Grundschule">Grundschule</mat-option>
                      <mat-option value="Weiterführend">Weiterführend</mat-option>
                      <mat-option value="Andere">Andere</mat-option>
                      <mat-option value="Egal">Egal</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Kapazität an Lesekindern</mat-label>
                    <mat-select formControlName="studentsCapacity">
                      <mat-option value="1">1</mat-option>
                      <mat-option value="2">2</mat-option>
                      <mat-option value="3">3</mat-option>
                      <mat-option value="4">4</mat-option>
                      <mat-option value="5">5</mat-option>
                      <mat-option value="6">6</mat-option>
                      <mat-option value="7">7</mat-option>
                      <mat-option value="8">8</mat-option>
                      <mat-option value="9">9</mat-option>
                      <mat-option value="10">10</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <textarea matInput formControlName="comment" placeholder="Kommentar"></textarea>
                  </mat-form-field>
              </div>
              <div class="col-md-4">
                <p><strong>Persönliche Daten</strong></p>
                  <mat-form-field>
                    <input matInput formControlName="firstname"  type="text" placeholder="Vorname" required>
                  </mat-form-field>
      
                  <mat-form-field>
                    <input matInput formControlName="lastname"  type="text" placeholder="Nachname" required>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Geschlecht</mat-label>
                    <mat-select formControlName="gender" required>
                      <mat-option value="männlich">männlich</mat-option>
                      <mat-option value="weiblich">weiblich</mat-option>
                      <mat-option value="divers">divers</mat-option>
                    </mat-select>
                  </mat-form-field>
      
                  <mat-form-field>
                    <input matInput formControlName="email"  type="text" placeholder="E-Mail"> 
                  </mat-form-field>
      
                  <mat-form-field>
                    <input matInput formControlName="phoneNumber"  type="tel" placeholder="Telefon Festnetz">
                  </mat-form-field>
      
                  <mat-form-field>
                    <input matInput formControlName="mobileNumber"  type="tel" placeholder="Telefon Mobil">
                  </mat-form-field>

                  <mat-form-field (click)="birthdaypicker.open()">
                    <mat-label>Geburtstag</mat-label>
                    <input matInput formControlName="birthday" type="text" [matDatepicker]="birthdaypicker">
                    <mat-datepicker-toggle matSuffix [for]="birthdaypicker"></mat-datepicker-toggle>
                    <mat-datepicker #birthdaypicker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                    <p><strong>Zeitliche Verfügbarkeit für Lesesitzungen</strong></p>
                    <mat-form-field>
                      <input matInput formControlName="availabilityMo"  type="text" placeholder="Montag">
                    </mat-form-field>
    
                    <mat-form-field>
                      <input matInput formControlName="availabilityTu"  type="text" placeholder="Dienstag">
                    </mat-form-field>
    
                    <mat-form-field>
                      <input matInput formControlName="availabilityWe"  type="text" placeholder="Mittwoch">
                    </mat-form-field>
    
                    <mat-form-field>
                      <input matInput formControlName="availabilityTh"  type="text" placeholder="Donnerstag">
                    </mat-form-field>
    
                    <mat-form-field>
                      <input matInput formControlName="availabilityFr"  type="text" placeholder="Freitag">
                    </mat-form-field>
    
                    <mat-form-field>
                      <input matInput formControlName="availabilitySa"  type="text" placeholder="Samstag">
                    </mat-form-field>
    
                    <mat-form-field>
                      <input matInput formControlName="availabilitySu"  type="text" placeholder="Sonntag">
                    </mat-form-field>
                </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="button-group">
                      <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big" aria-label="Edit">
                          Speichern
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="button-group">
                      <button mat-button color="accent" type="button" class="size-big" [routerLink]="['/coordinator-list']" aria-label="Cancel">
                        Abbrechen
                      </button>
                    </div>
                  </div>
                </div>
          </form>
        </div>
    </div>
  </div>
</div>