import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Subscription, Subject } from 'rxjs';
import { UserService } from "../providers/user.service";
import { takeUntil } from 'rxjs/operators';

export interface User {
  username?: string;
}
export interface Session {}
@Component({
  selector: 'app-contact-person',
  templateUrl: './contact-person.component.html',
  styleUrls: ['./contact-person.component.scss'],
})
export class ContactPersonComponent implements OnInit {
  userId: string;
  coordinator;
  mentor;
  user: User[] = [];
  subscription: Subscription;
  appSettings;
  private appSettingsSubs: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService : AuthService,
    public userService: UserService,
    // public sessionService: SessionService,
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.params['id'];
    this.userService.getUserbyId(this.userId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(data => {
        this.user = data;
        this.mentor = this.user['mentor'];
        this.userService.getCoordinatorBySchool(this.user['school']['_id']).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(data => {
            this.coordinator = data;
        });

      });
    // Get app settings
    this.appSettings = this.authService.getAppSettings();
    this.appSettingsSubs = this.authService.getAppSettingsListener()
      .subscribe(appSettings => {
        this.appSettings = appSettings;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
