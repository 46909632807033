<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-8 col-sm-12">
        <form [formGroup]="rForm" #formDirective="ngForm">
            <div class="row">
              <div class="col-md-12">
                <h2>Kind: {{ student.firstname }} {{ student.lastname }}<br>
                  Mentor: {{ mentor.firstname }} {{ mentor.lastname }}</h2>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-12">
                <br>
                <p>Falls bereits ein Lesetermin bekannt ist, können Sie ihn hier eingeben:</p>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field (click)="picker.open()">
                  <mat-label>Nächster Lesetermin</mat-label>
                  <input matInput formControlName="nextMeeting" type="text" [ngxMatDatetimePicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #picker [defaultTime]="defaultTime"></ngx-mat-datetime-picker>
                </mat-form-field>
                <br>
                <br>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-6">
                <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big">Speichern</button>
              </div>
              <div class="col-md-6">
                <button mat-button color="accent" type="button" (click)="cancel()" class="size-big">Abbrechen</button>
              </div>
            </div>
          </form>
      </div>
  </div>
</div>