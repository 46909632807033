<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-8 col-sm-12">
        <form [formGroup]="rForm" #formDirective="ngForm">
            <div class="row">
              <div class="col-md-12">
                <br>
                <h1>{{ user?.firstname }} {{ user?.lastname }} heraufstufen?</h1>

                <mat-form-field>
                  <mat-label>Betreute Schulen</mat-label>
                  <mat-select formControlName="supervisingSchools" placeholder="Schulen" multiple>
                    <mat-option>
                      <ngx-mat-select-search [formControl]="schoolFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let school of filteredSchools | async" [value]="school">
                      {{school.name}} | {{school.plz}} {{school.city}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big">Speichern</button>
              </div>
              <div class="col-md-6">
                <button mat-button color="accent" type="button" (click)="cancel()" class="size-big">Abbrechen</button>
              </div>
            </div>
          </form>
      </div>
  </div>
</div>