import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

export interface Admins {
  username: string;
  _id: string;
  role?: string;
}

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss'],
})
export class AdminListComponent implements OnInit {
  displayedColumns: string[] = ['username', 'firstname', 'lastname', 'buttons'];
  dataSource:any;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isAdmin = false;
  filterValues = {
    search: ''
  };

  admins: Admins[] = [];
  private adminsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(   
    private userService : UserService
  ) {}

  ngOnInit() {
    this.userService.getAdminList();
    this.adminsSub = this.userService.getAdminsUpdateListener().pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe((admins: Admins[]) => {
        this.admins = admins;
        this.dataSource = new MatTableDataSource<Admins>(this.admins);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
        // Filter in nested array as well
        this.dataSource.filterPredicate = (data: any, filter) => {
          // Find position for search word
          let positionFound = JSON.stringify(data).toLowerCase().indexOf(filter.search) != -1;
          return positionFound;
        };
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValues['search'] = filterValue.trim().toLowerCase()
    this.dataSource.filter = this.filterValues;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}