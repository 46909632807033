import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';
import { AnalyticsComponent } from './analytics.component';
import { PipeModule } from '../pipes/pipe.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from "@angular/forms";

@NgModule({
  declarations: [
    AnalyticsComponent,
  ],
  entryComponents: [
    AnalyticsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule
  ],
  bootstrap: [
  ],
  exports: [
    AnalyticsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AnalyticsModule {}
