import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EbooksEpubService } from "../../providers/epub.service";
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { FileTypeChecker } from './fileTypeChecker';
import { FileTypeImageChecker } from './fileTypeImageChecker';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs'

export class Book {
  _id: string;
  file: File;
  name: string;
  type?: string;
  size: string;
  originalname?: string;
  path?: string;
  recommendation: string;
}


@Component({
  selector: 'app-book-create',
  templateUrl: './book-create.component.html',
  styleUrls: ['./book-create.component.scss'],
})
export class BookCreateComponent implements OnInit {
  rForm: FormGroup;
  book: any;
  bookPicture: any;
  allowedFileTypes = '';
  uploadprogress: number;
  showprogress: boolean = false;
  color = 'primary';
  mode = 'determinate';
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @ViewChild('fileInputModel') fileInputModel;

  constructor(
    public ebookEpubService: EbooksEpubService,
    private router: Router,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
      this.allowedFileTypes = FileTypeChecker.getAllowedFiletypes();
      //init form / input data and validator
      this.rForm = this._formBuilder.group({
        files  : ['', Validators.required],
        name   : ['', Validators.required],
        filePicture : ['', Validators.required],
        recommendation : ['']
      }); 
  }

  /* PDF or EPUB */
  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      for (const file of event.target.files) {
        const fileEnding = file.name.split('.')[1];
        if (FileTypeChecker.isAcceptType(fileEnding)) {
          const book: Book = new Book();
          book._id = 'pre_' + new Date().getTime();
          book.file = file;
          book.name = file.name;
          book.originalname = file.name;
          book.size = file.size;
          // book.recommendation = file.recommendation;
          // this.rForm.get('files').setValue(file);
          console.log(file)
          console.log(book);
          // Set book file for later usage
          this.book = book;
        } else {
          console.log(file.name + ': ' + 'Wrong File Type! ');
        }
      }
    }
  }

  /* JPG or PNG or JPEG */
  onFilePictureChange(event) {
    if (event.target.files && event.target.files.length) {
      for (const file of event.target.files) {
        const fileEnding = file.name.split('.')[1];
        if (FileTypeImageChecker.isAcceptType(fileEnding)) {
          console.log(file)
          // Set book file for later usage
          this.bookPicture = file;
          console.log(this.bookPicture)
        } else {
          console.log(file.name + ': ' + 'Wrong File Type! ');
        }
      }
    }
  }

  uploadFilesToServer(file, bookPicture) {
     if (file._id.startsWith('pre_')) {
        console.log(file)
        this.showprogress = true;
        this.ebookEpubService.createBook(file, bookPicture).pipe(
            takeUntil(this._unsubscribeAll)
          ).subscribe((res:any) => {
          // Show upload progress
          if (res.status == 'progress') {
            this.uploadprogress = res.message;
          }
          // Upload finished
          if (res.status == 'finished') {
            this.showprogress = false;
            this.router.navigate(["/books"])
          }
        });
     }
  }


   /**
   * submit data
   */
  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      this.rForm.get("name").markAsTouched();
      this.rForm.get("files").markAsTouched();
      this.rForm.get("filePicture").markAsTouched();
      return;
    }
    console.log(this.rForm.value)
    console.log(this.book)
    this.book.name = this.rForm.value.name;
    this.book.recommendation = this.rForm.value.recommendation;
    this.uploadFilesToServer(this.book, this.bookPicture);

    formDirective.resetForm();
    /**
     * Workaround, da clear-Methode des ngx-mat-file-input nicht geht
     */
    this.fileInputModel._elementRef.nativeElement.firstChild.value = '';
    this.fileInputModel.clear(event);
    this.rForm.reset();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
