<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="button-group">
        <button mat-button color="accent" (click)="openDialog()" class="size-big">Buch auswählen</button>
      </div>
    </div>
    <div class="col-md-8 col-sm-12">
      <app-pdfreader [readingMode]="'Solo'" [bookUrl]="bookUrl" [toolButtons]="false" [previewMode]="true"
        [bookActivePage]="bookActivePage" (activePage)="getActivePage($event)" *ngIf="readingModeIsPDF"></app-pdfreader>
    </div>
  </div>
</div>