<div class="dashboard container-fluid">
  <div class="dashboard_wrap row">
    <div [ngClass]="{'col-md-4 px-0': isMentor, 'd-none': !isMentor }">
      <div class="dashboard_navigation" *ngIf="isMentor">
        <mat-selection-list [multiple]="false">
          <mat-list-option *ngFor="let student of students" [value]="student._id" (click)="selectStudent(student)" [selected]="room == student._id">
            <span class="online-status" [ngClass]="{'online-status--is-online': checkIfOnline(student._id) }"></span>
            <span>
              <span class="dashboard_name_wrap">
                <span *ngIf="student.firstname">{{ student.firstname }}</span> <span *ngIf="student.lastname">{{ student.lastname }}</span>
                <span *ngIf="!student.firstname && !student.lastname">{{ student.username }}</span>
              </span>
              <span *ngIf="student?.passwordResetRequest" matBadge="1" matBadgeOverlap="false" matBadgeColor="accent"></span>
            </span>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <div [ngClass]="{'col-md-8': isMentor, 'col-md-12': !isMentor }">
      <div class="corner-info">
        <div *ngIf="appSettings?.technicalSupport" class="technical-support">
          <mat-icon class="material-icons">device_unknown</mat-icon> {{ appSettings?.technicalSupport }}
        </div>
        <p *ngIf="currentMentor && !isMentor" class="mentor-info">Mentor: <strong>{{ currentMentor }}</strong></p>
      </div>
      <div class="button-group">
        <img src="assets/logos/{{clientLogo}}" class="dashboard-logo" />
        <ng-container *ngIf="isMentor">
          <div *ngIf="activeStudent?.passwordResetRequest"> 
            <p class="notification">Das Lesekind hat leider sein Kennwort vergessen. Bitte ändern Sie dieses in seinem Profil und teilen es seinen Eltern mit.</p>
          </div>
          <button mat-button color="primary" (click)="videoCall()" class="size-big" [disabled]="!checkIfOnline(room)">Anrufen</button>
          <button mat-button color="accent" (click)="goToSession()" class="size-big" [disabled]="students.length < 1">Lesesitzungen</button>
          <button mat-button color="accent" (click)="goToProfile()" class="size-big" [disabled]="students.length < 1">Profil</button>
          <button mat-button color="accent" [routerLink]="['/book-preview']" class="size-big">Mentorbibliothek</button>
        </ng-container>
        <ng-container *ngIf="!isMentor">
          <div *ngIf="isInThePast" class="next-meeting-info">
            <h2>Nicht vergessen: Dein nächster Lesetermin ist am {{ nextMeeting | date:'fullDate' }}, um {{ nextMeeting | date:'shortTime' }} Uhr</h2>
          </div>
          <h1>Hallo {{ firstname }}, was möchtest du machen?</h1>
          <div class="instructionVideo_wrap_reading" [ngClass]="{'instructionVideo_wrap_reading--two-videos': showInstructionVideos }">
            <button mat-button color="accent" [routerLink]="['/single-reading']" class="size-big">Lesen</button>
            <button type="button" mat-button aria-label="Hilfevideo" (click)="openIframeAsOverlay(fileUrl + 'alleine-lesen.mp4')" *ngIf="showInstructionVideos" class="icon-with-text instructionVideo">
              <p>Alleine lesen</p>
              <mat-icon>videocam</mat-icon>
            </button>
            <button type="button" mat-button aria-label="Hilfevideo" (click)="openIframeAsOverlay(fileUrl + 'gemeinsam-lesen.mp4')" *ngIf="showInstructionVideos" class="icon-with-text instructionVideo">
              <p>Mit Mentor lesen</p>
              <mat-icon>videocam</mat-icon>
            </button>
          </div>
          <div class="instructionVideo_wrap">
            <button mat-button color="accent" [routerLink]="['/games']" class="size-big">Spielen</button>
            <button type="button" mat-button aria-label="Hilfevideo" (click)="openIframeAsOverlay(fileUrl + 'spielen.mp4')" *ngIf="showInstructionVideos" class="icon-with-text instructionVideo">
              <!-- <p>Hilfevideo</p> -->
              <mat-icon>videocam</mat-icon>
            </button>
          </div>
          <div class="instructionVideo_wrap">
            <button mat-button color="accent" (click)="goToProfile()" class="size-big">Profil</button>
            <button type="button" mat-button aria-label="Hilfevideo" (click)="openIframeAsOverlay(fileUrl + 'profil.mp4')" *ngIf="showInstructionVideos" class="icon-with-text instructionVideo">
              <!-- <p>Hilfevideo</p> -->
              <mat-icon>videocam</mat-icon>
            </button>
          </div>
          <div class="instructionVideo_wrap">
            <button mat-button color="accent" [routerLink]="['/contact/' + userId]" class="size-big" *ngIf="appSettings?.showContactPerson">Ansprechpartner</button>
            <button type="button"  mat-button aria-label="Hilfevideo" (click)="openIframeAsOverlay(fileUrl + 'ansprechpartner.mp4')" *ngIf="appSettings?.showContactPerson && showInstructionVideos" class="icon-with-text instructionVideo">
              <!-- <p>Hilfevideo</p> -->
              <mat-icon>videocam</mat-icon>
            </button>
          </div>
          <!-- <button mat-button color="accent" (click)="testMessageToMentor()" class="size-big">Emit</button> -->
        </ng-container>
        <!-- <button mat-button color="accent" [routerLink]="['/debugvideo']" class="size-big">Debug Video</button> -->
      </div>
    </div>
  </div>
</div>