
<div class="container-fluid pt-3">
    <div class="row justify-content-center">
        <div class="col-md-6 col-sm-12">
            <h1>Neues Buch erstellen</h1>
            <div *ngIf="showprogress"> 
                <mat-progress-bar
                class="example-margin"
                [color]="color"
                [mode]="mode"
                [(value)]="uploadprogress">
                </mat-progress-bar>
            </div>
            <form [formGroup]="rForm" #formDirective="ngForm">
                <mat-form-field>
                    <input matInput formControlName="name"  type="name" placeholder="Buch Name" required>
                </mat-form-field>
                <mat-form-field>
                    <ngx-mat-file-input #fileInputModel formControlName="files" (change)="onFileChange($event)" placeholder="Buch auswählen" ></ngx-mat-file-input>
                    <!-- <mat-icon matSuffix>folder</mat-icon> -->
                </mat-form-field>
                <mat-form-field>
                    <ngx-mat-file-input #filePictureInputModel formControlName="filePicture" (change)="onFilePictureChange($event)" placeholder="Cover auswählen" ></ngx-mat-file-input>
                    <!-- <mat-icon matSuffix>folder</mat-icon> -->
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Altersempfehlung</mat-label>
                    <mat-select formControlName="recommendation" placeholder="Altersempfehlung">
                      <mat-option value=""></mat-option>
                      <mat-option value="6 bis 8">6 bis 8</mat-option>
                      <mat-option value="8 bis 10">8 bis 10</mat-option>
                      <mat-option value="Ab 10">Ab 10</mat-option>
                    </mat-select>
                  </mat-form-field>
                

                <button mat-raised-button color="primary" type="button" (click)="submitForm(formDirective)">Speichern</button>
            </form>
        </div>
    </div>
</div>