<div class="dashboard container-fluid">
  <div class="dashboard_wrap row">
    <div class="col-md-12">
      <div class="button-group">
        <ng-container *ngIf="isAdmin || isCoordinator">
          <h1 class="pt-3">Untermenüs</h1>
          <button mat-button color="accent" [routerLink]="['/mentor-create']" class="size-big">Anlegen</button>
          <button mat-button color="accent" [routerLink]="['/mentor-list']" class="size-big">Matching</button>
          <button mat-button color="accent" [routerLink]="['/mentor-list']" class="size-big">Ändern</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>