import { NgModule, CUSTOM_ELEMENTS_SCHEMA, SystemJsNgModuleLoader, NgModuleFactoryLoader, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from "./auth/auth-interceptor";
import { NoCacheHeadersInterceptor } from "./auth/no-cache-headers-interceptor";
// Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// Entry point
import { AppComponent } from './app.component';
// Routing Module
import { AppRoutingModule } from './app-routing.module';
// Material Design Modules
import { MaterialModule } from './material.module';
// Custom Modules
//import { HomePageModule } from './home/home.module';
import { RecordRTCModule } from './record-rtc/record-rtc.module';
import { WebSocketService } from './providers/websocket.service';
import { WebrtcService } from './providers/webrtc.service';
import { LoginModule } from './user/login/login.module';
import { ChangePasswordModule } from './user/change-password/change-password.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AdminDashboardModule } from './admin-dashboard/admin-dashboard.module';
import { AdminSettingsModule } from './admin-settings/admin-settings.module';
import { SettingsModule } from './settings/settings.module';
import { ContactPersonModule } from './contact-person/contact-person.module';
import { RoomModule } from './room/room.module';
import { RegisterModule } from './user/register/register.module';
import { BookListModule } from './books/book-list/book-list.module';
import { BookEditModule } from './books/book-edit/book-edit.module';
import { AdminListModule } from './user/admin/admin-list/admin-list.module';
import { MentorListModule } from './user/mentor/mentor-list/mentor-list.module';
import { MentorMappingModule } from './user/mentor/mentor-mapping/mentor-mapping.module';
import { MentorEditModule } from './user/mentor/mentor-edit/mentor-edit.module';
import { StudentListModule } from './user/student/student-list/student-list.module';
import { StudentEditModule } from './user/student/student-edit/student-edit.module';
import { BookCreateModule } from './books/book-create/book-create.module';
import { GameAviatorModule } from './games/singleplayer/game-aviator/game-aviator.module';
import { GameListModule } from './games/singleplayer/game-list/game-list.module';
import { GameListMultiplayerModule } from './games/multiplayer/game-list/game-list-multiplayer.module';
import { GameRabbitRunModule } from './games/singleplayer/game-rabbit-run/game-rabbit-run.module';
import { GameTowerBlocksModule } from './games/singleplayer/game-tower-blocks/game-tower-blocks.module';
import { GameHextrisModule } from './games/singleplayer/game-hextris/game-hextris.module';
import { SessionModule } from './session/session.module';
import { SessionNoteModule } from './session-note/session-note.module';
import { AnalyticsModule } from './analytics/analytics.module';
import { SingleReadingModule } from './single-reading/single-reading.module';
import { SingleReadingSilentModule } from './single-reading-silent/single-reading-silent.module';
import { BackButtonModule } from './helpers/back-button/back-button.module';
import { ResetPasswordModule } from './user/reset-password/reset-password.module';
import { SplashScreenModule } from './splash-screen/splash-screen.module';
import { LoadingSpinnerModule } from './helpers/loading-spinner/loading-spinner.module';
import { CreateQuestionModule } from './questions/create-question/create-question.module';
import { EditQuestionModule } from './questions/edit-question/edit-question.module';
import { QuizModule } from './quiz/quiz.module';
import { CreateSchoolModule } from './school/create-school/create-school.module';
import { EditSchoolModule } from './school/edit-school/edit-school.module';
import { SchoolListModule } from './school/school-list/school-list.module';
import { BookPreviewModule } from './books/book-preview/book-preview.module';
import { CoordinatorListModule } from './user/coordinator/coordinator-list/coordinator-list.module';

import { CoordinatorEditModule } from './user/coordinator/coordinator-edit/coordinator-edit.module';
import { CoordinatorMenuModule } from './user/coordinator/coordinator-menu/coordinator-menu.module';
import { CoordinatorDowngradeModule } from './user/coordinator/coordinator-downgrade/coordinator-downgrade.module';
import { MentorMenuModule } from './user/mentor/mentor-menu/mentor-menu.module';
import { StudentMenuModule } from './user/student/student-menu/student-menu.module';
import { MentorViewModule } from './user/mentor/mentor-view/mentor-view.module';
import { StudentViewModule } from './user/student/student-view/student-view.module';
import { StudentMatchingModule } from './user/student/student-matching/student-matching.module';
import { MentorAddStudentModule } from './user/mentor/mentor-add-student/mentor-add-student.module';
import { MentorUpgradeModule } from './user/mentor/mentor-upgrade/mentor-upgrade.module';
import { SchoolMenuModule } from './school/school-menu/school-menu.module';
import { SessionMenuModule } from './session-menu/session-menu.module';
import { MentorMatchingModule } from './user/mentor/mentor-matching/mentor-matching.module';
import { ListQuestionModule } from './questions/list-question/list-question.module';
import { CsvUploadModule} from './csv-upload/csv-upload.module'

import { IframeModule } from './helpers/iframe/iframe.module';
import { ConfirmDialogComponent } from './helpers/confirm-dialog/confirm-dialog.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorInterceptor } from "./error/error-interceptor";

import { AuthGuard } from './auth/auth.guard';

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    // ModuleLoadDirective
  ],
  entryComponents: [
    ConfirmDialogComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(
      //{ _forceStatusbarPadding: true }
    ),
    AppRoutingModule,
    //HomePageModule,
    LoginModule,
    ChangePasswordModule,
    DashboardModule,
    AdminDashboardModule,
    AdminSettingsModule,
    SettingsModule,
    ContactPersonModule,
    RoomModule,
    RegisterModule,
    BookListModule,
    RecordRTCModule,
    BookCreateModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    GameListModule,
    GameListMultiplayerModule,
    SessionModule,
    SessionNoteModule,
    AnalyticsModule,
    SingleReadingModule,
    BackButtonModule,
    ResetPasswordModule,
    AdminListModule,
    MentorListModule,
    MentorMappingModule,
    MentorEditModule,
    StudentListModule,
    StudentEditModule,
    SplashScreenModule,
    LoadingSpinnerModule,
    CreateQuestionModule,
    SingleReadingSilentModule,
    QuizModule,
    EditQuestionModule,
    CreateSchoolModule,
    EditSchoolModule,
    SchoolListModule,
    BookPreviewModule,
    CoordinatorListModule,
    CoordinatorEditModule,
    BookEditModule,
    CoordinatorMenuModule,
    CoordinatorDowngradeModule,
    MentorMenuModule,
    StudentMenuModule,
    SchoolMenuModule,
    SessionMenuModule,
    MentorViewModule,
    StudentViewModule,
    StudentMatchingModule,
    MentorAddStudentModule,
    MentorUpgradeModule,
    MentorMatchingModule,
    ListQuestionModule,
    IframeModule,
    CsvUploadModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
    { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader  },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    WebSocketService,
    WebrtcService,
    AuthGuard,
    { provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
