import { Injectable } from '@angular/core';
import Peer from 'peerjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Subject } from "rxjs";
import { CapacitorService } from "../providers/capacitor.service";
import { MatSnackBar } from '@angular/material/snack-bar';

declare let cordova: any;

const constraints: MediaStreamConstraints = {
  audio: {
      // autoGainControl: false,
      // echoCancellation: true,
      // noiseSuppression: false
      //latency: 0,
  },
  //video: true
  video: {
    width: { 
      //min: 320,
      max: 854
    },
    height: {
      //min: 180,
      max: 480
    },
    // aspectRatio: {
    //   ideal: 1.2
    // },
    // facingMode: {
    //     ideal: "user"
    // }
  }
};
var n = <any>navigator;
//    n.mediaDevices.getUserMedia = navigator.mediaDevices.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia;

@Injectable({
  providedIn: 'root'
})
export class WebrtcService {
  peer: Peer;
  myStream: MediaStream;
  myEl: HTMLMediaElement;
  partnerStream: MediaStream;
  partnerEl: HTMLMediaElement;
  userId: string;
  userType: string;
  partnerId: string;
  private connectionStatusListener = new Subject<boolean>();
  disableCall: boolean = false;
  callList = [];
  myDeviceInfo: any;
  partnerDeviceInfo: any;

  mediaConnection: Peer.MediaConnection;
  options: Peer.PeerJSOption;
  ICE_SERVERS: RTCIceServer[] = environment.iceServers;

  constructor(
    private router: Router,
    public capacitorService: CapacitorService,
    public snackBar: MatSnackBar,
  ) {
    // this.options = {}; // Empty uses Google server -> doesnt work sometimes
    // this.options = {
    //   host: '9000-d949c52b-bc65-43fd-8cd4-18e678161001.ws-eu01.gitpod.io', // own server -> https://github.com/peers/peerjs-server
    //   port: 443,
    //   path: '/',
    //   secure: true
    // };
    this.options = {
      host: environment.peerServerUrl,
      port: environment.peerServerPort,
      path: '/ubf2pay286lohom2zun1',
      key: 'eyJhbGciOiJIUzI1NiIsInxR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IxkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMexJf36POk6yJV_adQssw5c',
      secure: environment.ssl,
      config: {
        'iceServers': this.ICE_SERVERS
      } /* Sample servers, please use appropriate ones */
      //debug: 3
    };
  }

  /**
   * getPartnerStream
   */
  getPartnerStream() {
    return this.partnerStream;
  }

  getPartnerDeviceInfo() {
    return this.partnerDeviceInfo;
  }

  /**
   * getMedia
   */
  getMedia() {
    n.mediaDevices
        .getUserMedia(constraints)
        .then(this.handleSuccess.bind(this))
        .catch(this.handleError.bind(this));
  }

  /**
   * initPeer and set the peerID
   */
  async initPeer(userId: string, userType: string, deviceInfo: any) {
    this.disableCall = false;
    this.userId = 'peer-' + userId;
    console.log(this.userId)
    this.userType = userType;
    this.myDeviceInfo = deviceInfo;
    // Students needs to wait for camera access to create peer
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
      await this.createPeer(this.userId);
    }
  }

  /**
   * initVideo
   */
  async initVideo(myEl: HTMLMediaElement, partnerEl: HTMLMediaElement, partnerId: string) {
    this.myEl = myEl;
    this.partnerEl = partnerEl;
    this.partnerId = 'peer-' + partnerId;
    await this.getMedia();
  }

  /**
   * createPeer / open, error, close, disconnected
   */
  async createPeer(userId: string) {
    this.peer = new Peer(userId, this.options);
    this.peer.on('open', (id) => {
        console.log('My peer ID is: ' + id);
        if (!(this.userType == 'mentor' || this.userType == 'coordinator')) {
          this.wait();
        }
    });
    this.peer.on('error', (error) => {
      console.log(error.type);
      let showError = false;
      let errorMessage = 'Ihre Gegenstelle wurde vom Internet getrennt.';
      if (error.type == 'peer-unavailable') {
        this.peerUnavailable();
      }
      if (error.type == 'network') {
          showError = true;
      } else if (error.type == 'server-error') {
          showError = true;
          errorMessage = 'Keine Verbindung zum Server.';
      } else if (error.type == 'socket-error') {
          showError = true;
      } else if (error.type == 'socket-closed') {
          showError = true;
          errorMessage = 'Die Verbindung wurde unerwartet getrennt.';
      }
      if (showError) {
        this.snackBar.open(errorMessage, "",{
          panelClass: 'snack-error',
          duration: 10000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    });
    this.peer.on('close', function() {
        console.log('on close');
        // this.connectionStatusListener.next(false);
    });
    this.peer.on('disconnected', function() {
        console.log("peer disconnect from me!");
        // if (this.userType == 'mentor' || this.userType == 'coordinator') {
        //   this.peer.reconnect();
        // }
    });
  }

  /**
   * console log if peer ist unavailable
   */
  peerUnavailable() {
    console.log('CAN NOT CALL, Child not available!!');
    setTimeout(() => {
        this.tryCallingChild(this.partnerId, 1);
    }, 1000);
    // this.hangup()
    // this.router.navigate(["/dashboard"]); 
  }

  /**
   * try calling child 5 times waiting 1sec for each try
   */
  tryCallingChild(partnerId, number) {
    if (number > 0) {
      setTimeout(() => {
        try {
          this.call(partnerId);
        } catch(e) {
          this.tryCallingChild(partnerId, number-1);
        }
      }, 1000);
    }
  }

  /**
   *  set partner volume depending on device info
   */
  setPartnerVolume() {
    let volume = 0.9;
    // Check for Lenovo TB-X505F' android devie
    if ((this.myDeviceInfo.model == 'Lenovo TB-X505F' && this.partnerDeviceInfo.model == 'Lenovo TB-X505F') &&
        (this.myDeviceInfo.osVersion == '9' && this.myDeviceInfo.osVersion == '9') &&
        (this.myDeviceInfo.platform == 'android' && this.partnerDeviceInfo.platform == 'android')) {
          volume = 0.1;
        }
    // console.log(this.myDeviceInfo);
    // console.log(this.partnerDeviceInfo);
    this.partnerEl.volume = volume;
    setTimeout(() => {
      this.partnerEl.muted = false;
    }, 1500);
  }

  /**
   *  calling student
   */
  call(partnerId: string) {
    if (!this.disableCall) {
        const call = this.peer.call(partnerId, this.myStream);
        // Check if Safari on Big Sur
        // Bugfix
        if (this.myDeviceInfo.operatingSystem == "mac" &&
            this.myDeviceInfo.osVersion == "10.15.6" &&
            this.capacitorService.getBrowserName() == "safari") {
          // Hide own video only on mentor
          this.myEl.style.display = 'none';
        } else {
          this.myEl.volume = 0.9;
          this.myEl.play();
        }

        console.log('MAKING CALL TO ' + partnerId + '!');
          try {
            if (call) {
              console.log(call)
              call.on('error', (err) => {
                console.error(err);
                // Fire error
                // this.snackBar.open(errorMessage, "",{
                //   panelClass: 'snack-error',
                //   duration: 10000,
                //   horizontalPosition: 'center',
                //   verticalPosition: 'top'
                // });
              });
              // IOS on('stream') not working, addstream fixes it
              call.peerConnection.addEventListener('addstream', (e:any) => {
                  if(!this.callList[call.peer]) {
                    // Call got answered
                    console.log(e.stream)
                    console.log('call got answered');
                    this.partnerStream = e.stream;
                    // Enable remote video and audio first
                    this.partnerStream.getTracks().forEach(function(track, index, array) {
                        // console.log('track ' + index)
                        // console.log(track);    
                        track.enabled = true; 
                    });
                    // Set stream on video element and autoplay with volume
                    this.partnerEl.srcObject = this.partnerStream;
                    this.myEl.muted = true;
                    setTimeout(() => {
                      this.setPartnerVolume();
                    }, 1500);
                    //this.myStream.getAudioTracks()[0].enabled = false;
                    // Swap Video to full
                    this.connectionStatusListener.next(true);
                    // TODO Success feedback -> Close waiting for child
                    this.callList[call.peer] = call;
                  }
              });
              // call.on('stream', (remoteStream) => {
              //   if(!this.callList[call.peer]) {
              //     // Call got answered
              //     //console.log(stream)
              //     console.log('call got answered');
              //     console.log(remoteStream)
              //     this.partnerStream = remoteStream;
              //     // Set stream on video element and autoplay with volume
              //     this.partnerEl.srcObject = remoteStream;
              //     this.myEl.muted = true;
              //     setTimeout(() => {
              //       this.setPartnerVolume();
              //     }, 1500);
              //     //this.myStream.getAudioTracks()[0].enabled = false;
              //     // Swap Video to full
              //     this.connectionStatusListener.next(true);
              //     // TODO Success feedback -> Close waiting for child
              //     this.callList[call.peer] = call;
              //   }
              // });
              call.on('close', () => {
                console.log('call closed');
                this.callList = [];
                this.connectionStatusListener.next(false);
              });
            }
          } catch(e) {
            console.log(e)
          }
    }
  }

  /**
   *  wait for the call
   */
  wait() {
    console.log('WAITING FOR CALL, Child is ready!!');
    this.peer.on('call', (call) => {
        console.log(call)
        call.on('error', (err) => {
          console.error(err);
          // Fire error
          // this.snackBar.open(errorMessage, "",{
          //   panelClass: 'snack-error',
          //   duration: 10000,
          //   horizontalPosition: 'center',
          //   verticalPosition: 'top'
          // });
        });
        // Answer the call
        call.answer(this.myStream);
        // IOS on('stream') not working, addstream fixes it
        call.peerConnection.addEventListener('addstream', async (e:any) => {
          // console.log('addstream --------------------------')
          // console.log(e)
          // console.log(e.stream)
            if(!this.callList[call.peer]) {
              // Call answered
              console.log('call created');
              this.partnerStream = e.stream;
              // Enable remote video and audio first
              this.partnerStream.getTracks().forEach(function(track, index, array) {
                  // console.log('track ' + index)
                  // console.log(track);    
                  track.enabled = true; 
              });
              // Set stream on video element and autoplay with volume
              this.partnerEl.srcObject = this.partnerStream;
              this.myEl.muted = true;
              this.setPartnerVolume();
              //this.myStream.getAudioTracks()[0].enabled = false;
              // Swap Video to full
              this.connectionStatusListener.next(true);
              this.callList[call.peer] = call;
            }
        });
        // call.peerConnection.addEventListener('track', (e:any) => {
        //   console.log('track remote --------------------------')
        //   console.log(e.streams)
        //     if(!this.callList[call.peer]) {
        //       // Call answered
        //       console.log('call created');
        //       // Set stream on video element and autoplay with volume
        //       this.partnerEl.srcObject = e.streams[0];
        //       this.myEl.muted = true;
        //       this.setPartnerVolume();
        //       //this.myStream.getAudioTracks()[0].enabled = false;
        //       // Swap Video to full
        //       this.connectionStatusListener.next(true);
        //       this.callList[call.peer] = call;
        //     }
        // });
        // call.on('stream', (remoteStream) => {
        //   if(!this.callList[call.peer]) {
        //     // Call answered
        //     console.log('call created');
        //     // Set stream on video element and autoplay with volume
        //     this.partnerEl.srcObject = remoteStream;
        //     this.myEl.muted = true;
        //     this.setPartnerVolume();
        //     //this.myStream.getAudioTracks()[0].enabled = false;
        //     // Swap Video to full
        //     this.connectionStatusListener.next(true);
        //     this.callList[call.peer] = call;
        //   }
        // });
        call.on('close', () => {
            console.log('call closed');
            this.callList = [];
            this.connectionStatusListener.next(false);
        });
    });
  }

  toggleMutePartnerAudio() {
      this.partnerEl.muted = !this.partnerEl.muted;
  }

  toggleMuteMyAudioStream() {
      this.myStream.getAudioTracks()[0].enabled = !this.myStream.getAudioTracks()[0].enabled;
  }

  playVideoStreams() {
    this.myEl.play();
    this.partnerEl.play();
  }

  activatePartnerAudio() {
      this.partnerEl.muted = false;
      setTimeout(() => {
        this.partnerEl.volume = 0.9;
      }, 1500);
  }

  partnerVolIncrease() {
    if (this.partnerEl.volume < 1) {
      this.partnerEl.volume = this.round(this.partnerEl.volume + 0.1, 1);
    }
  }

  partnerVolDecrease() {
    if (this.partnerEl.volume > 0) {
      this.partnerEl.volume = this.round(this.partnerEl.volume - 0.1, 1);
    }
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getCurrentPartnerVol() {
    return this.partnerEl.volume;
  }

  getCurrentMuteVal() {
    return this.myEl.muted;
  }

  /**
   *  hangup / close camera and disconnect
   */
  async hangup() {
    // this.myEl.pause();
    // this.myEl.srcObject = null;
    // this.myEl.src = "";
    //this.partnerEl.pause();
    //this.partnerEl.srcObject = null;
    //this.partnerEl.src = "";
    this.disableCall = true;
    this.closePeerAndResetMedia();
  }

  /*
   * set deviceinfo from caller
   */ 
  setPartnerDeviceInfo(deviceInfo) {
    this.partnerDeviceInfo = deviceInfo;
  }

  closePeerAndResetMedia() {
      // Close camera
      if (this.myStream) {
        this.myStream.getTracks().forEach(function(track, index, array) {
          track.stop();      
      });
      }
      if (this.peer) {
        this.peer.disconnect();
        this.peer.destroy();
      }
      // this.myStream = null;
      // this.partnerStream = null;
      this.connectionStatusListener.next(false);
  }

  /**
   * getConnectionStatusListener
   */
  getConnectionStatusListener() {
		return this.connectionStatusListener.asObservable();
	}

  /**
   * set stream if handleSuccess
   */
  handleSuccess(stream: MediaStream) {
    this.myStream = stream;
    this.myEl.srcObject = stream;
    if (this.userType == 'mentor' || this.userType == 'coordinator') {
        this.tryCallingChild(this.partnerId, 50);
    } else {
      // Wait for camera access to create peer
      this.createPeer(this.userId);
      this.myEl.play();
    }
  }

  /**
   * handleError
   */
  handleError(error: any) {
    let errorMessage;
    if (error.name === 'ConstraintNotSatisfiedError') {
      errorMessage = "Die geforderte Auflösung wird von Ihrem Gerät nicht unterstützt";
     // console.log(`The resolution ${v.width.exact}x${v.height.exact} px is not supported by your device.`);
      console.log('The resolution px is not supported by your device.');
    }  
    if (error.name === 'PermissionDeniedError') {
      errorMessage = "Es wurden keine Berechtigungen zur Verwendung Ihrer Kamera und Ihres Mikrofons erteilt. Sie müssen den Seitenzugriff auf Ihre Geräte zulassen.";
      console.log('Permissions have not been granted to use your camera and ' +
        'microphone, you need to allow the page access to your devices in ' +
        'order for the demo to work.');
    }

    if ( error.name === "NotAllowedError") {
      errorMessage = "Es wurde keine Berechtigung für Kamera oder Mikrofon erteilt.";
      console.log(`getUserMedia error: ${error.name}`, error);
    }

    if ( error.name === "AbortError") {
      errorMessage = "Es trat ein Problem auf, das die Verwendung des Gerätes verhinderte.";
      console.log(`getUserMedia error: ${error.name}`, error);
    }

    if ( error.name === "NotFoundError") {
      errorMessage = "Bitte prüfen Sie, ob Ihre Webcam und/oder Ihr Mikrofon aktiviert ist.";
      console.log(`getUserMedia error: ${error.name}`, error);
    }

    if ( error.name === "NotReadableError") {
      errorMessage = "Es ist ein Hardwarefehler aufgetreten, der den Zugriff auf das Gerät verhinderte.";
      console.log(`getUserMedia error: ${error.name}`, error);
    }

    if ( error.name === "OverconstrainedError") {
      errorMessage = "Die angegebenen Einschränkungen führten dazu, dass keine Kandidatengeräte die angeforderten Kriterien erfüllen.";
      console.log(`getUserMedia error: ${error.name}`, error);
    }

    if ( error.name === "SecurityError") {
      errorMessage = "Die Unterstützung von Benutzermedien ist deaktiviert.";
      console.log(`getUserMedia error: ${error.name}`, error);
    }

    if ( error.name === "TypeError") {
      errorMessage = "Die Liste der angegebenen Einschränkungen ist leer oder alle Einschränkungen sind auf false gesetzt.";
      console.log(`getUserMedia error: ${error.name}`, error);
    }
    // Fire error
    this.snackBar.open(errorMessage, "",{
      panelClass: 'snack-error',
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  /**
   * Penetration test for peer server
   */
  penetratePeer() {
    // let number = 500;
    //   for (let i = 0; i < number; i++) {
    //       let penUser = 'pen' + Math.random().toString(36).substring(2, 15);
    //       let penPeer = new Peer(penUser, this.options);
    //           penPeer.on('open', (id) => {
    //               console.log('My penPeer ID is: ' + id);
    //               penPeer.connect(Math.random().toString(36).substring(2, 15));
    //           });
    //           penPeer.on('close', function() {
    //               console.log('on close');
    //           });
    //   }
  }
}