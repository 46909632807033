<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-12 col-sm-12 px-3" *ngIf="start">
      <h1>{{currentQuizSet.question}}</h1>
      <mat-radio-group [(ngModel)]="currentQuizSet.selected">
        <mat-radio-button *ngFor="let o of currentQuizSet.answers" name="{{o._id}}" value="{{o._id}}">{{o.answerText}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="col-md-12 col-sm-12 px-3" *ngIf="start">
      <button mat-button color="accent" *ngIf="(currentIndex+1) != quiz.length" (click)="next()"
        [disabled]="!currentQuizSet?.selected" class="size-big">Weiter</button>
    </div>

    <div class="col-md-12 col-sm-12 px-3" *ngIf="quiz?.length > 0 && quizCheck">
      <div *ngIf="!start">
        <div *ngIf="!quizFinished">
          <h1>Zu {{bookName}} wurden {{ quiz.length }} Fragen gefunden.</h1>
          <p>Möchtest Du das Quiz starten?</p>
        </div>
        <div *ngIf="quizFinished">
          <h1>Ergebnis: {{correct}}/{{quiz.length}} Fragen wurden richtig beantwortet.</h1>
        </div>
        <button mat-button color="accent" (click)="startQuiz()" class="size-big">{{buttonname}}</button>
      </div>
    </div>

    <div class="col-md-12 col-sm-12 px-3" *ngIf="start">
      <button mat-button color="accent" (click)="submit()" class="size-big" *ngIf="endQuiz"
        [disabled]="!currentQuizSet?.selected">Quiz beenden</button>
    </div>
      

    <div class="col-md-12 col-sm-12 px-3" *ngIf="quiz?.length < 1 && quizCheck">
      <h1> Keine Fragen zu {{bookName}} vorhanden</h1>
    </div>

    <mat-icon class="close_quiz_icon" (click)="closeQuiz()">close</mat-icon>
  </div>
</div>