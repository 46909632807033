import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { StudentViewComponent } from '../student-view/student-view.component';
import { MentorViewComponent } from '../../mentor/mentor-view/mentor-view.component';
import { MentorAddStudentComponent } from '../../mentor/mentor-add-student/mentor-add-student.component';

export interface mentors {}
export interface Student {}

@Component({
  selector: 'app-student-matching',
  templateUrl: './student-matching.component.html',
  styleUrls: ['./student-matching.component.scss'],
})
export class StudentMatchingComponent implements OnInit {
  displayedColumns: string[] = ['created','firstname', 'lastname', 'coordinator', "monday", "tuesday" , "wednesday" , "thursday", "friday", "saturday", "sunday", 'match-button'];
  displayedStudentColumns: string[] = ['preview-button','firstname', 'lastname', 'school', "monday", "tuesday" , "wednesday" , "thursday", "friday", "saturday", "sunday"];
  dataSource:any;
  dataSourceStudent:any;
  studentAvailableDays = [];

	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  isAdmin = false;
  filterValues = {
    search: '',
    availability: false,
    students: false,
    studentsCapacity: false
  };
  id;
  student: Student[] = [];
  mentors: mentors[] = [];
  availabilityMentor;
  private mentorsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    private router: Router,
    private route:ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {    
    this.id = this.route.snapshot.params['id'];
    this.userService.getUserbyId(this.id).subscribe(res => {
      this.student = res;
      console.log(this.student)
      this.dataSourceStudent = new MatTableDataSource<Student>([res]);
      // Read available days
      if (this.student['availability']) {
        let studentAvailability = JSON.parse(this.student['availability']);
        if (!(studentAvailability.monday == null || studentAvailability.monday == '')) { this.studentAvailableDays.push('monday'); }
        if (!(studentAvailability.tuesday == null || studentAvailability.tuesday == '')) { this.studentAvailableDays.push('tuesday'); }
        if (!(studentAvailability.wednesday == null || studentAvailability.wednesday == '')) { this.studentAvailableDays.push('wednesday'); }
        if (!(studentAvailability.thursday == null || studentAvailability.thursday == '')) { this.studentAvailableDays.push('thursday'); }
        if (!(studentAvailability.friday == null || studentAvailability.friday == '')) { this.studentAvailableDays.push('friday'); }
        if (!(studentAvailability.saturday == null || studentAvailability.saturday == '')) { this.studentAvailableDays.push('saturday'); }
        if (!(studentAvailability.sunday == null || studentAvailability.sunday == '')) { this.studentAvailableDays.push('sunday'); }
      }
      console.log(this.studentAvailableDays)
    });


    this.userService.getMentorsAndCoordinatorsList();
    this.mentorsSub = this.userService.getMentorsAndCoordinatorsUpdateListener().pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe((mentors: mentors[]) => {
        this.mentors = mentors;
        this.dataSource = new MatTableDataSource<mentors>(this.mentors);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
        // Filter in nested array as well
        this.dataSource.filterPredicate = (data: any, filter) => {
          let availabilityFound = false;
          // Check if children without mentor is set
          if (filter.availability) {
            if (data['availability'] == undefined || data['availability'] == '' || typeof this.isJson(data['availability']) !== 'object') {
              availabilityFound = false;
            } else {
              let mentorAvailability = JSON.parse(data['availability']);
              if (!(mentorAvailability.monday == null || mentorAvailability.monday == '') && this.studentAvailableDays.includes('monday')) { availabilityFound = true; }
              if (!(mentorAvailability.tuesday == null || mentorAvailability.tuesday == '') && this.studentAvailableDays.includes('tuesday')) { availabilityFound = true; }
              if (!(mentorAvailability.wednesday == null || mentorAvailability.wednesday == '') && this.studentAvailableDays.includes('wednesday')) { availabilityFound = true; }
              if (!(mentorAvailability.thursday == null || mentorAvailability.thursday == '') && this.studentAvailableDays.includes('thursday')) { availabilityFound = true; }
              if (!(mentorAvailability.friday == null || mentorAvailability.friday == '') && this.studentAvailableDays.includes('friday')) { availabilityFound = true; }
              if (!(mentorAvailability.saturday == null || mentorAvailability.saturday == '') && this.studentAvailableDays.includes('saturday')) { availabilityFound = true; }
              if (!(mentorAvailability.sunday == null || mentorAvailability.sunday == '') && this.studentAvailableDays.includes('sunday')) { availabilityFound = true; }
            }
          }
          // Check if students array is empty
          let withoutStudentsFound = false;
          if (filter.students) {
            if (data['students'].length < 1) {
              withoutStudentsFound = true;
            }
          }
          // Check if studentsCapacity available
          let withoutStudentsCapacityFound = false;
          if (filter.studentsCapacity) {
            if (Number(data['studentsCapacity']) > data['students'].length) {
              console.log(data)
              withoutStudentsCapacityFound = true;
            }
          }
          // Find position for search word
          let positionFound = JSON.stringify(data).toLowerCase().indexOf(filter.search) != -1;
          if (filter.availability || filter.students || filter.studentsCapacity) {
              // All selected
              if (filter.availability && filter.students && filter.studentsCapacity) {
                  return availabilityFound && withoutStudentsFound && withoutStudentsCapacityFound && positionFound;
              } // Availability and Students selected 
              else if (filter.availability && filter.students && !filter.studentsCapacity) {
                return availabilityFound && withoutStudentsFound && positionFound;
              } // Availability and studentsCapacity selected 
              else if (filter.availability && !filter.students && filter.studentsCapacity) {
                return availabilityFound && withoutStudentsCapacityFound && positionFound;
              } // Students only selected
              else if (!filter.availability && filter.students && !filter.studentsCapacity) {
                return withoutStudentsFound && positionFound;
              }
              // studentsCapacity only selected
              else if (!filter.availability && !filter.students && filter.studentsCapacity) {
                return withoutStudentsCapacityFound && positionFound;
              }
              // Students and studentsCapacity selected
              else if (!filter.availability && filter.students && filter.studentsCapacity) {
                return withoutStudentsFound && withoutStudentsCapacityFound && positionFound;
              }
              // Availability selected
              else {
                return availabilityFound && positionFound;
              }
          } else {
              return positionFound;
          }

        };       
      });
  }

  isJson(str) {
      try {
          return JSON.parse(str);
      } catch (e) {
          return false;
      }
  }

  extractNameFromJson(obj, day){
    if (obj != undefined && obj != "") {
      obj = JSON.parse(obj);
      switch(day) { 
        case 'monday': { 
          return obj.monday;
        } 
        case 'tuesday': { 
          return obj.tuesday;
        }
        case 'wednesday': { 
          return obj.wednesday;
        } 
        case 'thursday': { 
          return obj.thursday;
        } 
        case 'friday': { 
          return obj.friday;
        } 
        case 'saturday': { 
          return obj.saturday;
        } 
        case 'sunday': { 
          return obj.sunday;
        } 
        default: { 
           return;
        } 
     }
    }  else {
      return '';
    }
  }

  studentsSelectionFilter(el) {
    this.filterValues['students'] = el.checked;
    this.dataSource.filter = this.filterValues;
  }

  studentsCapacitySelectionFilter(el) {
    this.filterValues['studentsCapacity'] = el.checked;
    this.dataSource.filter = this.filterValues;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValues['search'] = filterValue.trim().toLowerCase()
    this.dataSource.filter = this.filterValues;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  availabilityMatchSelectionFilter(el) {
    this.filterValues['availability'] = el.checked
    this.dataSource.filter = this.filterValues;
  }

  openStudentProfil(id): void {
    const dialogRef = this.dialog.open(StudentViewComponent, {
      width: '100%',
      autoFocus: false,
      data: { id:  this.id }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

  openMentorProfil(id): void {
    console.log(id)
    const dialogRef = this.dialog.open(MentorViewComponent, {
      width: '100%',
      autoFocus: false,
      data: { id: id }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

  openMatch(element): void {
    const dialogRef = this.dialog.open(MentorAddStudentComponent, {
      width: '1000px',
      autoFocus: false,
      data: { 
        mentor: element,
        student: this.student
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }
}
