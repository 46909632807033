import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { WebrtcService } from '../providers/webrtc.service';
import { WebSocketService } from '../providers/websocket.service';
import { AuthService } from '../auth/auth.service';
import { Subscription, Subject } from 'rxjs';
import { CapacitorService } from '../providers/capacitor.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss'],
})
export class VideoCallComponent implements AfterViewInit {
  topVideoFrame = 'partner-video';
  activeVideo: boolean = true;
  userId: any;
  userType: string;
  showOpponent: boolean = false;
  remoteStreamPaused: boolean = true;
  remoteStreamActive: boolean = false;
  // TODO dynamic child
  myEl: HTMLMediaElement;
  partnerEl: HTMLMediaElement;
  private stream: MediaStream;
  private sub: any;
  private connectionListenerSubs: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  @ViewChild('myvideo') myVideo: ElementRef;
  @ViewChild('partnervideo') partnerVideo: ElementRef;
  @Input('partnerId') partnerId: string;
  @Input('showBoth') showBoth: boolean;

  constructor(
    public webRTC: WebrtcService,
    private webSocketService: WebSocketService,
    public elRef: ElementRef,
    private authService : AuthService,
    private capacitorService: CapacitorService
  ) { }

  ngAfterViewInit() {
    this.getAuthData();
    this.connectionListenerSubs = this.webRTC.getConnectionStatusListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(isConnected => {
        if (isConnected) {
            if (this.showBoth) {
              this.showOpponent = true;
            }
            this.swapVideo('my-video');
        } else {
          this.swapVideo('partner-video');
        }
    });
  }

  /**
   * getAuthData/ ID, Usertype
   */
  getAuthData() {
    this.userId = this.authService.getCurrentID();
    this.userType = this.authService.getType();
    this.initStream();
  }

  /**
   * initStream
   */
  initStream() {
    // this.myEl = this.elRef.nativeElement.querySelector('#my-video');
    this.myEl = this.myVideo.nativeElement;
    this.myEl.volume = 0;
    this.myEl.muted = true;
    // this.partnerEl = this.elRef.nativeElement.querySelector('#partner-video');
    this.partnerEl = this.partnerVideo.nativeElement;
    this.partnerEl.volume = 0;
    this.partnerEl.muted = true;
// // Force restart partner stream
// this.partnerEl.addEventListener('play', (event) => {
//   console.log('Video is playing');
//   // this.handlePartnerVideoPlay(this.partnerEl);
//   this.remoteStreamPaused = false;
// });
// this.partnerEl.addEventListener('pause', (event) => {
//   console.log('Video is no longer playing');
//   // this.handlePartnerVideoPlay(this.partnerEl);
//   this.remoteStreamPaused = true;
// });
// this.partnerEl.addEventListener('loadeddata', (event) => {
//   console.log('Yay! The readyState just increased to  ' +
//       'HAVE_CURRENT_DATA or greater for the first time.');
//       //this.handlePartnerVideoPlay(this.partnerEl);
//       this.remoteStreamActive = true;
// });
    //Login
    this.webRTC.initPeer(this.userId, this.userType, this.capacitorService.getDeviceInfo());
    this.webRTC.initVideo(this.myEl, this.partnerEl, this.partnerId);
  }

  /**
   * swapVideo / change the view
   */
  swapVideo(topVideo: string) {
    setTimeout(() => {
      this.topVideoFrame = topVideo;
    }, 2000);
  
  }

  /**
   * Fires if remote stream came through
   */
  remoteVideoLoaded(element) {
      // Trying to restart
      this.handleVideoPlay(element);
      this.remoteStreamActive = true;
  }

  /**
   * Fires if remote stream got paused
   */
  remoteVideoPaused(element) {
      console.log('video got paused')
      // Trying to restart
      this.handleVideoPlay(element);
  }

  /**
   * try to play video
   */
  async playVideo(element) {
    try {
      console.log('trying to restart video')
      await element.play();
      this.remoteStreamPaused = false;
    } catch(err) {
      this.remoteStreamPaused = true;
    }
  }
  
  /**
   * handle play video if paused
   */
  handleVideoPlay(element) {
    console.log('handling video')
    if (element.paused) {
      this.playVideo(element);
    } else {
      this.remoteStreamPaused = false;
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
      this.activeVideo = false;
      this.turnOffUserMedia();
      // this.myEl.pause();
      // this.myEl.src = "";
      // this.myEl.srcObject = null;
      this.connectionListenerSubs.unsubscribe();
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  turnOffUserMedia() {
    let stream = this.myVideo.nativeElement.srcObject;
    if (stream) {
      let tracks = stream.getTracks();
      tracks.forEach(function (track) {
          //console.log(track)
          track.stop();
      });
    }
    // this.myVideo.nativeElement.srcObject = null;
    // this.myVideo.nativeElement.src = "";
    // this.myEl.srcObject = null;
    // this.myEl.src = "";
  }
}
