<div class="container-fluid">
  <div class="reset-password row">
    <div class="col-md-12 d-flex justify-content-md-center">
      <div class="reset_wrap">
        <img src="assets/logos/{{clientLogo}}" class="dashboard-logo" />
        <form [formGroup]="rForm" (ngSubmit)="ResetPassword(formDirective)" #formDirective="ngForm">
          <mat-form-field>
            <input matInput formControlName="username" type="username" placeholder="Benutzername" >
          </mat-form-field>
          <button mat-button color="accent" type="submit" class="size-big">Passwort zurücksetzen</button>
        </form>
      </div>
    </div>
  </div>
</div>