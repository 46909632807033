import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';


import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators, FormGroupDirective } from "@angular/forms";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
})
export class AdminSettingsComponent implements OnInit {
  rForm: FormGroup;
  subscription: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();


  constructor(
    private authService : AuthService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.rForm = new FormGroup({
      technicalSupport: new FormControl(null, { validators: []}),
      showContactPerson: new FormControl(null, { validators: []}),
      showContactMentor: new FormControl(null, { validators: []}),
      showContactCoordinator: new FormControl(null, { validators: []}),
      mentorContactIntro: new FormControl(null, { validators: []}),
      coordinatorContactIntro: new FormControl(null, { validators: []})
    })

    // Get field input from API
    this.authService.getAppSettingsListener()
        .pipe(
          takeUntil(this._unsubscribeAll)
        )
        .subscribe(settings => {
          if (settings["technicalSupport"]) {
            this.rForm.get('technicalSupport').setValue(settings["technicalSupport"])
          }
          if (settings["mentorContactIntro"]) {
            this.rForm.get('mentorContactIntro').setValue(settings["mentorContactIntro"])
          }
          if (settings["coordinatorContactIntro"]) {
            this.rForm.get('coordinatorContactIntro').setValue(settings["coordinatorContactIntro"])
          }
          let showContactPerson = true;
          if (settings['showContactPerson'] == undefined) {
            showContactPerson = false;
          } else {
            showContactPerson = settings['showContactPerson'];
          }
          this.rForm.get('showContactPerson').setValue(showContactPerson);
          let showContactMentor = true;
          if (settings['showContactMentor'] == undefined) {
            showContactMentor = false;
          } else {
            showContactMentor = settings['showContactMentor'];
          }
          this.rForm.get('showContactMentor').setValue(showContactMentor);
          let showContactCoordinator = true;
          if (settings['showContactCoordinator'] == undefined) {
            showContactCoordinator = false;
          } else {
            showContactCoordinator = settings['showContactCoordinator'];
          }
          this.rForm.get('showContactCoordinator').setValue(showContactCoordinator);
        });
    this.authService.loadAppSettings();
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    // Update fields with API
    this.authService.updateAppSettings({
      technicalSupport : this.rForm.value.technicalSupport,
      showContactPerson : this.rForm.value.showContactPerson,
      showContactMentor : this.rForm.value.showContactMentor,
      showContactCoordinator : this.rForm.value.showContactCoordinator,
      mentorContactIntro : this.rForm.value.mentorContactIntro,
      coordinatorContactIntro : this.rForm.value.coordinatorContactIntro
    }).subscribe(res => {
        this.router.navigate(['/admin-dashboard']);
    });
  }
}
