import { Component, OnInit, Input } from '@angular/core';
import { WebSocketService } from '../../../providers/websocket.service';
import { AuthService } from '../../../auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-game-connect-four',
  templateUrl: './game-connect-four.component.html',
  styleUrls: ['./game-connect-four.component.scss'],
})
export class GameConnectFourComponent implements OnInit {

  @Input('userType') isMentor;
  @Input('studentId') studentId: string;
  @Input('mentorId') mentorId: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isLoading: boolean = true;
  
  constructor(
    private webSocketService: WebSocketService,
    private authService : AuthService
  ) { }

  ngOnInit() {
    // Generate gameplay server
    this.initGame();
  }

  /**
   * initGame / start game
   */ 
  initGame() {
    // Generate gameplay frontend
    // this.webSocketService.connectFour();
     this.webSocketService.connectFour(this.mentorId, this.studentId, this.isMentor);
     console.log(this.isMentor)
    // this.webSocketService.emitTo('game-connect-four-create-game', this.webSocketService.getCurrentRoom(), {
    //   mentor: this.mentorId,
    //   child: this.studentId
    // });

    this.webSocketService.listen('game-connect-four-board-initialized').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => { this.isLoading = false; });
  }

  /**
   * reset the game
   */ 
  resetGame() {
    if (this.authService.getType() == 'mentor' || this.authService.getType() == 'coordinator') {
      var data = {
        turn : this.studentId
      };
    } else {
      var data = {
        turn : this.mentorId
      };
    }
    this.webSocketService.emitTo('game-connect-four-reset', this.webSocketService.getCurrentRoom(), data);
    console.log('resetted')
  }

  /**
   * On destroy / left game if mentor close it
   */
  ngOnDestroy(): void {
    console.log('Game connect four left')
    this.webSocketService.connectFourClearEventlistener();
    this.webSocketService.emitTo('game-connect-remove-board', this.webSocketService.getCurrentRoom(), '');
  }
}