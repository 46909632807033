
<div class="container">
  <div class="book-shelf row">
    <div class="col-md-3 col-sm-6 col-xs-12">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Vier gewinnt</mat-card-title>
          </mat-card-header>
          <div class="mat-card-image-wrap">
            <img mat-card-image src="assets/games/game-connect-four-cover.jpg" />
          </div>
          <!-- <mat-card-content>
            <p>
            </p>
          </mat-card-content> -->
          <mat-card-actions>
            <button mat-button [mat-dialog-close]="'connect-four'">Spielen</button>
          </mat-card-actions>
        </mat-card>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Yalah!</mat-card-title>
        </mat-card-header>
        <div class="mat-card-image-wrap">
          <img mat-card-image src="assets/games/game-uno-cover.jpg" />
        </div>
        <!-- <mat-card-content>
          <p>
          </p>
        </mat-card-content> -->
        <mat-card-actions>
          <button mat-button [mat-dialog-close]="'uno'">Spielen</button>
        </mat-card-actions>
      </mat-card>
  </div>
  </div>
</div>