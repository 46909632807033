<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-8 col-sm-12">
        <h1 class="pt-3">Mentor Profil</h1>
        <div class="register_wrap">
            <div class="row">
              <div class="col-md-6">
                  <div>Benutzername: {{username}}</div>
                  <div>Aktive Mitgliedschaft: {{activeMembershipText}}</div>
                  <div>Wissenschaftliche Befragung: {{scientificSurveyText}}</div> 
                  <div>Präferenz Schulforn für Leseaktivität: {{preferredTypeOfSchool}}</div>              

                  <div *ngIf="created">
                    Erstellt: {{ created | date:'dd.MM.yyyy' }} 
                  </div>

                  <div>Kapazität an Lesekindern: {{studentsCapacity}}</div>

                  <div *ngIf="mentor?.students">
                      <br />
                      <h3>Betreute Kinder: {{ mentor?.students.length }}</h3>
                      <ol>
                        <li *ngFor="let student of mentor?.students">
                          {{ student.firstname }} {{ student.lastname }}
                        </li>
                      </ol>
                  </div>
              </div>

              <div class="col-md-6">
                <div>Vorname: {{firstname}}</div>
                <div>Nachname: {{lastname}}</div>
                <div>E-Mail: {{email}}</div>
                <div>Telefon Festnetz: {{phoneNumber}}</div>
                <div>Telefon Mobil: {{mobileNumber}}</div>
                <div>Geburtstag: {{birthday | date: 'dd.MM.yyyy' :'+0200':'de' }}</div>
              </div>
              <div class="col-md-12" *ngIf="comment">
                  Kommentar:<br>
                  {{comment}}
              </div>
            </div>
        </div>
    </div>
  </div>
</div>