<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-8 col-sm-12">
        <h1 class="pt-3">Buch bearbeiten</h1>
        <div class="register_wrap">
          <form [formGroup]="rForm" #formDirective="ngForm">
            <div class="row">
              <div class="col-md-12">
                  <mat-form-field>
                    <input matInput formControlName="name"  type="text" placeholder="Buchname" required>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Altersempfehlung</mat-label>
                    <mat-select formControlName="recommendation" placeholder="Altersempfehlung">
                      <mat-option value=""></mat-option>
                      <mat-option value="6 bis 8">6 bis 8</mat-option>
                      <mat-option value="8 bis 10">8 bis 10</mat-option>
                      <mat-option value="Ab 10">Ab 10</mat-option>
                    </mat-select>
                  </mat-form-field>
  
                  <button  mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big" aria-label="Registration">
                      Speichern
                  </button>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
</div>