import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from "../../../auth/auth.service";
import moment from 'moment';
import {StudentViewComponent} from "../student-view/student-view.component"
import { ConfirmDialogComponent } from '../../../helpers/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.scss'],
})
export class StudentListComponent implements OnInit {
  displayedColumns: string[] = ['preview-button', 'username', 'firstname', 'lastname', 'school', 'mentor', 'nextMeeting', 'buttons'];
  dataSource:any;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isAdmin: boolean = false;
  userType: string;
  dialogConfig: any;
  firstname: string;
  lastname: string;
  filterValues = {
    search: '',
    mentor: false,
    nextMeeting: false
  };

  students: Students[] = [];
  private studentsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(   
    private userService : UserService,
    public authService: AuthService,
    public dialog: MatDialog,
    ) {}

  ngOnInit() {
    this.userType = this.authService.getType();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    this.userService.getStudentsList();
    this.studentsSub = this.userService.getStudentsUpdateListener().pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe((students: Students[]) => {
      console.log(students)
        this.students = students;
        this.firstname = students["firstname"];
        this.lastname = students["lastname"];
        console.log(this.students)
        this.dataSource = new MatTableDataSource<Students>(students);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
        // Filter in nested array as well
        this.dataSource.filterPredicate = (data: any, filter) => {
          // Check if children without mentor is set
          let withoutMentorFound = false;
          if (filter.mentor) {
            if (data['mentor'] == undefined) {
              withoutMentorFound = true;
            }
          }
          // Check if student has no nextMeeting in the future
          let withoutnextMeetingFound = false;
          if (filter.nextMeeting) {
            if (data['nextMeeting'] == undefined) {
              withoutnextMeetingFound = true;
            } else if (data['nextMeeting'] == undefined) {} else {
                let currentTime = moment.utc().format("YYYY-MM-DDTHH:mm:00.000[Z]");
                // Compare current date with meeting date to show info
                if (data['nextMeeting'] < currentTime) {
                    withoutnextMeetingFound = true;
                }
            }
          }
          // Find position for search word
          let positionFound = JSON.stringify(data).toLowerCase().indexOf(filter.search) != -1;
          if (filter.nextMeeting || filter.mentor) {
              // Both active
              if (filter.nextMeeting && filter.mentor) {
                  return withoutMentorFound && withoutnextMeetingFound && positionFound;
              } // Mentor selected
              else if (!filter.nextMeeting && filter.mentor) {
                return withoutMentorFound && positionFound;
              } // Availability selected
              else {
                return withoutnextMeetingFound && positionFound;
              }
          } else {
              return positionFound;
          }
        };
      });
  }

  mentorSelectionFilter(el) {
    this.filterValues['mentor'] = el.checked
    this.dataSource.filter = this.filterValues;
  }

  nextMeetingSelectionFilter(el) {
    this.filterValues['nextMeeting'] = el.checked
    this.dataSource.filter = this.filterValues;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValues['search'] = filterValue.trim().toLowerCase()
    this.dataSource.filter = this.filterValues;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteUser(id, firstname, lastname) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    //this.dialogConfig.width = '400px';
    this.dialogConfig.data = {
      title: 'Achtung',
      note: ' Wollen Sie' +"  " + firstname + " " + lastname + " " +'wirklich löschen?',
      accept: true,
      acceptIcon: '',
      acceptText: 'Ja',
      decline: true,
      declineIcon: '',
      declineText: 'Nein'
    };
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(result => {
      if (result == 'ok') {
       this.userService.deleteUserAndRemoveRelation(id).subscribe(res => {
          // Update local list
          this.students.splice(this.students.findIndex((item) => {
              return item._id === id;
          }), 1);
          // Refresh datasource
          this.dataSource._updateChangeSubscription();
        }, (error) => {
            console.error("Error deleting user: ", error);
        });
      }
    });
  }

  openStudentProfil(id): void {
    const dialogRef = this.dialog.open(StudentViewComponent, {
      width: '100%',
      autoFocus: false,
      data: { id: id }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }
}