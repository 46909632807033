import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-student-menu',
  templateUrl: './student-menu.component.html',
  styleUrls: ['./student-menu.component.scss'],
})
export class StudentMenuComponent implements OnInit {
  isAdmin = false;
  isCoordinator = false;
  userType;

  constructor( 
    private authService : AuthService,
    ) { }

  ngOnInit() {
    this.userType = this.authService.getType();

    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    if (this.userType == 'coordinator') {
      this.isCoordinator = true;
    }
  }

}
