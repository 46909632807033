import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-coordinator-menu',
  templateUrl: './coordinator-menu.component.html',
  styleUrls: ['./coordinator-menu.component.scss'],
})
export class CoordinatorMenuComponent implements OnInit {
  isAdmin = false;
  isCoordinator = false;
  userType;

  constructor(
    private authService : AuthService,
  ) { }

  ngOnInit() {
    this.userType = this.authService.getType();

    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    if (this.userType == 'coordinator') {
      this.isCoordinator = true;
    }
  }

}
