<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-8 col-sm-12">
      <div class="add-content" *ngIf="highlightTemp" (click)="addTextSelectionToQuestion()">
        <mat-icon>add</mat-icon> Möchtest du diesen Textabschnitt hinzufügen?
      </div>
      <!-- <app-bookreader [readingMode]="'Solo'" [bookUrl]="bookUrl" *ngIf="readingModeIsEpub"></app-bookreader> -->
      <app-pdfreader (mouseup)="showSelectedText(0)" [readingMode]="'Solo'" [bookUrl]="bookUrl" [toolButtons]="true"
        [bookActivePage]="bookActivePage" (activePage)="getActivePage($event)" *ngIf="readingModeIsPDF"></app-pdfreader>
    </div>
    <div class="col-md-4 col-sm-12">
      <!-- <div class="button-group">
        <button mat-button color="accent" (click)="openDialog()" class="size-big">Buch auswählen</button>
      </div> -->
      <form [formGroup]="rForm" #formDirective="ngForm">
        <mat-form-field>
          <textarea matInput formControlName="question" type="question" placeholder="Frage"
            required> </textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Typ</mat-label>
          <mat-select [(value)]="selectedType" (selectionChange)="quizType($event)">
            <mat-option selected value="yes-no">Ja / Nein</mat-option>
            <mat-option value="1of3">1 aus 3</mat-option>
            <mat-option value="freetext">Freitext</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Schwierigkeitsgrad</mat-label>
          <mat-select formControlName="difficultyLevel">
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="table table-bordered" formArrayName="answers" *ngIf="!isHidden">
          <!-- <mat-icon *ngIf="showAddAnswers" (click)="addAnswer()"> add</mat-icon>  -->
          
          <div *ngFor="let answer of getAnswersFormControls(); let i=index" [formGroupName]="i">
                <mat-form-field class="answer_input">
                  <fieldset class="answers_field" [disabled]="isDisabled">
                    <input matInput formControlName="answerText" type="text" placeholder="Antwort {{i +1 }}" required>
                  </fieldset>
                </mat-form-field>
                <mat-slide-toggle class="example-margin" color="primary" [checked]="rightAnswer" (change)="onChange($event, i)" formControlName="rightAnswer"> </mat-slide-toggle>
                <!-- <mat-icon *ngIf="i > 1" (click)="removeAnswer(i)"> remove</mat-icon> -->
          </div>
        </div>

        <div class="highlighted-content">
          Auf welche Textabschnitte bezieht sich die Frage?
          <p>Textmarkierungen*:</p>
          <div *ngFor="let highlight of highlightText; let i=index" class="marked-content">
            <div class="remove-content" (click)="removeHighlightedText(i)">
              <mat-icon>remove</mat-icon>
            </div>
            {{ highlight.text }}
            <span>Seite {{ highlight.page }}</span>
          </div>
          <div *ngIf="isHighlighted" class="errors">
            <span *ngIf="isHighlighted">Textmarkierungen notwendig</span>
          </div>
        </div>
        <!-- <mat-form-field>
          <fieldset class="answers_field" disabled>
          <textarea matInput formControlName="textPassage" [ngModel]="highlightText[0]" type="text" placeholder="Textmarkierung"></textarea>
        </fieldset>
        </mat-form-field> -->

        <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big" aria-label="Frage speichern">
          Frage erstellen
        </button>
      </form>
    </div>
  </div>
</div>