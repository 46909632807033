<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-6 col-sm-12">
        <h1 class="pt-3">Schule bearbeiten</h1>
        <div class="register_wrap">
          <form [formGroup]="rForm" #formDirective="ngForm">

            <mat-form-field>
              <input matInput formControlName="name" type="text" placeholder="Name der Schule" required>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Schulform</mat-label>
              <mat-select formControlName="typeOfSchool" type="role" required>
                <mat-option value="Grundschule">Grundschule</mat-option>
                <mat-option value="Weiterführend">Weiterführend</mat-option>
                <mat-option value="Andere">Andere</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="streetNr"  type="text" placeholder="Straße / Hausnummer">
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="plz"  type="text" placeholder="PLZ">
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="city" type="text" placeholder=" Ort der Schule">
            </mat-form-field>

            <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big" aria-label="Speichern">
                  Speichern
            </button>
          </form>
        </div>
    </div>
  </div>
</div>