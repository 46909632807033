import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../helpers/confirm-dialog/confirm-dialog.component';
import { SessionService } from '../providers/session.service';
import { AuthService } from '../auth/auth.service';
import { map, takeUntil } from 'rxjs/operators';
import { UserService } from '../providers/user.service';
import { SessionNoteDialogComponent } from '../session-note/session-note.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';


export interface Session {
  student: string[];
  mentor: string;
  filename: string;
  sessionReadTime: number;
  readingMode: string;
}


@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent implements OnInit {
  // column name of the table
  displayedColumns: string[] = ['sessionReadTime', 'sessionGoalTime', 'readBooks', 'readingMode', 'created', 'filename', 'buttons'];
  dataSource:any;
  dialogConfig: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private userSub: Subscription;
  session: Session[] = [];
  studentId: string;
  isMentor = true;
  isAdmin = false;
  showFilter = false;
  isCoordinator = false;
  activeStudentName;
  userType;
  pageSize = 5;
  totalReadingTime = 0; 
  totalBookCount = 0;
  totalPagesCount = 0;
  readingPointsIncreased = 0;
  //@Input('studentId') studentId: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService : SessionService,
    private authService : AuthService,
    private userService : UserService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.studentId = this.route.snapshot.params['id'];
    this.userType =  this.authService.getType();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    if (this.userType == 'coordinator') {
      this.isCoordinator = true;
    }
    if (this.isAdmin || this.isCoordinator) {
      // If coordinator but mentor view
      if (!this.studentId) {
        this.displayedColumns = ['sessionReadTime', 'sessionGoalTime', 'readBooks', 'readingMode', 'student', 'created', 'filename', 'buttons'];
        this.pageSize = 9;
        this.showFilter = true;
      }
    }
    if (this.studentId) {
      this.sessionService.getListOfSession(this.studentId);     
    } else {
      this.sessionService.getListOfSession();     
    }
    if (!(this.userType == 'mentor' || this.userType == 'coordinator')) {
      this.isMentor = false;
    }
    if (!this.isAdmin) {
      this.activeStudentName = this.authService.getUsernameOfActiveStudent();
    }
    this.userSub = this.sessionService.getSessionUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((session:Session[]) => {
        this.session = session;
        console.log(this.session)
        this.dataSource = new MatTableDataSource<Session>(this.session);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
        // Filter in nested array as well
        this.dataSource.filterPredicate = (data: any, filter) => {
          const dataStr = JSON.stringify(data).toLowerCase();
          return dataStr.indexOf(filter) != -1; 
        };
    });
  }

  getAudio(filename) {
    return this.sessionService.getAudio(filename);
  }

  addNoteToSession(id) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    //this.dialogConfig.width = '400px';
    this.dialogConfig.data = {
      title: 'Notiz hinzufügen',
      note: 'Diese Funktion steht noch nicht zur Verfügung.',
      accept: true,
      acceptIcon: '',
      acceptText: 'OK',
      decline: false,
      declineIcon: '',
      declineText: 'Nein',
      id
    };
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(SessionNoteDialogComponent, this.dialogConfig);
  }

  deleteSession(id) {
      this.dialogConfig = new MatDialogConfig();
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = false;
      //this.dialogConfig.width = '400px';
      this.dialogConfig.data = {
        title: 'Achtung',
        note: 'Soll die Session wirklich gelöscht werden?',
        accept: true,
        acceptIcon: '',
        acceptText: 'Ja',
        decline: true,
        declineIcon: '',
        declineText: 'Nein'
      };
      this.dialog.closeAll();
      let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
      dialogRef.afterClosed().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(result => {
        if (result == 'ok') {
          this.sessionService.deleteSession({
              _id: id,
              sessionStatus: 'DELETED'
          });
        }
      });
  }

  increaseReadingPoints(element) {
    element.clicked = true;
    let userId = element.student._id;
    this.userService.getUserbyId(userId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
        //  Get current rading points
        let currentReadingPoints = res["readingPoints"] ? res["readingPoints"] : 0;
        // increase readingPoints
        currentReadingPoints++;
        // Save to server
        this.userService.updateUser({
          _id : userId,
          readingPoints : currentReadingPoints
        }).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(res => {
          //console.log('new points earned')
          this.snackBar.open("Der Punktestand wurde um +1 erhöht.", "",{
						duration: 3000,
						horizontalPosition: 'right'
          });
          this.readingPointsIncreased++;
        });
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}

