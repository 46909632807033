import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SchoolService } from '../../providers/school.service';
import { AuthService } from "../../auth/auth.service";

export interface School {

}

@Component({
  selector: 'app-school-list',
  templateUrl: './school-list.component.html',
  styleUrls: ['./school-list.component.scss'],
})
export class SchoolListComponent implements OnInit {
  displayedColumns: string[] = ['name', "plz", "city", 'buttons'];
  dataSource:any;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isAdmin = false;
  userType;

  school: School[] = [];
  private schoolSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
     private schoolService : SchoolService,
     public authService: AuthService
  ) { }

  ngOnInit() {
    this.userType = this.authService.getType();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    this.schoolService.getSchoolList();
    this.schoolSub = this.schoolService.getSchoolUpdateListener().pipe(
     takeUntil(this._unsubscribeAll)
   ).subscribe((school: School[]) => {
       this.school = school;
       this.dataSource = new MatTableDataSource<School>(this.school);
       // pagination and sort table
       this.dataSource.sort = this.sort;
       this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
       this.dataSource.paginator = this.paginator;
     });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
