import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


export interface Book {
  _id?: string;
  name: string;
  filename: string;
  size: string;
  originalname: string;
  path: string;
}


export interface Books {
  files: Book[];
}

@Injectable({
  providedIn: 'root'
})

export class EbooksEpubService {
  private restServerUrl;
  books: Book[] = [];
  book: Book;
  //bookUpdated = new Subject<Book>();
  booksUpdated = new Subject<Book[]>();
  onBookChanged: BehaviorSubject<any>;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
      private http: HttpClient,
    ) { 
      let protocol = environment.ssl ? 'https://' : 'http://';
      this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
    }

  
  createBook(element, bookPicture) {
    const formData = new FormData();
    console.log(element)
          formData.append('bookname', element.name);
          formData.append('originalname', element.originalname);
          formData.append('book', element.file, element.originalname);
          formData.append('bookPicture', bookPicture, bookPicture.name);
          formData.append('recommendation', element.recommendation);

    return this.http.post<any>(this.restServerUrl + "/api/book/upload", formData, {
      reportProgress: true,
      observe: 'events'
      })
      .pipe(map((event) => {

        switch (event.type) {

          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            return { status: 'finished', data: event.body };
          default:
            return { status: 'unhandled', type: event.type };
        }
      })
    );
  }

  getBooksUpdateListener() {
		return this.booksUpdated.asObservable();
	}

  /**
   * get list of all books
   */
	getBooksList() {
		this.http.get<Book[]>(this.restServerUrl + "/api/book/list",)
			.pipe(map(data => data), takeUntil(this._unsubscribeAll))
			.subscribe(data => {
                this.books = data;
                console.log(this.books)
                this.booksUpdated.next([...this.books]);
                // this.onBookChanged.next(this.books);
			});
  }

    /**
   * edit book data
   */
  updateBook(data)  {
    console.log(data)
    return this.http.put(
      this.restServerUrl + '/api/book/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(res => {
          return <Book>res;
        }));
  }


  /**
   * get single book by id
   */
	getBookById(id: string) {
    return this.http.get<Book[]>(this.restServerUrl + '/api/book/' + id)
    .pipe(map(data => data));
  }

  /**
   * get single book
   */
	getBook(filename: string) {
    return this.restServerUrl + "/api/book/download/" + filename;
		// this.http.get<Book>(this.restServerUrl + "/api/book/download/" + id)
		// 	.pipe(map(data => data))
		// 	.subscribe(data => {
    //             this.book = data;
    //             console.log(this.book)
    //             //this.bookUpdated.next([...this.book]);
    //             // this.onBookChanged.next(this.book);
		// 	});
  }

  /**
   * get book image
   */
	getBookimage(filename: string) {
    return this.restServerUrl + "/api/book/download-image/" + filename;
  }
  
  // delete book from DB (API Call)
  deleteBook(id: string)  {
    this.http.delete(this.restServerUrl + '/api/book/' + id).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      // Update local list
      this.books.splice(this.books.findIndex((item) => {
          return item._id === id;
      }), 1);
        this.booksUpdated.next([...this.books]);
      }, (error) => {
        console.error("Error deleting books: ", error);
    });
  }
    
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
