<div class="record-timer-bar">
    <div class="progressbar-0-indicator">
      <span>0</span>
    </div>
    <div class="progressbar-25-indicator"></div>
    <div class="progressbar-50-indicator">
      <span *ngIf="time > 0">{{ time | timeformat:'min' }}</span>
    </div>
    <div class="progressbar-75-indicator"></div>
    <div class="progressbar-100-indicator">
      <span>{{ session }}</span>
    </div>
    <mat-progress-bar mode="determinate" [value]="display"></mat-progress-bar>
</div>