import { Component, OnInit } from '@angular/core';
import { BookListComponent } from '../../books/book-list/book-list.component';
import { MatDialog } from '@angular/material/dialog';
import { EbooksEpubService } from "../../providers/epub.service";
import { AuthService } from '../../auth/auth.service';
import { QuizService } from '../../providers/quiz.service';
import { UserService } from "../../providers/user.service";
import { Router, ActivatedRoute } from '@angular/router';
//import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { FormBuilder, FormArray, FormGroup, FormGroupDirective, AbstractControl, Validators } from "@angular/forms";
import { MatSnackBar } from '@angular/material/snack-bar';


export class Quiz {
  question: string;
  textPassage: any;
  page: number;
  book: string;
  answers: any;
  difficultyLevel: any;
}

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss'],
})
export class CreateQuestionComponent implements OnInit {
  IS_APP = environment.isApp;
  book;
  bookUrl = '';
  bookId;
  bookActivePage = 1;
  bookStartPage = 1;
  readingModeIsPDF: boolean = false;
  readingModeIsEpub: boolean = false;
  userId;
  studentBooks: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  rForm: FormGroup;
  highlightText = [];
  highlightTemp = '';
  showAddAnswers = true;
  rightAnswer: boolean = false;
  selectedText = "";
  selectedTyp;
  isHidden: boolean = false;
  isDisabled: boolean = true;
  selectedType = 'yes-no';
  isHighlighted: boolean = false; 

  constructor(
    public dialog: MatDialog,
    public ebookEpubService: EbooksEpubService,
    private authService : AuthService,
    private quizService : QuizService,
    public userService: UserService,
    private insomnia: Insomnia,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private router: Router
  ) { }

  showSelectedText(id) {
    var text = "";
    if (window.getSelection()["extentOffset"] > 2 && window.getSelection()["type"] == "Range" ) {
      if (window.getSelection) {
        text = window.getSelection().toString();
        this.highlightTemp = text;
      }
    } else {
      this.highlightTemp = '';
    }
    console.log(window.getSelection())
  }

  addTextSelectionToQuestion() {
    if (this.highlightTemp != '') {
      this.highlightText.push({
        text: this.highlightTemp,
        page: this.bookActivePage
      });
      this.isHighlighted = false;
      console.log(this.highlightText)
      // Reset highlighted temp text
      this.highlightTemp = '';
    }
  }

  removeHighlightedText(i) {
    this.highlightText.splice(i, 1);
    if ( this.highlightText.length < 1)   {
      this.isHighlighted = true;
    } else {
      this.isHighlighted = false;
    }
  }

  getStringFromArray() {
    let text = '';
    this.highlightText.forEach((el, i) => {
      text += el + '\n\n';
    });
    return text;
  }

  ngOnInit() {
    this.bookId = this.route.snapshot.params['id'];
    this.bookUrl = '';
    this.userId = this.authService.getCurrentID();

    this.ebookEpubService.getBookById(this.bookId).pipe(
            takeUntil(this._unsubscribeAll)
          ).subscribe(data => {
            this.book = data;
            this.startReadingMode();
            console.log(this.book)
          });
          
    this.initForm();
    // Prevent screen from sleeping
    if (this.IS_APP) {
      this.insomnia.keepAwake();
    }
  }

  initForm() {
    this.rForm = this._formBuilder.group({
      question     : ['', Validators.required],
      answers: this._formBuilder.array([
        this.newAnswer("Ja",true),
        this.newAnswer("Nein",false),
      ]),
      textPassage : '',
      difficultyLevel: ["1"],
    });
  }

  /**
   * new answer formgroup
   */   
  newAnswer(answerText, checked): FormGroup {
    return this._formBuilder.group({
      answerText: answerText,
      rightAnswer: checked
    })
  }
  
  /**
   * add answer to answers array
   */   
  addAnswer() {
    const currentAnswers = this.rForm.get("answers") as FormArray;
          currentAnswers.push(this.newAnswer("",false));
          if (this.rForm.value.answers.length > 3) {
            this.showAddAnswers = false; 
          }
  }

  /**
   * remove answer from answers array
   */  
  removeAnswer(i:number) {
    const currentAnswers = this.rForm.get("answers") as FormArray;
          currentAnswers.removeAt(i);
          if (this.rForm.value.answers.length < 4) {
                  this.showAddAnswers = true; 
          }
  }

  quizType(event) {
    switch (event.value) {
      case 'yes-no':
        this.rForm.get('answers').enable();
        if (this.rForm.value.answers.length > 2) {
          this.removeAnswer(2);
        }
        this.initForm();
        // this.rForm.get('answers').disable();
        console.log(this.rForm.value.answers)
        this.isHidden = false;
        this.isDisabled = true;
        break;

      case '1of3':
        this.rForm.get('answers').enable();
        //this.rForm.get('answers').reset();
        const answerFormsArray = (<FormArray> this.rForm.get('answers')).controls;
              answerFormsArray.forEach((el, i) => {
                el.patchValue({
                  answerText: '',
                  rightAnswer: i == 0 ? true : false
                })
              });
        if (this.rForm.value.answers.length < 3) {
          this.addAnswer();
        }
        this.isHidden = false;
        this.isDisabled = false;
        break;

      case 'freetext':
          this.rForm.get('answers').disable();
          this.isHidden = true;
          this.isDisabled = false;
        break;
      
      default:
        break;
    }
  }

  /**
   * slide toggle change action to set values
   */
  onChange(event, index) {
    const currentAnswers = this.rForm.get("answers") as FormArray;

    if (event.checked) {
      // If changed value is true set all others to false
      currentAnswers.value.forEach((el, i) => {
        currentAnswers.at(i).patchValue({ rightAnswer : i != index ? false : true });
      });
    } else {
      // If changed value is false set first value to true
      let trueIndex = 0;
      // If first element is set to false, chose second element as right answer
      if (index == 0 && currentAnswers.at(0).value.rightAnswer == false) {
        trueIndex = 1;
      }
      currentAnswers.value.forEach((el, i) => {
        currentAnswers.at(i).patchValue({ rightAnswer : i === trueIndex ? true : false });
      });
    }
  }

  /**
   * get formcontrol for html loop
   */
  getAnswersFormControls(): AbstractControl[] {
    return (<FormArray> this.rForm.get('answers')).controls
  }

  /**
   * open book list modal
   */
  openDialog(): void {
    const dialogRef = this.dialog.open(BookListComponent, {
      width: '100%',
      autoFocus: false
      // data: {id: this._id, url: this.url}
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(book => {
      // Set current active page before switching to new book
      this.setActivePageOnBook(book);
    });
  }

  startReadingMode() {
      // Check for active page to continue reading
      this.bookActivePage = this.getActivePageFromStoredBook(this.bookId);
      this.bookUrl = this.ebookEpubService.getBook(this.book.filename);
      this.setReaderByType();
  }

  setReaderByType() {
    if (this.book.format == 'pdf') {
      this.readingModeIsPDF = true;
      this.readingModeIsEpub = false; 
    } else {
      this.readingModeIsPDF = false;
      this.readingModeIsEpub = true; 
    }
  }

  setActivePageOnBook(book?) {
      if (this.book != undefined) {
        // Find current book
        var foundBook = this.studentBooks[this.studentBooks.findIndex((item) => {
          return item.book === this.bookId;
        })];
        // Change active page of current book
        if (foundBook) {
          foundBook.activePage = this.bookActivePage;
          foundBook.lastRead = new Date().toISOString();
        } else {
          this.studentBooks.push({
            book: this.bookId,
            activePage: this.bookActivePage,
            lastRead: new Date().toISOString()
          });
        }
      }
      // Set new book
      if (book) {
        this.book = book;
          this.startReadingMode();
      }
  }

  getActivePageFromStoredBook(id) {
      // Find current book
      var foundBook = this.studentBooks[this.studentBooks.findIndex((item) => {
        return item.book === id;
      })];
      if (foundBook) {
        return foundBook.activePage;
      } else {
        return 1;
      }
  }

  getActivePage(page) {
    this.bookActivePage = page;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      //console.log('reading unsubscribe -------------')
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }, 500);
    // Allow sleep mode again
    //if (this.IS_APP) {
      this.insomnia.allowSleepAgain();
    //}
  }
  

  submitForm(formDirective: FormGroupDirective) {
    if (this.highlightText.length < 1) {
      this.isHighlighted = true;
      return;
    }
    if (this.rForm.invalid )   {
      this.rForm.get("question").markAsTouched();
      this.rForm.markAllAsTouched();
      return;
    }
    this.isHighlighted = false;
    const quiz: Quiz = new Quiz();
    quiz.question = this.rForm.value.question;
    quiz.textPassage = this.highlightText;
    quiz.page = Math.max.apply(Math, this.highlightText.map(function(o) { return o.page; })).toString();
    quiz.book = this.bookId;
    quiz.answers = this.rForm.value.answers;
    quiz.difficultyLevel =this.rForm.value.difficultyLevel

    this.quizService.createQuestionForQuiz(quiz).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe((res:any) => {
        this.snackBar.open("Frage wurde erfolgreich angelegt", "",{
          duration: 3000,
          horizontalPosition: 'right'
        });
        formDirective.resetForm();
        this.rForm.reset();
        this.router.navigate(['/list-question/' + this.bookId]);
      });
  }
}
