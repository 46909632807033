import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IframeComponent } from '../../helpers/iframe/iframe.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private restServerUrl;
  loginForm: FormGroup;
  CLIENT_ID = environment.clientId;
  clientLogo = environment.clientLogo;
  appSettings;
  showInstructionVideos: boolean = false;
  private appSettingsSubs: Subscription;
  private studentsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  fileUrl: string = '/files/instruction-videos/'

  constructor(
    private router: Router,
    private authService : AuthService,
    public dialog: MatDialog,
  ) {
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
   }

  ngOnInit() {
    if (this.CLIENT_ID == 'app.mentor.lese') {
      this.showInstructionVideos = true;
    }
    if (this.authService.getIsAuth()) {
      this.router.navigate(['dashboard']);
    } else {
      // Get app settings
      this.appSettings = this.authService.getAppSettings();
      this.appSettingsSubs = this.authService.getAppSettingsListener()
        .subscribe(appSettings => {
          this.appSettings = appSettings;
      });
    }
  }

    /**
   * open overlay for scietific survey or speedtest
   */  
  openIframeAsOverlay(url) {
    url = this.restServerUrl + url
    let params = false;
    const dialogRef = this.dialog.open(IframeComponent, {
      width: '100%',
      height: '80%',
      autoFocus: false,
      panelClass: 'iframe-overlay-pane',
      data: {
        url: url,
        // mentorId: this.userId,
        params: params
      }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

  /**
   *  login
   */
  login(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.authService.login(form.value.username, form.value.password, form.value.rememberMe);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
