<div class="contact-person container-fluid pt-3">
  <div class="contact-person_wrap row justify-content-center">
    <div class="col-md-6">
      <h1 class="align-center">Ansprechpartner</h1>
      <div *ngIf="appSettings?.showContactMentor">
        <h3 *ngIf="appSettings?.mentorContactIntro">{{ appSettings?.mentorContactIntro }}</h3>
        <h3 class="contact-info">
          <strong>Mentor*in:</strong> {{ mentor?.firstname }} {{ mentor?.lastname }}<br>
          <strong>Telefon:</strong> {{ mentor?.phoneNumber }}<br>
          <span *ngIf="mentor?.mobileNumber"><strong>Mobil:</strong> {{ mentor?.mobileNumber }}<br></span>
          <strong>E-Mail:</strong> {{ mentor?.email }}
        </h3>
        <br>
        <br>
      </div>
      <div *ngIf="appSettings?.showContactCoordinator">
        <h3 *ngIf="appSettings?.coordinatorContactIntro">{{ appSettings?.coordinatorContactIntro }}</h3>
        <h3 class="contact-info">
          <strong>Koordinator*in:</strong> {{ coordinator?.firstname }} {{ coordinator?.lastname }}<br>
          <strong>Telefon:</strong> {{ coordinator?.phoneNumber }}<br>
          <span *ngIf="coordinator?.mobileNumber"><strong>Mobil:</strong> {{ coordinator?.mobileNumber }}<br></span>
          <strong>E-Mail:</strong> {{ coordinator?.email }}
        </h3>
      </div>
    </div>
  </div>       
</div>