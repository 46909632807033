import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MaterialModule } from '../material.module';
import { RecordRTCComponent } from './record-rtc.component';
import { LoadingSpinnerModule } from '../helpers/loading-spinner/loading-spinner.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MaterialModule,
    LoadingSpinnerModule
  ],
  declarations: [RecordRTCComponent],
  exports: [RecordRTCComponent]
})
export class RecordRTCModule {}
