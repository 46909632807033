import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate',
  pure: false
})
export class FormatDatePipe implements PipeTransform {
  transform(t: number, hideTime?: boolean): string {
    const date = new Date(t);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let formattedDate = '';

    if (day < 10) {
      formattedDate += '0' + day;
    } else {
      formattedDate += '' + day;
    }
    formattedDate += '.';
    if (month < 10) {
      formattedDate += '0' + month;
    } else {
      formattedDate += '' + month;
    }

    formattedDate += '.' + year;

    const time = date.toLocaleTimeString().split(':');
    const formattedTime = time[0] + ':' + time[1];
    let output = formattedDate;
    if (!hideTime) {
      output = output + ' - ' + formattedTime;
    }
    return output;
  }
}