import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import moment from 'moment';

export interface DialogData {
  mentor: any;
  student: any;
}

@Component({
  selector: 'app-mentor-add-student',
  templateUrl: './mentor-add-student.component.html',
  styleUrls: ['./mentor-add-student.component.scss'],
})
export class MentorAddStudentComponent implements OnInit {
  mentor;
  student;
  defaultTime: any;
  rForm: FormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    private route:ActivatedRoute,
    public dialogRef: MatDialogRef<MentorAddStudentComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: DialogData,
    private router: Router,
  ) {
    if (injectedData) {
      if (typeof(injectedData['mentor'])!=="undefined") {
        this.mentor = injectedData['mentor'];
      }
      if (typeof(injectedData['student'])!=="undefined") {
        this.student = injectedData['student'];
      }
    }
  }

  ngOnInit() {
    // Set seconds to zero on timepicker
    var d = new Date(moment().format("YYYY-MM-DDTHH:mm:00"));
    this.defaultTime = [d.getHours(), 0 , 0];
    console.log(this.student._id)
    console.log(this.mentor._id)

    this.initForm();
  }

  initForm() {
    this.rForm = new FormGroup({
      //nextMeeting: new FormControl(null)
    });
  }

  cancel() {
    this.dialogRef.close('decline');
  }

  // submit form with data and navigate to dashboard
	submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    //let nextMeeting = this.rForm.value.nextMeeting ? new Date(moment(this.rForm.value.nextMeeting).format("YYYY-MM-DDTHH:mm:00")) : null;
    this.userService.addSingleStudentToMentor(this.mentor._id, this.student._id/*, nextMeeting*/).pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
        if (this.router.url.includes('/mentor-matching')) { 
          this.router.navigate(['/mentor-list']);
        } else {
          this.router.navigate(['/student-list']);
        }
        this.dialogRef.close('ok');
    });
    
  }

}
