<div class="session container-fluid pt-3">
  <div class="session_wrap row">
    <div class="col-md-12" *ngIf="isMentor || isAdmin">
      <h2 class="align-center" *ngIf="studentId">Vergangene Lesesitzungen<span *ngIf="studentId && isMentor"> von {{ activeStudentName }}</span></h2>

      <!-- <h2 class="align-center">
        Vergangene Lesesitzungen
        <span *ngIf="studentId && isMentor">
          <span class="heading_name_wrap">
            <span *ngIf="user['firstname']">{{ user["firstname"] }}</span> <span *ngIf="user['lastname']">{{ user["lastname"] }}</span>
            <span *ngIf="!user['firstname'] && !user['lastname']">{{ user["username"] }}</span>
          </span>
        </span>
      </h2> -->

      <div class="table-navigation"  *ngIf="showFilter">
        <div class="filter-area">
          <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Modus, Werk" #input>
          </mat-form-field>
        </div>
      </div>

      <mat-table [dataSource]="dataSource" class="mat-elevation-z1" matSort matSortActive="user" matSortDisableClear matSortDirection="desc">

        <ng-container matColumnDef="sessionReadTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Lesezeit</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.sessionReadTime | timeformat:'min' }} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sessionGoalTime">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Ziel-Lesezeit</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.sessionGoalTime | timeformat:'min' }} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="readBooks">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Werk</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container *ngFor="let element of row.readBooks; index as i">
                <span *ngIf="element.book">
                  {{i+1}}. {{ element.book.name }}
                  (Seite <span *ngIf="element.startPage">{{ element.startPage }}</span>
                  <span *ngIf="element.activePage > element.startPage">-{{ element.activePage }}</span>)
                  <br />
                </span>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="readBooksPages">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Seiten</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container *ngFor="let element of row.readBooks;">
                <span *ngIf="element.startPage">
                  {{ element.startPage }}
                </span>
                <span *ngIf="element.activePage > element.startPage">
                  -{{ element.activePage }}
                </span>
            </ng-container>
        </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="readingMode">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Modus</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.readingMode }} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="student" *ngIf="isAdmin || isCoordinator">
          <mat-header-cell *matHeaderCellDef>Lesekind</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element?.student?.firstname }} {{ element?.student?.lastname }} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Gelesen am</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.created | formatDate }} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="filename">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Audiodatei</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <!-- {{ element.filename}} -->
            <audio controls controlsList="nodownload" preload="none">
              <source src="{{getAudio( element.filename)}}" type="audio/wav">
            </audio>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" >
              <div>
                  <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More">
                      <mat-icon class="secondary-text">more_vert</mat-icon>
                  </button>

                  <mat-menu #moreMenu="matMenu">
                      <button mat-menu-item aria-label="add-note" (click)="addNoteToSession(element._id)">
                          <mat-icon>note_add</mat-icon>
                          <span>Notiz hinzufügen</span>
                      </button>
                      <button mat-menu-item aria-label="add_reaction" [disabled]="element.clicked" (click)="increaseReadingPoints(element)" *ngIf="element.readingMode == 'Solo' && element.student != null">
                        <mat-icon>add_reaction</mat-icon>
                        <span>Lesepunkte vergeben</span>
                      </button>
                      <button mat-menu-item aria-label="remove" (click)="deleteSession(element._id)">
                          <mat-icon>delete</mat-icon>
                          <span>Löschen</span>
                      </button>
                  </mat-menu>
              </div>
          </mat-cell>
      </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="pageSize"></mat-paginator>
    </div>
    <div class="col-md-12" *ngIf="isMentor">
      <app-analytics [readingPointsIncreased]="readingPointsIncreased" *ngIf="studentId"></app-analytics>
    </div>
  </div>
</div>