import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SplashScreenComponent } from './splash-screen.component';

@NgModule({
  declarations: [
    SplashScreenComponent
  ],
  entryComponents: [
    SplashScreenComponent
  ],
  imports: [
    BrowserModule
  ],
  schemas: [],
  bootstrap: [
  ],
  exports: [SplashScreenComponent]
})
export class SplashScreenModule {}
