import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';
export interface User {
  username?: string;
	email?: string;
	firstname?: string;
	lastname?: string;
	role?: string;
	schoolId?: string;
	coordinatorId?: string;
	phoneNumber?: string;
	mobileNumber?: string;
	birthday?: Date;
	availability?: string;
	supervisingSchools?: string[];
	readingAbilityRate? : string;
	schoolClass?: string;
	comment?: string;
	preferredTypeOfSchool?: string;
	loanedDeviceSerial?: string;
  students?: string[];
}

import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
export interface DialogData {
  id: string;
}

@Component({
  selector: 'app-mentor-view',
  templateUrl: './mentor-view.component.html',
  styleUrls: ['./mentor-view.component.scss'],
})
export class MentorViewComponent implements OnInit {
  id;
  created;
  mentor: User;
  username;
  firstname;
  lastname;
  email;
  phoneNumber;
  mobileNumber;
  availability;
  birthday;
  comment;
  studentsCapacity;
  preferredTypeOfSchool;
  isAvailability: boolean = false;
  activeMembershipText: string = "";
  scientificSurveyText: string = "";

  constructor(
    private userService : UserService,
    private route:ActivatedRoute,
    public dialogRef: MatDialogRef<MentorViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
  ) { }

  ngOnInit() {
    this.id = this.data.id;
    this.userService.getUserbyId(this.id).subscribe(res => {
      this.mentor = <User>res;
      console.log(res)
      this.username = res["username"];
      this.firstname = res["firstname"];
      this.lastname = res["lastname"];
      this.email = res["email"];
      this.phoneNumber = res["phoneNumber"];
      this.mobileNumber = res["mobileNumber"];
      this.availability = res["availability"];
      this.birthday = res["birthday"];
      this.created = res["created"];
      this.comment =  res["comment"];
      this.studentsCapacity = res["studentsCapacity"];
      this.preferredTypeOfSchool = res["preferredTypeOfSchool"]

      if (res["activeMembership"] === true) {
        this.activeMembershipText = "Ja"
      } else{
        this.activeMembershipText = "Nein"
      }

      if (res["scientificSurvey"] === true) {
        this.scientificSurveyText = "Ja"
      } else{
        this.scientificSurveyText = "Nein"
      }

    });

    if (this.router.url.includes('/mentor-matching') && this.router.url.includes('/student-matching') ) { 
      this.isAvailability = true;
    } else {
      this.isAvailability = false;
    }
  }

}
