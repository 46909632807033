import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoadingSpinnerComponent } from './loading-spinner.component';


@NgModule({
  declarations: [
    LoadingSpinnerComponent
  ],
  entryComponents: [
    LoadingSpinnerComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  schemas: [ ],
  bootstrap: [],
  exports: [LoadingSpinnerComponent]
})
export class LoadingSpinnerModule {}
