import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MatDialog,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizService } from '../providers/quiz.service';


export interface DialogData {
  startPage: number;
  activePage: number;
  bookId: string;
  bookName: string;
}
export interface Quiz {
  id: number;
  question: string;
  answers: Option[];
  answer: number;
  selected: number;
}

export interface Option {
  optionid: number;
  answerText: string;
  rightAnswer: boolean
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  quizCheck: boolean = false;
  currentIndex:number;
  correct:any;
  currentQuizSet:any;
  start=false;
  quizFinished=false;
  bookName;
  buttonname = "Quiz starten";
  endQuiz = false;
  quiz: Quiz[];

  constructor(
    private matDialog: MatDialog,
    private quizService: QuizService,
    @Inject(MAT_DIALOG_DATA) public injectedData: DialogData
    ){

    }
  
  ngOnInit() {
    this.bookName = this.injectedData.bookName
    console.log(this.injectedData)
    this.quizService.generateQuiz({
      bookId: this.injectedData.bookId,
      startPage: this.injectedData.startPage,
      activePage: this.injectedData.activePage
    }).subscribe(res => {
      this.quizCheck = true;
      this.quiz = <Quiz[]>res;
      this.currentIndex = 0;
      this.currentQuizSet = this.quiz[this.currentIndex];
    });
  }

  next() {
    this.currentIndex = this.currentIndex + 1;
    this.currentQuizSet = this.quiz[this.currentIndex];
    if (this.currentIndex+1 == this.quiz.length) {
      this.endQuiz = true;
    }
  }

  submit() {
    this.buttonname = "Quiz wiederholen";
    this.endQuiz = false;
    if (this.currentIndex + 1 == this.quiz.length) {
      this.quizFinished = true;
      this.start = false;
      this.correct = 0;

      // Generate quiz result
      this.quiz.map(x => {
        let result = x.answers[x.answers.findIndex((item) => {
          return item['_id'] === x.selected;
        })];
        if (result && result.rightAnswer === true) {
          this.correct = this.correct + 1;
        }
      });
    }
  }

  closeQuiz(){
    this.matDialog.closeAll();
  }

  startQuiz() {
    this.quizFinished = false;
    this.currentIndex = 0;
    this.currentQuizSet = this.quiz[this.currentIndex];
    this.start = true;
    if (this.currentIndex+1 == this.quiz.length) {
      this.endQuiz = true;
    }
  }
}
