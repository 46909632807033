import { Component, OnInit, Input } from '@angular/core';
import { WebSocketService } from '../providers/websocket.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../providers/user.service';
import { SessionService } from '../providers/session.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface User {}
@Component({
  selector: 'app-record-timer',
  templateUrl: './record-timer.component.html',
  styleUrls: ['./record-timer.component.scss'],
})
export class RecordTimerComponent implements OnInit {

  // https://stackoverflow.com/questions/58914756/angular-timer-to-count-the-time
  time: number = 0;
  display ;
  interval;
  goal = 0;
  session: number = 20;
  isPaused: boolean = true;
  metamorphoseDuration;
  metamorphosePlayback: boolean = true;
  currentReadingPoints: number;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  @Input('userType') isMentor;
  @Input('partnerId') partnerId: string;
  @Input('readingMode') readingMode: string;

  constructor(
    private webSocketService: WebSocketService,
    private authService : AuthService,
    private userService : UserService,
    private sessionService : SessionService
  ) { }

  ngOnInit() {
    this.userService.getUserbyId(this.partnerId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      //  Minutes to read from Mentor
      this.metamorphoseDuration = res["readingTime"];
      // Reading points for tandem
      this.currentReadingPoints = res["readingPoints"] ? res["readingPoints"] : 0;
      if (this.metamorphoseDuration) {
        this.session = this.metamorphoseDuration;
      }
      this.goal = this.session*60; // Goal in seconds
    });
    this.webSocketService.listen('start-timer').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.toggleTimer(data));
  }

    /**
   * resetTimer
   */
  resetTimer() {
    this.display = 0;
    this.time = 0;
  }

  /**
   * startTimer/ set interval
   */
  startTimer() {
    this.resetTimer();
    this.isPaused = false;
    console.log("=====>");
    this.interval = setInterval(() => {
      if(!this.isPaused) {
        this.time++;
        //this.display = this.transform(this.time);
        this.display = (this.time/this.goal)*100;
      }
      switch(this.display) { 
          case 25:
          case 50:
          case 75: {
              this.resumeMetamorphose();
              break; 
          }
          case 100: { 
              this.resumeMetamorphose();
              this.metamorphosePlayback = false;
              
              //this.stopTimer();
              // increase readingPoints
              if (this.readingMode == 'Tandem') {
                this.currentReadingPoints++;
                // Save to server
                this.userService.updateUser({
                  _id : this.partnerId,
                  readingPoints : this.currentReadingPoints
                }).pipe(
                  takeUntil(this._unsubscribeAll)
                ).subscribe(res => {
                  console.log('new points earned')
                });
              }
              break; 
          }  
          default: { 
              break; 
          } 
      }
    }, 1000);
  }

  /**
   * resume Metamorphose
   */
  resumeMetamorphose() {
    if (this.metamorphosePlayback) {
      if (this.readingMode == 'Solo') {
        this.webSocketService.emit('metamorphose-play-solo', '');
      } else {
        // only student metamorphose (change on other side)
        //this.webSocketService.emitTo('metamorphose-play', this.webSocketService.getCurrentRoom(), '');
        // change my own (mentor and student has metamorphose video)
        this.webSocketService.emit('metamorphose-play-solo', '');
      }
    }
  }

  /**
   * reset Metamorphose
   */
  // resetMetamorphose() {
  //   if (this.isMentor && !this.metamorphosePlayback) {
  //     this.webSocketService.emitTo('metamorphose-reset', this.webSocketService.getCurrentRoom(), '');
  //   } else if (this.metamorphosePlayback) {
  //     if (this.readingMode == 'Solo') {
  //       this.webSocketService.emit('metamorphose-reset-solo', '');
  //     }
  //   }
  // }

  /**
   * transform/ time of the recorder
   */
  transform(value: number): string {
       const minutes: number = Math.floor(value / 60);
       return minutes + ':' + (value - minutes * 60);
  }

  /**
   * toggleTimer / start, pause, resume, stop
   */
  toggleTimer(status) {
    switch(status) { 
        case 'start': {
            this.startTimer();
            break; 
        }
        case 'pause': { 
            this.isPaused = true;
            break; 
        }  
        case 'resume': { 
            this.isPaused = false;
            break; 
        }
        case 'stop': { 
            this.stopTimer();
            break; 
        }  
        default: { 
            break; 
        } 
    }
  }

  /**
   * stopTimer
   */
  stopTimer() {
    this.sessionService.setSessionTimer(this.time, this.goal);
    this.isPaused = true;
    clearInterval(this.interval);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      //console.log('recorder unsubscribe -------------')
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }, 100);
  }
  
}