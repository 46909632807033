import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../providers/school.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";

export interface School {}

@Component({
  selector: 'app-edit-school',
  templateUrl: './edit-school.component.html',
  styleUrls: ['./edit-school.component.scss'],
})
export class EditSchoolComponent implements OnInit {
  id;
  school: School[] = [];
  rForm: FormGroup;
  subscription: Subscription;

  constructor(
    private schoolService : SchoolService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.schoolService.getSchoolbyId(this.id).subscribe(res => {
      this.school = res;
      console.log(res)
    });

    this.initForm();
  }

  initForm(){
    this.rForm = new FormGroup({
      name: new FormControl(null, {
        validators: [Validators.required]
      }),
      streetNr: new FormControl(null, {
        validators: []
      }),
      plz: new FormControl(null, {
        validators: []
      }),
      city: new FormControl(null, {
        validators: []
      }),
      typeOfSchool: new FormControl(null, {
        validators: [Validators.required]
      }),
    })

    //fill input field with data from user object
    this.subscription = this.schoolService.getSchoolbyId(this.id).subscribe(school => {
      this.rForm.get('name').setValue(school['name']);
      this.rForm.get('streetNr').setValue(school['streetNr']);
      this.rForm.get('plz').setValue(school['plz']);
      this.rForm.get('city').setValue(school['city']);
      this.rForm.get('typeOfSchool').setValue(school['typeOfSchool']);
    })
  }

  submitForm(formDirective: FormGroupDirective) {
    this.schoolService.updateSchool({
      _id : this.id,
      name : this.rForm.value.name,
      streetNr : this.rForm.value.streetNr,
      plz : this.rForm.value.plz,
      city : this.rForm.value.city,
      typeOfSchool: this.rForm.value.typeOfSchool
    }).subscribe(res => {
      this.router.navigate(['/school-list']);
    })
  }

}
