import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from '../../../app-routing.module';
import { MaterialModule } from '../../../material.module';
import {MatDialogModule} from '@angular/material/dialog';
import { AuthInterceptor } from "../../../auth/auth-interceptor";
import { ErrorInterceptor } from "../../../error/error-interceptor";
import { MentorMappingComponent } from './mentor-mapping.component';
import { DragDropModule, CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    MentorMappingComponent
  ],
  entryComponents: [
    MentorMappingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    MatDialogModule,
    DragDropModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [
  ],
  exports: [MentorMappingComponent]
})
export class MentorMappingModule {}
