<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-8 col-sm-12">
        <h1 class="pt-3">Kind Profil</h1>
        <div class="register_wrap">
            <div class="row">
              <div class="col-md-6">
                  <div>Benutzername: {{username}}</div>
                  <div>Aktive Mitgliedschaft: {{activeMembershipText}}</div>
                  <div>Schule: {{schoolName}}</div>
                  <div>Besuchte Schulklasse: {{schoolClass}} </div>
                  <div>Einschätzung der Lesefähigkeit der Eltern: {{readingAbilityRate}}</div>
                  <div *ngIf="coordinator"><br>Koordinator*in: {{ coordinator?.firstname }} {{ coordinator?.lastname }}</div>
              </div>

              <div class="col-md-6">
                <div>Vorname: {{firstname}}</div>
                <div>Nachname: {{lastname}}</div>
                <div>Geschlecht: {{gender}}</div>
                <div>E-Mail: {{email}}</div>
                <div>Telefon Festnetz: {{phoneNumber}}</div>
                <div>Telefon Mobil: {{mobileNumber}}</div>
                <div>Geburtstag: {{birthday | date: 'dd.MM.yyyy' :'+0200':'de' }}</div>
              </div>
              <div class="col-md-12" *ngIf="comment">
                  Kommentar:<br>
                  {{comment}}
              </div>
            </div>
        </div>
    </div>
  </div>
</div>