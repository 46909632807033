import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource,} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from "../../../auth/auth.service";
import { MentorViewComponent } from '../../mentor/mentor-view/mentor-view.component';
import { MentorUpgradeComponent } from '../mentor-upgrade/mentor-upgrade.component';
import { ConfirmDialogComponent } from '../../../helpers/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export interface mentors {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-mentor-list',
  templateUrl: './mentor-list.component.html',
  styleUrls: ['./mentor-list.component.scss'],
})
export class MentorListComponent implements OnInit {
  displayedColumns: string[] = ['preview-button', 'username', 'firstname', 'lastname', 'phoneNumber', 'mobileNumber', 'created', 'buttons'];
  dataSource:any;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userType;
  dialogConfig: any;
  isAdmin: boolean = false;
  filterValues = {
    search: '',
    students: false
  };

  mentors: mentors[] = [];
  private mentorsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(   
    private userService : UserService,
    public dialog: MatDialog,
    public authService: AuthService
    ) {}

  ngOnInit() {
    this.userType = this.authService.getType();
    if (this.userType == 'admin') {
      this.isAdmin = true;
    }
    this.userService.getMentorList();
    this.mentorsSub = this.userService.getMentorsUpdateListener().pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe((mentors: mentors[]) => {
        this.mentors = mentors;
        console.log(mentors)
        this.dataSource = new MatTableDataSource<mentors>(this.mentors);
        // pagination and sort table
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (value, sortHeaderId) => value[sortHeaderId];
        this.dataSource.paginator = this.paginator;
        // Filter in nested array as well
        this.dataSource.filterPredicate = (data: any, filter) => {
          // Check if students array is empty
          let withoutStudentsFound = false;
          if (filter.students) {
            if (data['students'].length < 1) {
              withoutStudentsFound = true;
            }
          }
          // Find position for search word
          let positionFound = JSON.stringify(data).toLowerCase().indexOf(filter.search) != -1;
          if (filter.students) {
              return withoutStudentsFound && positionFound;
          } else {
              return positionFound;
          }
        };
        //check mentors without students
        // if (this.isAdmin) {
        //     this.studentsSelectionFilter({ checked: true });
        // }
    });
  }

  studentsSelectionFilter(el) {
    this.filterValues['students'] = el.checked;
    this.dataSource.filter = this.filterValues;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValues['search'] = filterValue.trim().toLowerCase()
    this.dataSource.filter = this.filterValues;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openMentorUpgrade(id): void {
      const dialogRef = this.dialog.open(MentorUpgradeComponent, {
        width: '100%',
        autoFocus: false,
        data: { id: id }
      });
      dialogRef.afterClosed().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
      });
  }

  deleteUser(id, firstname, lastname) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    //this.dialogConfig.width = '400px';
    this.dialogConfig.data = {
      title: 'Achtung',
      note: ' Wollen Sie' +"  " + firstname + " " + lastname + " " +'wirklich löschen?',
      accept: true,
      acceptIcon: '',
      acceptText: 'Ja',
      decline: true,
      declineIcon: '',
      declineText: 'Nein'
    };
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(result => {
      if (result == 'ok') {
        this.userService.deleteUserAndRemoveRelation(id).subscribe(res => {
            // Update local list
            this.mentors.splice(this.mentors.findIndex((item) => {
                return item._id === id;
            }), 1);
            // Refresh datasource
            this.dataSource._updateChangeSubscription();
         }, (error) => {
             console.error("Error deleting user: ", error);
         });
      }
    });
  }


  openMentorProfil(id): void {
    console.log(id)
    const dialogRef = this.dialog.open(MentorViewComponent, {
      width: '100%',
      autoFocus: false,
      data: { id: id }
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
    });
  }

}