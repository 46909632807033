import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../../providers/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss'],
})
export class GameListComponent implements OnInit {
  public metamorphose;
  public metamorphosePoints = 0;
  userId;
  selectedmetamorphose;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public elRef: ElementRef,
    private authService : AuthService,
    private userService : UserService
  ) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentID();
    this.selectedmetamorphose = this.authService.getActiveAvatar();
    this.userService.getUserbyId(this.userId).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        this.metamorphosePoints = res["readingPoints"] ? res["readingPoints"] : 0;
      });
    // this.metamorphose = this.elRef.nativeElement.querySelectorAll('.metamorphose-image');
    // this.metamorphose.forEach(function(item) {
    //   item.onloadeddata = function(event) {
    //     const {
    //       duration
    //     } = event.srcElement;
    //     this.currentTime = duration;
    //   };
    // });
  }

}
