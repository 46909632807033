<div class="container-fluid">
  <div class="login row">
    <div class="col-md-12">
      <div class="corner-info">
        <div *ngIf="appSettings?.technicalSupport" class="technical-support">
          <mat-icon class="material-icons">device_unknown</mat-icon> {{ appSettings?.technicalSupport }}
        </div>
      </div>
      <div class="button-group">
        <!-- <div class="login_mentor"><button mat-button color="accent" (click)="login(0)" class="size-big">Mentor</button></div>
        <div class="login_kind"><button mat-button color="accent" (click)="login(1)" class="size-big">Kind 1</button></div>
        <div class="login_kind"><button mat-button color="accent" (click)="login(2)" class="size-big">Kind 2</button></div>
        <div class="login_kind"><button mat-button color="accent" (click)="login(3)" class="size-big">Kind 3</button></div> -->
        <div class="login_wrap">
          <img src="assets/logos/{{clientLogo}}" class="dashboard-logo" />
          <form (submit)="login(loginForm)" #loginForm="ngForm">
              <mat-form-field>
                <input matInput name="username" ngModel type="username" placeholder="Benutzername" #usernameInput="ngModel" required>
                <mat-error *ngIf="usernameInput.invalid">Please enter a valid username.</mat-error> 
              </mat-form-field>
              <mat-form-field>
                <input type="password" name="password" ngModel matInput placeholder="Kennwort" #passwordInput="ngModel" required>
                <mat-error *ngIf="passwordInput.invalid">Please enter a valid password.</mat-error> 
              </mat-form-field>
              <div class="remember-forgot-password">
                <mat-checkbox class="remember-me" name="rememberMe" ngModel #rememberMeCheckbox="ngModel" aria-label="Remember me">
                  Logindaten speichern
                </mat-checkbox>
                <a class="reset-password_link" [routerLink]="['/reset-password']">Passwort zurücksetzen</a>
              </div>
              <div class="instructionVideo_wrap">
                <button mat-button color="accent" type="submit" class="size-big" aria-label="LOG IN">Einloggen</button>
                <button mat-button type="button" aria-label="Hilfevideo" *ngIf="showInstructionVideos" (click)="openIframeAsOverlay(fileUrl + 'anmeldung.mp4')" class="icon-with-text instructionVideo">
                  <p>Hilfevideo</p>
                  <mat-icon>videocam</mat-icon>
                </button>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>