<div class="session container-fluid pt-3">
  <div class="session_wrap row">
    <div class="col-md-12">

      <div class="table-navigation">
        <div class="filter-area">
          <div class="filter-wrap">
            <mat-form-field>
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Benutzername, Vorname, Nachname" #input>
            </mat-form-field>
  
            <mat-checkbox (change)="studentsSelectionFilter($event)" [checked]="filterValues.students" *ngIf="isAdmin">Mentoren ohne Lesekind</mat-checkbox>
          </div>
        </div>
        <div class="button-area" *ngIf="isAdmin">
          <a [routerLink]="['/mentor-create']">
            <mat-icon >person_add</mat-icon> MentorIn anlegen
          </a>
        </div>
      </div>

      <mat-table [dataSource]="dataSource" class="mat-elevation-z1" matSort matSortActive="username" matSortDisableClear matSortDirection="asc">
        <ng-container matColumnDef="preview-button">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon (click)="openMentorProfil(element._id)">preview</mat-icon>
          </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Benutzername</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.username}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstname">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Vorname</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <!-- <a href="#" [routerLink]="['/mentor-mapping/' + element._id]"> -->
                  {{ element.firstname}} 
                <!-- </a> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastname">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Nachname</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <!-- <a href="#" [routerLink]="['/mentor-mapping/' + element._id]"> -->
                  {{ element.lastname}} 
                <!-- </a> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Telefon Festnetz</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.phoneNumber}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mobileNumber">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Telefon Mobil</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.mobileNumber}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Erstellt</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.created | date:'dd.MM.yyyy' }} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" >
                <div>
                    <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More">
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>

                    <mat-menu #moreMenu="matMenu">
                        <button mat-menu-item aria-label="add-note" [routerLink]="['/mentor-edit/' + element._id]">
                            <mat-icon>create</mat-icon>
                            <span>Bearbeiten</span>
                        </button>
                        <button mat-menu-item aria-label="add-note" [routerLink]="['/mentor-matching/' + element._id]">
                            <mat-icon>link</mat-icon>
                            <span>Matching</span>
                        </button>
                        <button mat-menu-item aria-label="add-note" [routerLink]="['/mentor-mapping/' + element._id]">
                            <mat-icon>assignment</mat-icon>
                            <span>Matchings einsehen & aufheben</span>
                        </button>
                        <button mat-menu-item aria-label="upgrade" *ngIf="isAdmin" (click)="openMentorUpgrade(element._id)">
                            <mat-icon>trending_up</mat-icon>
                            <span>zu Koordinator heraufstufen</span>
                        </button>
                        <button mat-menu-item aria-label="password" [routerLink]="['/change-password/' + element._id]">
                            <mat-icon>lock</mat-icon>
                            <span>Kennwort ändern</span>
                        </button>
                        <button mat-menu-item aria-label="remove" *ngIf="isAdmin" (click)="deleteUser(element._id, element.firstname, element.lastname)">
                          <mat-icon>delete</mat-icon>
                          <span>Löschen</span>
                        </button>
                    </mat-menu>
                </div>
            </mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginator [pageSize]="10"></mat-paginator>
    </div>
  </div>
</div>