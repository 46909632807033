import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { FileTypeChecker } from './fileTypeChecker';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs'
import { QuizService } from '../providers/quiz.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export class Question {
  _id: string;
  file: File;
  name?: string;
  type?: string;
  size?: string;
  originalname?: string;
  path?: string;
}

@Component({
  selector: 'app-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.scss'],
})
export class CsvUploadComponent implements OnInit {
  question: any;
  rForm: FormGroup;
  csv: any;
  allowedFileTypes = '';
  @ViewChild('fileInputModel') fileInputModel;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private quizService: QuizService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    // this.allowedFileTypes = FileTypeChecker.getAllowedFiletypes();
    this.rForm = this._formBuilder.group({
      files  : ['', Validators.required],
    }); 
  }

  onFileChange(event) {
    console.log(event)
    if (event.target.files && event.target.files.length) {
      for (const file of event.target.files) {
        const fileEnding = file.name.split('.')[1];
        if (FileTypeChecker.isAcceptType(fileEnding)) {
          const question: Question = new Question();
          question._id = 'pre_' + new Date().getTime();
          question.file = file;
          question.name = file.name;
          question.originalname = file.name;
          question.size = file.size;
          // book.recommendation = file.recommendation;
          // this.rForm.get('files').setValue(file);
          console.log(file)
          console.log(question);
          // Set book file for later usage
          this.question = question;
        } else {
          console.log(file.name + ': ' + 'Wrong File Type! ');
        }
      }
    }
  }

  uploadFilesToServer(file) {
    if (file._id.startsWith('pre_')) {
        this.quizService.uploadCsv(file).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe((res:any) => {
          this.snackBar.open(res.length +" Fragen wurden erfolgreich hochladen", "",{
            duration: 3000,
            horizontalPosition: 'right'
          });
          console.log(res)
        });
    }
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      this.rForm.get("files").markAsTouched();
      return;
    }
    this.uploadFilesToServer(this.question);
    formDirective.resetForm();
    /**
     * Workaround, da clear-Methode des ngx-mat-file-input nicht geht
     */
    this.fileInputModel._elementRef.nativeElement.firstChild.value = '';
    this.fileInputModel.clear(event);
    this.rForm.reset();
  }

}
