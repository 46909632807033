import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { QuizService } from '../../providers/quiz.service';
import { FormBuilder, FormArray, FormGroup, FormGroupDirective, AbstractControl, Validators } from "@angular/forms";
import { EbooksEpubService } from "../../providers/epub.service";
import { UserService } from "../../providers/user.service";
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export class Quiz {
  question: string;
  textPassage: any;
  page: number;
  answers: any;
  difficultyLevel: string;
}

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss'],
})
export class EditQuestionComponent implements OnInit {
  rForm: FormGroup;
  subscription: Subscription;
  quizId;
  question: Quiz[] = [];
  book;

  bookUrl = '';
  bookId;
  bookActivePage = 1;
  bookStartPage = 1;
  readingModeIsPDF: boolean = false;
  readingModeIsEpub: boolean = false;
  userId;
  studentBooks: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  highlightText = [];
  highlightTemp = '';
  showAddAnswers = true;
  rightAnswer: boolean = false;
  isHighlighted: boolean = false; 

  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private quizService : QuizService,
    private _formBuilder: FormBuilder,
    public ebookEpubService: EbooksEpubService,
    public userService: UserService,
    public snackBar: MatSnackBar
  ) { }

  showSelectedText(id) {
    var text = "";
    if (window.getSelection()["extentOffset"] > 2 && window.getSelection()["type"] == "Range" ) {
      if (window.getSelection) {
        text = window.getSelection().toString();
        this.highlightTemp = text;
      }
    } else {
      this.highlightTemp = '';
    }
    console.log(window.getSelection())
  }

  addTextSelectionToQuestion() {
      if (this.highlightTemp != '') {
        this.highlightText.push({
          text: this.highlightTemp,
          page: this.bookActivePage
        });
        this.isHighlighted = false;
        console.log(this.highlightText)
        // Reset highlighted temp text
        this.highlightTemp = '';
      }
  }

  getStringFromArray() {
    let text = '';
    this.highlightText.forEach((el, i) => {
      text += el + '\n\n';
    });
    return text;
  }

  removeHighlightedText(i) {
    this.highlightText.splice(i, 1);
    if ( this.highlightText.length < 1)   {
      this.isHighlighted = true;
    } else {
      this.isHighlighted = false;
    }
  }
  

  ngOnInit() {
    this.quizId = this.route.snapshot.params['id'];
    this.initForm();
  }
  

  initForm(){
    this.rForm = this._formBuilder.group({
      question : [],
      answers : this._formBuilder.array([]),
      page : '',
      textPassage : '',
      difficultyLevel: ''
    });

    // Get data from api
    this.subscription = this.quizService.getQuestionbyId(this.quizId).subscribe(quiz => {
      console.log(quiz)
      this.rForm.get('question').setValue(quiz['question']);
      this.rForm.get('difficultyLevel').setValue(quiz['difficultyLevel']);
      this.rForm.get('page').setValue(quiz['page']);
      this.rForm.get('textPassage').setValue(quiz['textPassage']);
      // set answers in formsaraay
      const answerFormsArray = this.rForm.get('answers') as FormArray;
      quiz['answers'].forEach((el, i) => {
        answerFormsArray.push(this._formBuilder.group({
          answerText: el.answerText,
          rightAnswer: el.rightAnswer,
        }));
      });
      quiz['textPassage'].forEach((el, i) => {
        console.log(el)
        this.highlightText.push({
          text: el.text,
          page: el.page
        });
      });

      // Load book
      this.bookId = quiz["book"];
      this.bookActivePage = quiz["page"];
      this.ebookEpubService.getBookById(this.bookId).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(data => {
        this.book = data;
        this.startReadingMode();
      });
    })
  }

  /**
   * new answer formgroup
   */   
  newAnswer(checked): FormGroup {
    return this._formBuilder.group({
      answerText: '',
      rightAnswer: checked,
    })
  }
  
  /**
   * add answer to answers array
   */   
  addAnswer() {
    const currentAnswers = this.rForm.get("answers") as FormArray;
          currentAnswers.push(this.newAnswer(false));
          if (this.rForm.value.answers.length > 3) {
            this.showAddAnswers = false; 
          }
  }

  /**
   * remove answer from answers array
   */  
  removeAnswer(i:number) {
    const currentAnswers = this.rForm.get("answers") as FormArray;
          currentAnswers.removeAt(i);
          if (this.rForm.value.answers.length < 4) {
                  this.showAddAnswers = true; 
          }
  }

  /**
   * slide toggle change action to set values
   */
  onChange(event, index) {
    const currentAnswers = this.rForm.get("answers") as FormArray;

    if (event.checked) {
      // If changed value is true set all others to false
      currentAnswers.value.forEach((el, i) => {
        currentAnswers.at(i).patchValue({ rightAnswer : i != index ? false : true });
      });
    } else {
      // If changed value is false set first value to true
      let trueIndex = 0;
      // If first element is set to false, chose second element as right answer
      if (index == 0 && currentAnswers.at(0).value.rightAnswer == false) {
        trueIndex = 1;
      }
      currentAnswers.value.forEach((el, i) => {
        currentAnswers.at(i).patchValue({ rightAnswer : i === trueIndex ? true : false });
      });
    }
  }

  /**
   * get formcontrol for html loop
   */
  getAnswersFormControls(): AbstractControl[] {
    return (<FormArray> this.rForm.get('answers')).controls
  }

  startReadingMode() {
    this.bookUrl = this.ebookEpubService.getBook(this.book.filename);
    this.setReaderByType();
  }

  setReaderByType() {
    if (this.book.format == 'pdf') {
      this.readingModeIsPDF = true;
      this.readingModeIsEpub = false; 
    } else {
      this.readingModeIsPDF = false;
      this.readingModeIsEpub = true; 
    }
  }

  setActivePageOnBook(book?) {
      // Set new book
      if (book) {
        this.book = book;
          this.startReadingMode();
      }
  }

  updateReadBooksOnStudent() {
    if (this.book) {
      this.setActivePageOnBook();
      // Update student books in db
      this.userService.updateUser({
        _id : this.userId,
        studentBooks : this.studentBooks
      }).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(res => {
        console.log(res)
      });
    }
  }

  getActivePage(page) {
    this.bookActivePage = page;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.updateReadBooksOnStudent();
    // Unsubscribe from all subscriptions
    setTimeout(() => {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }, 500);
  }

  submitForm(formDirective: FormGroupDirective) {
    if ( this.highlightText.length < 1)   {
      this.isHighlighted = true;
      return;
    }
    if (this.rForm.invalid) {
      return;
    }
    this.isHighlighted = false;
    const quiz: Quiz = new Quiz();
          quiz.question = this.rForm.value.question;
          quiz.textPassage = this.highlightText;
          quiz.page = Math.max.apply(Math, this.highlightText.map(function(o) { return o.page; })).toString();
          quiz.answers = this.rForm.value.answers;
          quiz.difficultyLevel = this.rForm.value.difficultyLevel;

    this.quizService.updateQuestion(this.quizId, quiz).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      this.snackBar.open("Frage wurde erfolgreich bearbeitet", "",{
        duration: 3000,
        horizontalPosition: 'right'
      });
      this.router.navigate(['/list-question/' + this.bookId]);
    });
  }

}
