import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../../providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgForm, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolService } from '../../../providers/school.service';

export interface Coordinator {
  _id: string;
  username: string;
  firstname?: string;
  lastname?: string;
  role: string;
}
export interface DialogData {
  id: any;
}
interface School {
  _id: string;
  name: string;
  streetNr?: string;
  plz?: string;
  city?: string;
  typeOfSchool: string;
}

@Component({
  selector: 'app-coordinator-downgrade',
  templateUrl: './coordinator-downgrade.component.html',
  styleUrls: ['./coordinator-downgrade.component.scss'],
})
export class CoordinatorDowngradeComponent implements OnInit {
  id;
  userType;
  user;
  rForm: FormGroup;

  coordinators : Coordinator[] = [];

  public coordinator: FormControl = new FormControl();
  public coordinatorFilter: FormControl = new FormControl();
  public filteredCoordinators: ReplaySubject<Coordinator[]> = new ReplaySubject<Coordinator[]>(1);
  private coordinatorsSub: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userService : UserService,
    private authService : AuthService,
    private router: Router,
    private schoolService : SchoolService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CoordinatorDowngradeComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: DialogData
  ) {
    if (injectedData) {
      if (typeof(injectedData['id'])!=="undefined") {
        this.id = injectedData['id'];
      }
    }
  }

  ngOnInit() {
    this.userService.getUserbyId(this.id).subscribe(res => {
      this.user = res;
    });
    this.initForm();
  }

  initForm() {
    this.rForm = new FormGroup({
      coordinator: new FormControl(null, Validators.required)
    });

    // Get coordinators from api
    this.userService.getCoordinatorsList();
    this.coordinatorsSub = this.userService.getCoordinatorsUpdateListener().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((coordinators: Coordinator[]) => {
      console.log(coordinators)
        this.coordinators = coordinators;
        // set select for coordinator
        this.coordinator.patchValue(this.coordinators);
        // refresh coordinators search filter
        this.filteredCoordinators.next(this.coordinators.slice());
      });

    this.coordinatorFilter.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.filterCoordinators();
      });
  }

  cancel() {
    this.dialogRef.close('decline');
  }

	submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    this.userService.updateUser({
      _id : this.id,
      coordinator : this.rForm.value.coordinator,
      role: 'mentor',
      tokenSalt: '',
      supervisingSchools: null
    }).subscribe(res => {
        this.dialogRef.close();
        this.router.navigate(['/mentor-list']);
        this.snackBar.open(this.user.firstname + " " + this.user.lastname + " wurde zum Mentor herabgestuft.", "",{
          duration: 3000,
          horizontalPosition: 'right'
        });
    })
  }

  filterCoordinators() {
    if (!this.coordinators) {
      return;
    }
    // get the search keyword
    let search = this.coordinatorFilter.value;
    if (!search) {
      this.filteredCoordinators.next(this.coordinators.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the coordinators by (firstname/ lastname)
    this.filteredCoordinators.next(
      this.coordinators.filter(coordinator => coordinator.firstname.toLowerCase().indexOf(search) > -1 || coordinator.lastname.toLowerCase().indexOf(search) > -1)
    );

  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
