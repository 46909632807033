import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface School {
  _id?: string;
  name: string;
  streetNr?: string;
  plz?: string;
  city?: string;
  typeOfSchool: string;
}

@Injectable({
  providedIn: 'root'
})

export class SchoolService {
  private restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  school: School[] = [];
  schoolUpdated = new Subject<School[]>();

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { 
    let protocol = environment.ssl ? 'https://' : 'http://';
    this.restServerUrl = protocol + environment.restServer + ":" + environment.restServerPort ;
  }
  
  createSchool( name: string,streetNr: string,plz: string, city: string, typeOfSchool: string ) {
    const schoolData: School = { 
      name: name,
      streetNr: streetNr,
      plz: plz,
      city: city,
      typeOfSchool: typeOfSchool
    };
    return this.http
        .post<any>(
            this.restServerUrl + "/api/school/create",
            schoolData
        );
  }
  
  getSchoolList() {
    this.http.get<School[]>(this.restServerUrl + '/api/school/list')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        console.log(data)
                this.school = data;
                this.schoolUpdated.next([...this.school]);
      });
  }

  getAvailableSchoolList() {
    this.http.get<School[]>(this.restServerUrl + '/api/school/listAvailable')
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
      .subscribe(data => {
                console.log(data)
                this.school = data;
                this.schoolUpdated.next([...this.school]);
      });
  }

  getAvailableSchoolListById(id) {
    this.http.get<School[]>(this.restServerUrl + '/api/school/listAvailableById/'+ id)
    .pipe(map(data => data), takeUntil(this._unsubscribeAll))
      .subscribe(data => {
                console.log(data)
                this.school = data;
                this.schoolUpdated.next([...this.school]);
      });
  }

  getSchoolbyId(id) {
    console.log(id)
    return this.http.get<School[]>(this.restServerUrl + '/api/school/' + id)
      .pipe(map(data => data));
  }

  updateSchool(data)  {
    return this.http.put(
      this.restServerUrl + '/api/school/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(map(res => {
          return <School>res;
        }));
  }

  getSchoolUpdateListener() {
    return this.schoolUpdated.asObservable();
  }
    
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
