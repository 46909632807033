import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { WebSocketService } from '../providers/websocket.service';
//Buch Overlay
import { EbooksEpubService } from '../providers/epub.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs'

import ePub from 'epubjs';

export class Book {
  label: string;
  file: string;
}

@Component({
  selector: 'app-bookreader',
  templateUrl: './bookreader.component.html',
  styleUrls: ['./bookreader.component.scss'],
})
export class BookreaderComponent implements OnInit, OnChanges {

  debug: boolean = false;
  // Websocket
  userName: string;
  message: string;
  output: any[] = [];
  feedback: string;
  currentCfi: any = 1;
  currentLocation: any = {
    end: "",
    href: "",
    index: 0,
    percentage: 0,
    start: "",
  };
  interactionByUser: boolean = false;
  // Epub
  book: any;
  rendition: any;
  direction: string = '';
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input('bookUrl') bookUrl: any;
  @Input('readingMode') readingMode: string;

  constructor(
    private webSocketService: WebSocketService,
    //Buch Overlay
    private epubService: EbooksEpubService
  ) { }

  ngOnInit() {
    this.webSocketService.listen('book-reading').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((data) => this.updatecurrentCfi(data));
  }

  calculateReaderHeight() {
    let h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // On the next line you need to factor in the height of any of your UI elements.
    // You could just take the  values if they are static or use jQuery to get the heights dynamically (that would be the preferred method)
    h = h - 50 - 68 - 44; // 50: Header Height, 68: Footer Height, 44: 22px * 2 top/bottom margin
    return h;
  }

  /**
   * render Epub / Epub styles / Epub buttons / send Epub data to socket
   */ 
  renderBook(book) {
    this.book = ePub(book/*, { encoding: "base64" }*/);
    // this.book.open('base64').then(
    //   () => console.log('EPUB content opened.'), 
    //   (err) => {
    //     console.log('EPUB content opened fail:');
    //     console.log(err);
    //   }
    // );
  
    this.rendition = this.book.renderTo("epub-viewer", {
      width: "1024", // 820
      height: "768", // 650
      // spread: "none",
      // flow: "paginated"
      // Testing variations for character generation
      //spread: 'always',
      //flow: 'paginated',
      //manager: 'continuous'
    });

    this.rendition.themes.default({
      // "html": {
      //   "font-size": "16px !important",
      //   "line-height": "1.5 !important",
      // },
      // "body": {
      //   "font-size": "1rem !important",
      //   "line-height": "1rem !important",
      // },
      "h1": {
        "font-size": "2rem !important",
        "line-height": "2.25rem !important",
        "margin": "1.25rem 0 !important"
      },
      "p": {
        "font-size": "1.25rem !important",
        "line-height": "1.5rem !important",
        "margin": "0.75rem 0 !important"
      }
    });

    this.rendition.display();
    // console.log(this.rendition);

    var _this = this;
    this.book.ready.then(() => {
        console.log('ready')


      const stored = localStorage.getItem(this.book.key() + '-locations');
      console.log('metadata:', this.book.package.metadata);

        if (stored) {
            // Get pages from local storage
            return this.book.locations.load(stored);
        } else {
            // Generates CFI for every X characters (Characters per/page)
            return this.book.locations.generate(3200); // 1600
        }
        //return book;
    }).then(locations => {
        console.log('locations generated');
        console.log(locations)
        localStorage.setItem(this.book.key() + '-locations', this.book.locations.save());
        var next = document.getElementById("book-next");
        next.addEventListener("click", function(e){
          e.preventDefault();
          _this.direction = _this.book.package.metadata.direction === "rtl" ? 'prev' : 'next';
          _this.book.package.metadata.direction === "rtl" ? _this.rendition.prev() : _this.rendition.next();
        }, false);

        var prev = document.getElementById("book-prev");
            prev.addEventListener("click", function(e){
              e.preventDefault();
              _this.direction = _this.book.package.metadata.direction === "rtl" ? 'prev' : 'next';
              _this.book.package.metadata.direction === "rtl" ? _this.rendition.next() : _this.rendition.prev();
            }, false);

        /*
        var keyListener = function(e){
            // Left Key
            if ((e.keyCode || e.which) == 37) {
              this.book.package.metadata.direction === "rtl" ? _this.rendition.next() : _this.rendition.prev();
            }
            // Right Key
            if ((e.keyCode || e.which) == 39) {
              this.book.package.metadata.direction === "rtl" ? _this.rendition.prev() : _this.rendition.next();
            }
        };

        _this.rendition.on("keyup", keyListener);
        document.addEventListener("keyup", keyListener, false);
        */
      });

      /**
       * prev and next button of Epub
       */ 
      this.rendition.on("relocated", function(location){

        var progress = this.book.locations.percentageFromCfi(location.start.cfi);
        console.log('Progress:', progress); // The % of how far along in the book you are
        console.log('Current Page:', this.book.locations.locationFromCfi(location.start.cfi));
        console.log('Total Pages:', this.book.locations.total);

        // console.log(location);
        // this.currentCfi = this.book.locations.locationFromCfi(location.start.cfi);

        var next = this.book.package.metadata.direction === "rtl" ?  document.getElementById("prev") : document.getElementById("book-next");
        var prev = this.book.package.metadata.direction === "rtl" ?  document.getElementById("next") : document.getElementById("book-prev");

        if (location.atEnd) {
            next.style.visibility = "hidden";
        } else {
            next.style.visibility = "visible";
        }

        if (location.atStart) {
            prev.style.visibility = "hidden";
        } else {
            prev.style.visibility = "visible";
        }
    });

   /**
   * Send Socket if the location of Epub changed
   */ 
    this.rendition.on("locationChanged", function(location) {
      console.log(location)
      if (_this.currentLocation.end != location.end && this.direction != '') {
        _this.currentLocation = location;
        // Change page on mentor or child
        // Fire next or prev to be exact and socket only child or mentor
        _this.sendSocketBookReading();
      }
    });

  /**
   * rendition layout / add and remove class
   */ 
    this.rendition.on("layout", function(layout) {
        let viewer = document.getElementById("epub-viewer");

        if (layout.spread) {
            viewer.classList.remove('single');
        } else {
            viewer.classList.add('single');
        }
    });
  }

  // waitForCfiChangeAndFireSocket() {
  //   setTimeout(() => {
  //       var newCfi = this.rendition.currentLocation();
  //       // Fire only if current page is not already set
  //       if (this.currentCfi != newCfi.end.cfi) {
  //         this.currentCfi = newCfi.end.cfi;
  //         console.log(newCfi);
  //         // Change page on mentor or child
  //         this.sendSocketBookReading();
  //       }
  //   }, 2000);
  // }

/**
 * Send Epub data to Socket 
 */ 
  sendSocketBookReading(): void {
      var currentDirection = [...this.direction];
      // Reset direction before sending to socket
      this.direction = '';
      // Send interaction to mentor or student
      if (this.readingMode == 'Tandem') {
        this.webSocketService.emitTo('book-reading', this.webSocketService.getCurrentRoom(), {
          //cfi: this.currentCfi
          direction : currentDirection,
          cfi: this.currentLocation.end
        });
      } else {
        this.updatecurrentCfi({
          direction : currentDirection,
          cfi: this.currentLocation.end
        })
      }
  }

/**
 * increase current page
 */ 
  increaseCurrentCfi() {
    // Epub get current page
    //console.log(this.book.pagination.pageFromCfi(this.book.getCurrentLocationCfi()));
    // Get the current CFI
    var currentLocation = this.rendition.currentLocation();
    console.log(currentLocation);
    // Get the Percentage (or location) from that CFI
    var cfiPercentage = this.book.locations.percentageFromCfi(currentLocation.start.cfi);
    console.log(cfiPercentage);
  }

/**
 * update current cfi/page
 */ 
  updatecurrentCfi(data:any) {
    if(!!!data) return;
      // Check for direction
      if (data.direction == 'prev') {
        this.rendition.prev();
      } else if(data.direction == 'next') {
        this.rendition.next();
      } else {
        this.currentCfi = data.cfi;
        // EPUB go to specific CFI
        this.rendition.display(this.currentCfi);
      }
  }

  gototest(){
    // console.log(this.book.locations.percentageFromCfi('epubcfi(/6/6[id1243]!/4/12/3:136)'));
    // this.currentCfi = 'epubcfi(/6/6[id1243]!/4/12/3:136)';
    // //this.currentCfi = 'epubcfi(/6/6[id1243]!/4/10/3:184)';
    // this.rendition.display(this.currentCfi);
    console.log(this.book.locations.percentageFromCfi('epubcfi(/6/6[id1243]!/4/12/3:136)'));
    //var cfi = this.book.locations.cfiFromPercentage(0.004648460197559558);
    var cfi = 'epubcfi(/6/6[id1243]!/4/12/3:136)';
    console.log(cfi);
		this.rendition.display(cfi);
    //this.sendSocketBookReading();
  }

 /**
 * ngOnChanges / render the selected Epub
 */ 
  ngOnChanges(changes: SimpleChanges) {
    if (changes.bookUrl) {
      if (this.rendition) {
        this.rendition.destroy();
      }
      this.renderBook(changes.bookUrl.currentValue);
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
