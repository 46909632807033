import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from '../../../app-routing.module';
import { MaterialModule } from '../../../material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthInterceptor } from "../../../auth/auth-interceptor";
import { ErrorInterceptor } from "../../../error/error-interceptor";
import { GameUnoComponent } from './game-uno.component';
import { LoadingSpinnerModule } from '../../../helpers/loading-spinner/loading-spinner.module';


@NgModule({
  declarations: [
    GameUnoComponent
  ],
  entryComponents: [
    GameUnoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    MatDialogModule,
    LoadingSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [
  ],
  exports: [GameUnoComponent]
})
export class GameUnoModule {}
