<div class="session container-fluid pt-3">
  <div class="session_wrap row">
    <div class="col-md-12">
      <div class="table-navigation">
        <div class="filter-area">
          <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Frage" #input>
          </mat-form-field>
        </div>
        <div class="button-area">
          <a [routerLink]="['/create-question/' + id]">
            <mat-icon >post_add</mat-icon> Frage anlegen
          </a>
        </div>
      </div>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z1" matSort matSortActive="page" matSortDisableClear matSortDirection="asc">
        <ng-container matColumnDef="question">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Fragen zu {{bookname}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                  {{ element.question}} 
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="page">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Seite</mat-header-cell>
          <mat-cell *matCellDef="let element">
                {{ element.page}} 
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" >
              <div>
                  <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More">
                      <mat-icon class="secondary-text">more_vert</mat-icon>
                  </button>

                  <mat-menu #moreMenu="matMenu">
                      <button mat-menu-item aria-label="add-note" [routerLink]="['/edit-question/' + element._id]">
                          <mat-icon>create</mat-icon>
                          <span>Bearbeiten</span>
                      </button>

                      <button mat-menu-item aria-label="remove" (click)="deleteQuestion(element._id)">
                        <mat-icon>delete</mat-icon>
                        <span>Löschen</span>
                    </button>
                  </mat-menu>

              </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginator  [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>

