<div class="container-fluid pt-3">
  <div class="register row justify-content-center">
      <div class="col-md-8 col-sm-12">
        <form [formGroup]="rForm" #formDirective="ngForm">
            <div class="row">
              <div class="col-md-12">
                <br>
                <h1>{{ user?.firstname }} {{ user?.lastname }} herabstufen?</h1>

                <mat-form-field> 
                  <mat-label>Koordinator</mat-label>
                  <mat-select formControlName="coordinator" placeholder="Koordinator" required>
                    <mat-option>
                      <ngx-mat-select-search [formControl]="coordinatorFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let coordinator of filteredCoordinators  | async" [value]="coordinator._id">
                      {{ coordinator?.firstname }}  {{ coordinator?.lastname }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <button mat-button color="accent" type="button" (click)="submitForm(formDirective)" class="size-big">Speichern</button>
              </div>
              <div class="col-md-6">
                <button mat-button color="accent" type="button" (click)="cancel()" class="size-big">Abbrechen</button>
              </div>
            </div>
          </form>
      </div>
  </div>
</div>