<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-12">
      <h1 class="align-center">Ziehen Sie Studenten von von links nach rechts.</h1>
      <p class="align-center">Diese Funktion wird nur für das Aufheben/Löschen von Matchings benutzt.</p>
      <div class="d-flex justify-content-center">
        <div class="students-container students-container-active">
          <h2>Lesekinder von Mentor</h2>
          <div
            id="MentorStudentsList"
            cdkDropList
            #mentorStudentsList="cdkDropList"
            [cdkDropListData]="mentorStudents"
            [cdkDropListConnectedTo]="[studentsList]"
            class="students-list"
            (cdkDropListDropped)="drop($event)">
            <div class="students-list-item" *ngFor="let item of mentorStudents" cdkDrag>{{ item.username }}</div>
          </div>
        </div>

        <div class="students-container">
          <h2>Alle Lesekinder</h2>
          <div
            id="studentsList"
            cdkDropList
            #studentsList="cdkDropList"
            [cdkDropListData]="students"
            [cdkDropListConnectedTo]="[mentorStudentsList]"
            class="students-list"
            (cdkDropListDropped)="drop($event)">
            <div class="students-list-item" *ngFor="let item of students" cdkDrag>{{ item.username }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 d-flex justify-content-center">
      <button mat-button color="accent" (click)="saveStudents()" class="size-big">Speichern</button>
    </div>
  </div>
</div>
